import {
    increaseVisibleConversationsOffset,
    selectConversationList,
    selectIsFiltered,
    selectSelectedConversation,
    selectVisibleConversationList,
    selectVisibleConversationsOffset,
    setSelectedConversationId,
    setVisibleConversationsOffset,
} from './store/conversationsSlice';
import { getConversations, isActionAllowed, isPreferredAction } from './Utils/shared/utils';
import { getMessages } from './store/chatSlice';
import { motion } from 'framer-motion';
import { openSocialMediaPanel, selectSocialMediaPanelState } from './store/stateSlice';
import { selectConnectionStatus } from 'app/store/connectionSlice';
import { selectSelectedChannelId } from './store/tabChannelSlice';
import { selectUserPreferences, selectUser } from "app/store/userSlice";
import { styled } from '@mui/material/styles';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Avatar from '@mui/material/Avatar';
import ButtonToRemoveFilterByStatus from './ButtonToRemoveFilterByStatus';
import CircularProgress from '@mui/material/CircularProgress';
import ConversationListItem from './ConversationListItem';
import FilterBar from './FilterBar';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import OfflineNotification from './OfflineNotification';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import SearchBar from './SearchBar';
import Typography from '@mui/material/Typography';

const Root = styled('div')(({ theme }) => ({
    background: theme.palette.background.paper,
    width: 440,
    height: '100%',
}));

const ListTop = styled(FuseScrollbars)(({ theme }) => ({
    width: 440,
    height: 'CALC(100% - 50px)',
    minHeight: 'CALC(100% - 50px)',
    overflowY: 'auto',
}));

const ConversationHeader = styled('div')(() => ({
    width: 440,
    height: 50,
}));

const containerVariant = {
    show: {
        transition: {
            staggerChildren: 0.1,
        },
    },
};

const itemVariant = {
    show: {
        transition: {
            staggerChildren: 0.1,
        },
    },
};

// Move ConversationListContent out of the main component and memoize it
const ConversationListContent = React.memo(
    ({ conversations, selectedConversationId, handlerClickConversation }) => {
        return (
            <>
                {!conversations ? (
                    <CircularProgress color="inherit" className="mx-auto" />
                ) : (
                    conversations.map((conversation) => {
                        const active = conversation.id === selectedConversationId;
                        return (
                            <motion.div
                                variants={itemVariant}
                                key={conversation.id}
                                className="border-b-2"
                                style={active ? { background: '#e5e5e5' } : null}
                            >
                                <ConversationListItem
                                    key={conversation.id}
                                    conversation={conversation}
                                    handlerClickConversation={handlerClickConversation}
                                />
                            </motion.div>
                        );
                    })
                )}
            </>
        );
    }
);

const ConversationList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const conversationList = useSelector(selectConversationList);
    const isConnected = useSelector(selectConnectionStatus);
    const isFiltered = useSelector(selectIsFiltered);
    const selectedChannelId = useSelector(selectSelectedChannelId);
    const selectedConversation = useSelector(selectSelectedConversation);
    const socialMediaPanelState = useSelector(selectSocialMediaPanelState);
    const visibleConversationsOffset = useSelector(selectVisibleConversationsOffset);

    const user = useSelector(selectUser);
    const userPreferences = useSelector(selectUserPreferences);

    const conversationListLengthRef = useRef(conversationList.length);
    const visibleConversationsOffsetRef = useRef(visibleConversationsOffset);

    const [isLoading, setIsLoading] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const listRef = useRef();

    let conversations = useSelector(selectVisibleConversationList);
    if (isFiltered) {
        conversations = conversationList.filter(
            (conversation) => conversation.filtered
        );
    }

    const selectedConversationId = selectedConversation?.id;
    let selectedConversationFrom = '';

    if (selectedConversation?.tipo == 1) {
        selectedConversationFrom = selectedConversation?.channels?.find(channel => channel.id === selectedChannelId)?.phoneNumber || null;
    } else if (selectedConversation?.tipo == 3) {
        selectedConversationFrom = selectedConversation.fb_user;
    } else if (selectedConversation?.tipo == 5) {
        selectedConversationFrom = selectedConversation.username;
    }

    // Load more conversations when the user scrolls to the bottom of the list
    const loadMoreConversations = useCallback(
        async (offset, listLength) => {
            if (isLoading) return;
    
            const typesConfig = [
                { type: 1, description: 'whatsapp', limit: 5 },
                { type: 3, description: 'facebook', limit: 5 },
                { type: 5, description: 'instagram', limit: 5 },
            ];
    
            if (offset < listLength) {
                const difference = listLength - offset;
                const limit = difference < 10 ? difference : 10;
    
                if (limit > 0) {
                    await dispatch(increaseVisibleConversationsOffset(limit));
                    setIsLoading(false);
                }
            } else if (listLength < 300) {
                const difference = 300 - listLength;
    
                // Inside batch I use for to be able to handle the await correctly :S
                await batch(async () => {
                    for (const { type, description, limit } of typesConfig) {
                        const actualLimit = difference < limit ? difference : limit;
    
                        if (isActionAllowed(user.permissions, description) && isPreferredAction(userPreferences, description)) {
                            await dispatch(getConversations({ offset: listLength, limit: actualLimit, tipo: type }));
                        }
                    }
                });
                
                const limit = Math.min(difference, 10);
                await dispatch(increaseVisibleConversationsOffset(limit));
                setIsLoading(false);
            }
    
            if (offset > listLength) {
                await dispatch(setVisibleConversationsOffset(listLength));
                setIsLoading(false);
            }
        },
        [dispatch, isActionAllowed, isPreferredAction]
    );

    const getConversationChat = useCallback(
        ({ reload, conversationId, type }) => {
            const params = {
                tipo: type,
                idConversacion: reload ? selectedConversationId : conversationId,
                idCanal: null,
                limit: 15,
                offset: 0,
                IdMensaje: '',
                reverse: false,
            };

            const data = {
                params,
                lastMessage: false,
                reloadChat: !!reload,
            };

            dispatch(getMessages({ data }));
        },
        [dispatch]
    );

    const handlerClickConversation = useCallback(
        (conversationId, type) => {
            dispatch(setSelectedConversationId(conversationId));
        },
        [dispatch]
    );

    const handleScroll = useCallback(
        (e) => {
            if (isLoading) return;

            const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;

            if (bottom) {
                setIsLoading(true);
                loadMoreConversations(
                    visibleConversationsOffsetRef.current,
                    conversationListLengthRef.current
                );
            }
        },
        [isLoading, loadMoreConversations]
    );

    const handleShowSearchBarClick = () => {
        setShowSearchBar(true);
    };

    const handleHideSearchBarClick = () => {
        setShowSearchBar(false);
    };

    useEffect(() => {
        visibleConversationsOffsetRef.current = visibleConversationsOffset;
        conversationListLengthRef.current = conversationList.length;
    }, [visibleConversationsOffset, conversationList.length]);

    useEffect(() => {
        const currentList = listRef.current;

        if (currentList) {
            currentList.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentList) {
                currentList.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    useEffect(() => {
        dispatch(setVisibleConversationsOffset(20));
    }, [dispatch, selectedConversationId, selectedConversation?.tipo, getConversationChat]);

    return (
        <Root className="flex flex-col">
            {socialMediaPanelState && (
                <ConversationHeader>
                    <div className="flex justify-between items-center px-4 border-b-2 h-52">
                        {selectedConversation ? (
                            <div className="flex items-center max-w-full" style={{ flexShrink: 1 }}>
                                {selectedConversation.avatar && (
                                    <Avatar src={selectedConversation.avatar} />
                                )}
                                <Typography
                                    className="mx-16 text-14 truncate"
                                    color="inherit"
                                    style={{ maxWidth: '200px' }}
                                >
                                    {t('wsPanel.conversationList.messagesFrom')}{' '}
                                    {selectedConversationFrom && (
                                        <Link
                                            href={selectedConversation?.tipo === 1 ? `https://wa.me/${selectedConversationFrom}` : null}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="ml-2"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {selectedConversationFrom}
                                        </Link>
                                    )}
                                </Typography>
                            </div>
                        ) : (
                            <div className="flex-grow"></div>
                        )}
                        <div className="flex items-center space-x-2">
                            {
                                isFiltered && (<ButtonToRemoveFilterByStatus />)
                            }
                            <FilterBar />
                            {showSearchBar ? (
                                <IconButton onClick={handleHideSearchBarClick} size="large">
                                    <Icon>close</Icon>
                                </IconButton>
                            ) : (
                                <IconButton onClick={handleShowSearchBarClick} size="large">
                                    <Icon>search</Icon>
                                </IconButton>
                            )}

                        </div>
                    </div>
                </ConversationHeader>
            )}

            {socialMediaPanelState && showSearchBar && <SearchBar />}
            {!isConnected && <OfflineNotification />}

            <ListTop ref={listRef}>
                <List className="overflow-y-auto">
                    <motion.div
                        className="flex flex-col shrink-0"
                        variants={containerVariant}
                        initial="hidden"
                        animate="show"
                    >
                        <ConversationListContent
                            conversations={conversations}
                            selectedConversationId={selectedConversationId}
                            handlerClickConversation={handlerClickConversation}
                        />
                    </motion.div>
                </List>
            </ListTop>
            {isLoading && (
                <center margin="10px">
                    <CircularProgress />
                </center>
            )}
        </Root>
    );
};

export default ConversationList;

import { lazy, Suspense } from 'react';

function MessageTypeTemplate(props) {
    props = props[0]
    let type = props.type;
    let text = props.payload.elements[0].subtitle;
    let context = props.title;
    let messageContext = props.title;
    let StyleMessageContext = {
        borderRadius: "0px 10px 10px 0px",
        padding: "10px",
        background: "rgba(189,189,189,0.40)",
        boxShadow: "-10px 0px 0px 0px rgba(0,0,0,0.50)",
        marginLeft: "10px"
    }

    if (context) {
        const MessageTypeComponent = lazy(() => import("."));
        messageContext = <MessageTypeComponent type={context.type} content={context.content} context={context.context} />;
    }

    return (
        <>
            {context && (
                <Suspense fallback={<div>Loading...</div>}>
                    <div style={StyleMessageContext}>
                        {messageContext}
                    </div>
                </Suspense>
            )}

            <p className={context && ("my-8")}>{text}</p>
        </>
    )
}

export default MessageTypeTemplate
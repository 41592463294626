import { useState, useRef, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";

const AudioRecorder = ({ onSendAudio, chatIsBlocked, t }) => {
    const dispatch = useDispatch();
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioURL, setAudioURL] = useState(null);
    const [audioBlob, setAudioBlob] = useState(null);
    const audioRef = useRef(null);
    const [recordingTime, setRecordingTime] = useState(0);
    const timerRef = useRef(null);
    const audioChunksRef = useRef([]);

    // Formatear tiempo para mostrar
    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const startRecording = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            
            dispatch(showMessage({
                message: 'Tu navegador no soporta grabación de audio.',
                variant: 'error',
            }))
            return;
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream);
            setMediaRecorder(recorder);
            setAudioURL(null);
            setAudioBlob(null);
            audioChunksRef.current = [];

            recorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            recorder.onstop = () => {
                if (audioChunksRef.current.length > 0) {
                    const blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
                    const url = URL.createObjectURL(blob);
                    setAudioURL(url);
                    setAudioBlob(blob);
                } else {
                    console.error("No hay datos de audio disponibles.");
                }
                stream.getTracks().forEach((track) => track.stop());
            };

            recorder.start();
            setIsRecording(true);
        } catch (err) {
            console.error("Error al acceder al micrófono: ", err);
            dispatch(showMessage({
                message: 'No se pudo acceder a tu micrófono.',
                variant: 'error',
            }));
        }
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);
        }
    };

    const sendAudio = () => {
        if (!audioBlob) return;

        onSendAudio(audioBlob);

        // Limpiar datos de audio
        setAudioURL(null);
        setAudioBlob(null);
        audioChunksRef.current = [];
    };

    useEffect(() => {
        if (isRecording) {
            timerRef.current = setInterval(() => {
                setRecordingTime((prev) => prev + 1);
            }, 1000);
        } else {
            clearInterval(timerRef.current);
            setRecordingTime(0);
        }

        return () => clearInterval(timerRef.current);
    }, [isRecording]);

    return (
        <>
            <IconButton
                size="small"
                onClick={isRecording ? stopRecording : startRecording}
                className="mr-[5px]"
                disabled={chatIsBlocked}>
                <Icon>{isRecording ? 'stop_circle' : 'mic'}</Icon>
            </IconButton>
            {isRecording && (
                <span className="text-green-500 font-bold mr-[15px]">
                    {formatTime(recordingTime)}
                </span>
            )}
            {audioURL && (
                <>
                    <audio ref={audioRef} src={audioURL} controls className="ml-2" />
                    <Button
                        variant="contained"
                        color="secondary"
                        className={`text-white font-medium rounded-lg text-sm px-10 py-5 ml-5 mr-[15px] ${
                            chatIsBlocked
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-green-700 hover:bg-green-800'
                        }`}
                        onClick={sendAudio}
                        disabled={chatIsBlocked}>
                        {t('wsPanel.formChat.sendAudio')}
                    </Button>
                </>
            )}
        </>
    );
};

export default AudioRecorder;

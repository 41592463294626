import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const Root = styled('div')(({ theme }) => ({
  '& > .logo-icon': {
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '& > .badge': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function Logo() {
  return (
    <Root className="flex items-center">
      <img className="logo-icon w-24 h-24" src="assets/images/logo/logo-new-age.svg" alt="logo" />

      <div
        className="flex items-center py-4 px-8 mx-8"
        style={{ backgroundColor: 'transparent'}}
      >
      <Typography className="logo-text text-14 leading-none mx-12 font-medium" color="primary">
      New Age Systems
      </Typography>
      </div>
    </Root>
  );
}

export default Logo;

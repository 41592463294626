import { formatDateTimeMessage } from 'app/utils';
import { subtractHour } from '../../Utils/socialMediaUtil';
import { useTheme } from '@mui/styles';
import clsx from 'clsx';
import MessageType from './MessageType';
import MessageTypeError from 'app/whatsapp-panel/components/MessageType/MessageTypeError';
import StyledMessageContainerRow from '../../Utils/components/StyledMessageContainerRow';

const StyledMessageContainerRowFb = (props) => {
    const theme = useTheme();

    return (
        <StyledMessageContainerRow
            bgColor={theme.palette.facebook.main}
            textColor={theme.palette.facebook.contrastText}
            {...props}
        />
    );
};

const MessageRowFb = ({ message }) => {
    const messageStatus = message.status;
    // const direction = message.direction;
    const error1 = message.error ? message.error.error : null;
    const error2 = message.errors ? message.errors.error : null;
    const error = error1 ? error1 : error2;

    const classMessageRow = clsx(
        { me: message.direction === 'sent' },
        { contact: message.direction === 'received' },
        // { 'first-of-group': isFirstMessageOfGroup(message, i) },
        { 'last-of-group': true }
    );
    return (
        <StyledMessageContainerRowFb className={classMessageRow}>
            <div className="message-extra-data">
                <div className="message-data-user">
                    <p>{message.NombreUsuario ? message.NombreUsuario : ''}</p>
                </div>
                <div className="message-data-user-group">
                    <p>{message.NombreGrupo ? message.NombreGrupo : ''}</p>
                </div>
                {/* {direction === "received" &&  (
                    <div className="message-data-user-readtime">
                        <p>
                            {formatDateTimeMessage(
                                new Date(subtractHour(message.tmu))
                            )}
                        </p>
                    </div>
                )} */}
                <div className="message-data-client-name">
                    <p>{message.NombreCliente ? message.NombreCliente : ''}</p>
                </div>

                {error && <MessageTypeError error={error} />}
            </div>

            <div className="bubble shadow">
                <div className="message">
                    {messageStatus !== 'deleted' && (
                        <MessageType item={message} />
                    )}

                    {messageStatus === 'deleted' && (
                        <MessageType type="deleted" />
                    )}

                    <div className="time">
                        <p>
                            {formatDateTimeMessage(
                                new Date(subtractHour(message.createdDatetime))
                            )}
                        </p>
                    </div>
                </div>

                {message.reaction && (
                    <MessageType type="reaction" content={message.reaction} />
                )}
            </div>
        </StyledMessageContainerRowFb>
    );
};

export default MessageRowFb;

import AppBar from '@mui/material/AppBar';
import InfoAgentConversation from './InfoAgentConversation';
import InfoConversation from './InfoConversation';
import MenuConversation from './MenuConversation';
import Toolbar from '@mui/material/Toolbar';

const HeaderConversation = () => {
    return (
        <AppBar
            className="w-full justify-end items-stretch"
            elevation={0}
            position="relative"
            color="inherit"
            style={{ minHeight: 50 }}>
            <Toolbar className="px-16" style={{ minHeight: 50 }}>
                <InfoConversation />
                <InfoAgentConversation />
                <MenuConversation />
            </Toolbar>
        </AppBar>
    );
};

export default HeaderConversation;

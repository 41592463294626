
import { formatDateTimeMessage } from "app/utils/dateUtils";
import { previewSendedMessage, sendMessage } from "../../store/chatSlice";
import { styled } from "@mui/material";
import { subtractHour } from "../../Utils/socialMediaUtil";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useTheme } from "@mui/styles";
import clsx from "clsx";
import MessageStatus from "./MessageStatus";
import MessageType from "./MessageType";
import MessageTypeError from 'app/whatsapp-panel/components/MessageType/MessageTypeError';
import RetryButton from "./RetryButton";
import RetryModal from "./RetryModal";
import StyledMessageContainerRow from "../../Utils/components/StyledMessageContainerRow";
import MessageTypeResolution from "../MessageType/MessageTypeResolution";

const StyledMessageContainerRowWsp = (props) => {
    const theme = useTheme();

    return (
        <StyledMessageContainerRow
            bgColor={theme.palette.whatsapp.main}
            textColor={theme.palette.whatsapp.dark}
            {...props}
        />
    );
};
const MessageRowWsp = ({ message, emoji }) => {
    const messageStatus = message.status;
    const messageType = message.type;
    const direction = message.direction;
    const error1 = message.error ? message.error.error : null;
    const error2 = message.errors ? message.errors.error : null;
    const error = error1 ? error1 : error2;

    const [showRetryModal, setShowRetryModal] = useState(false);

    const dispatch = useDispatch();

    const classMessageRow = clsx(
        { me: message.direction === 'sent' },
        { contact: message.direction === 'received' },
        // { 'first-of-group': isFirstMessageOfGroup(message, i) },
        { 'last-of-group': true }
    );

    const handleRetryClick = () => {
        setShowRetryModal(true);
    };

    const handleCloseRetryModal = () => {
        setShowRetryModal(false);
    };

    const handleRetry = () => {
        const msgObj = {
            message: message.message,
            conversationNumber: message.conversationNumber,
            conversationId: message.conversationId,
            channelId: message.channelId,
            type: message.typeToSend,
            tempId: message.id,
            id: message.id,
            isRetry: true,
        };

        dispatch(sendMessage(msgObj));
        setShowRetryModal(false);
    };

    return (
        <StyledMessageContainerRowWsp className={classMessageRow}>
            <div className="message-extra-data">
                <div className="message-data-user">
                    <p>{message.NombreUsuario ? message.NombreUsuario : ''}</p>
                </div>
                <div className="message-data-user-group">
                    <p>{message.NombreGrupo ? message.NombreGrupo : ''}</p>
                </div>
                {direction === 'received' && message.tmu && (
                    <div className="message-data-user-readtime">
                        <p>
                            {formatDateTimeMessage(
                                new Date(subtractHour(message.tmu))
                            )}
                        </p>
                    </div>
                )}
                <div className="message-data-client-name">
                    <p>{message.NombreCliente ? message.NombreCliente : ''}</p>
                </div>

                {error && <MessageTypeError error={error} />}
            </div>

            <div className="bubble shadow">
                <div className="message">
                    {messageStatus !== 'deleted' && (
                        <MessageType item={message} />
                    )}

                    {messageStatus === 'deleted' && (
                        <MessageType type="deleted" />
                    )}

                    {emoji && <MessageTypeReaction emoji={emoji} />}

                    {(message.idResolucion) ? (<MessageTypeResolution resolution={message} index={message.idResolucion} />) :
                        (<div className="time">
                            <p>
                                {formatDateTimeMessage(
                                    new Date(subtractHour(message.createdDatetime))
                                )}
                            </p>

                            {direction === 'sent' && (
                                <MessageStatus status={messageStatus} />
                            )}
                        </div>)
                    }


                </div>
                {message.isAnotherError && (
                    <RetryButton onClick={handleRetryClick} />
                )}
            </div>
            {showRetryModal && (
                <RetryModal
                    open={showRetryModal}
                    onClose={handleCloseRetryModal}
                    onRetry={handleRetry}
                />
            )}
        </StyledMessageContainerRowWsp>
    );
};

const MessageTypeReaction = ({ emoji }) => {
    const Reaction = styled('div')({
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: '-15px',
        bottom: '-15px',
        width: '30px',
        height: '30px',
        zIndex: '1000',
        backgroundColor: '#dcf8c6',
        fontSize: '15px',
        textAlign: 'center',
        borderRadius: '50px',
        '&::after': {
            position: 'relative',
            left: 0,
            bottom: 0,
            content: `"${emoji}"`,
        },
    });

    return <Reaction className="font-sans shadow" />;
};

export default MessageRowWsp;

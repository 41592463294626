import { lazy } from 'react';

const ConfiguracionesApp = lazy(() => import('./config/ConfiguracionesApp'));

const ConfiguracionesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
    param: 'configuraciones'
  },
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/apps/configuraciones`,
      element: <ConfiguracionesApp />,
    },
  ],
};

export default ConfiguracionesAppConfig;
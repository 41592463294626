import { lazy } from 'react';
//import { Navigate } from 'react-router-dom';

const Client = lazy(() => import('./client/Client'));
const Clients = lazy(() => import('./Clients'));

const ClientsAppConfig = {
  settings: {
    layout: {},
    param: 'root'
  },
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/apps/clients`,
      element: <Clients />,
    },
    {
      path: `${process.env.PUBLIC_URL}/apps/clients/:clientId`,
      element: <Client />,
    }
  ],
};

export default ClientsAppConfig;

import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';

const MessageError = styled('div')({
    color: '#f44336',
    display: 'flex',
    alignItems: 'center'
});

const MessageTypeUnsupported = ({ message }) => {
    const { t } = useTranslation();

    const displayMessage = message || t('messageTypes.unsupportedMessage');

    return (
        <div className="message-error">
            <MessageError>
                <WarningIcon className="mr-4" />
                {displayMessage}
            </MessageError>
        </div>
    );
}

export default MessageTypeUnsupported;

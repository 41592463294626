//import AppBar from '@mui/material/AppBar';
import { getConversations, isActionAllowed, isPreferredAction } from "./Utils/shared/utils";
import { navbarClose } from "app/store/fuse/navbarSlice";
import { selectUser, selectUserPreferences } from "app/store/userSlice";
import { styled, useTheme } from "@mui/material/styles";
import { toggleSocialMediaPanel, closeSocialMediaPanel, selectSocialMediaPanelState } from "./store/stateSlice";
import { useCallback, useEffect, useRef, memo } from 'react';
import { useSelector, useDispatch, batch, shallowEqual } from 'react-redux';
import { useSwipeable } from "react-swipeable";
import ConversationList from "./ConversationList";
import ConversationPanel from "./ConversationPanel";
import FilterByStatusBar from "./FilterByStatusBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import keycode from "keycode";
import Paper from "@mui/material/Paper";
import reducer from "./store";
import withReducer from "app/store/withReducer";

const CloseOpenPanel = styled('div')(({ theme, opened, openedSidebar }) => ({
	position: 'absolute',
	display: 'flex',
	top: '50vh',
	left: opened ? '-10px' : 'unset',
	// right: opened ? 'unset' : '75px',
	right: opened ? '0px' : '0px',
	zIndex: openedSidebar && opened ? 0 : 1000,
	//width: opened ? '40px': '35px',
	width: 35,
	height: '50px',
	backgroundColor: theme.palette.background.paper,
	borderRadius: '25px 0 0 25px',
	...(opened && {
		//transform: theme.direction === 'rtl' ? 'translate3d(90vw,0,0)' : 'translate3d(-90vw,0,0)',
	}),

}));

const Root = styled('div')(({ theme, opened, openedSidebar }) => ({
	position: 'absolute',
	top: 50,
	right: 0,
	// width: opened ? 'calc(100% - 50px)' : '100px',
	width: opened ? 'calc(100% - 25px)' : '0',
	height: 'calc(100% - 118px)',
	zIndex: openedSidebar ? 0 : 999,
	border: '1px solid #eaeaea',

	[theme.breakpoints.down('sm')]: {
		width: opened ? '100%' : '100px',
		maxWidth: '100%',
		minWidth: '100px',
	},

	...(opened && {
		overflow: "visible",
	}),

	...(!opened && {
		overflow: "hidden",
		animation: `hide-panel 1ms linear ${theme.transitions.duration.standard}`,
		animationFillMode: "forwards",
	}),

	'& > .panel': {
		width: '100%',
		height: '100%',
		//position: 'absolute',
		//top: 50,
		//right: 0,
		//bottom: 0,
		//left: 0,
		//margin: 0,
		//overflow: 'hidden',
		//zIndex: 1000,
		//backgroundColor: theme.palette.background.paper,
		//boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
		//transform: 'translate3d(0,0,0)',
		//transition: theme.transitions.create(['transform'], {
		//  easing: theme.transitions.easing.easeInOut,
		//  duration: theme.transitions.duration.standard,
		//}),

		...(opened && {
			//transform: theme.direction === 'rtl' ? 'translate3d(90vw,0,0)' : 'translate3d(-90vw,0,0)',
		}),

		[theme.breakpoints.down('sm')]: {
			left: 'auto',
			position: 'fixed',
			//transform: theme.direction === 'rtl' ? 'translate3d(-360px,0,0)' : 'translate3d(360px,0,0)',
			boxShadow: 'none',
			width: 320,
			minWidth: 320,
			maxWidth: '100%',

			...(opened && {
				//transform: 'translate3d(0,0,0)',
				boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
			}),
		},
	},

	"@keyframes hide-panel": {
		"0%": {
			overflow: "visible",
		},
		"99%": {
			overflow: "visible",
		},
		"100%": {
			overflow: "hidden",
		},
	},
}));

const SocialMediaPanel = () => {
	const dispatch = useDispatch();
	
	const openedSidebar = useSelector(({ fuse }) => fuse.navbar.open);
	const preferences = useSelector(selectUserPreferences, shallowEqual);
	const state = useSelector(selectSocialMediaPanelState);
	const user = useSelector(selectUser, shallowEqual);

	const ref = useRef();
	const theme = useTheme();

	const handleDocumentKeyDown = useCallback(
        (event) => {
            if (keycode(event) === 'esc') {
                dispatch(closeSocialMediaPanel());
            }
        },
        [dispatch]
    );

	const handlePanelToggle = useCallback(() => {
        dispatch(toggleSocialMediaPanel());

        if (openedSidebar) {
            dispatch(navbarClose());
        }
    }, [dispatch, openedSidebar]);

    const onSwipedLeft = useCallback(() => {
        if (state && theme.direction === 'rtl') {
            dispatch(closeSocialMediaPanel());
        }
    }, [state, theme.direction, dispatch]);

    const onSwipedRight = useCallback(() => {
        if (state && theme.direction === 'ltr') {
            dispatch(closeSocialMediaPanel());
        }
    }, [state, theme.direction, dispatch]);

    const handlers = useSwipeable({
        onSwipedLeft,
        onSwipedRight,
    });

	useEffect(() => {
		const fetchConversations = (preferences, permissions) => {
			const typesConfig = [
				{ type: 1, description: 'whatsapp', limit: 10 },
				{ type: 3, description: 'facebook', limit: 5 },
				{ type: 5, description: 'instagram', limit: 5 },
			];
	
			batch(() => {
				typesConfig.forEach(({ type, description, limit }) => {
					const isAllowed = isActionAllowed(permissions, description);
					const isPreferred = isPreferredAction(preferences, description);
	
					if (isAllowed && isPreferred) {
						dispatch(getConversations({ offset: '0', limit: String(limit), tipo: type }));
					}
				});
			});
		};
	
		fetchConversations(preferences, user.permissions);
	}, [dispatch, preferences, user.permissions]);
	

    useEffect(() => {
        if (state) {
            document.addEventListener('keydown', handleDocumentKeyDown);
        } else {
            document.removeEventListener('keydown', handleDocumentKeyDown);
        }
    }, [handleDocumentKeyDown, state]);

	/**
	 * Click Away Listener
	 */
	// useEffect(() => {
	//   function handleDocumentClick(ev) {
	//     if (ref.current && !ref.current.contains(ev.target)) {
	//       dispatch(closeSocialMediaPanel());
	//     }
	//   }

	//   if (state) {
	//     document.addEventListener('click', handleDocumentClick, true);
	//   } else {
	//     document.removeEventListener('click', handleDocumentClick, true);
	//   }

	//   return () => {
	//     document.removeEventListener('click', handleDocumentClick, true);
	//   };
	// }, [state, dispatch]);

	return (
		<>
			<CloseOpenPanel opened={state ? 1 : 0} openedSidebar={openedSidebar} {...handlers}>
				<IconButton
					className=""
					color="inherit"
					size="small"
					onClick={handlePanelToggle}
				>
					{state ? <Icon className="text-32">arrow_forward_ios</Icon> : <Icon className="text-32">arrow_back_ios</Icon>}
				</IconButton>
			</CloseOpenPanel>

			<Root opened={state ? 1 : 0} openedSidebar={openedSidebar} {...handlers}>
				<div className="panel flex flex-col max-w-full" ref={ref}>
					<Paper className="flex flex-1 flex-row min-h-px shadow-0 container-chat" square>
						<ConversationList className="flex shrink-0" />
						<ConversationPanel />
					</Paper>
				</div>
				<FilterByStatusBar/>
			</Root>
		</>
	);
};

export default withReducer('socialMediaPanel', reducer)(memo(SocialMediaPanel));
import { http } from "./services";

const handlePostRequest = async (url, params) => {
  try {
    const response = await http.post(url, params);
    return response.data;
  } catch (error) {
    console.error(`Error occurred while posting to ${url}:`, error);
    throw error;
  }
}

export const iniciarSesion = (params) => handlePostRequest("login", params);

export const registrarUsuario = (params) => handlePostRequest("auth/sign-up", params);

export const actualizarUsuario = (params) => handlePostRequest("auth/user/update", params);

export const actualizarPreferenciasUsuario = (params) => handlePostRequest("modificarPreferenciasUsuario", params);

export const obtenerAccessToken = (params) => handlePostRequest("auth/access-token", params);

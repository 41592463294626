import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const handleDownloadImage = () => {

}

const handleUploadImage = () => {

}

function MessageTypeImage(props){
    let image = props.content.payload;
    let imgUrl = (image && image.url) ? image.url : '';
    let caption = (image && image.caption) ? image.caption : '';

    return(
        <>
        {imgUrl.length > 0 && (    
        <>
        <div className="flex flex-col items-center justify-center">
        <img src={imgUrl} width="200" height="200"/>
        
        {caption && (
            <p className="w-full my-8">
            {caption}
            </p> 
        )
        }
        </div>

        <div className="flex justify-center items-stretch w-full">
            <div className="flex w-full justify-start">
              <Tooltip title="Descargar Archivo">
                    <IconButton
                    onClick={handleDownloadImage}
                    size="small">
                        <Icon>cloud_download</Icon>
                    </IconButton>
                </Tooltip>
            </div>

            <div className="flex w-full justify-center">
              <Tooltip title="Adjuntar este documento al credito del cliente">
                    <IconButton
                    onClick={handleUploadImage}
                    size="small">
                        <Icon>cloud_upload</Icon>
                    </IconButton>
                </Tooltip>
            </div>

            <div className="flex w-full justify-end">
              <Tooltip title="Ver en tamaño mayor">
                    <IconButton

                    size="small">
                        <Icon>zoom_in</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        </div>
        </>
        )}
        </>
    )
}

export default MessageTypeImage
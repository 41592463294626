import { styled } from "@mui/material";

const { default: StyledMessageContainerRowBase } = require("./StyledMessageContainerRowBase");

const StyledMessageContainerRow = styled(
    ({ bgColor, textColor, ...otherProps }) => <StyledMessageContainerRowBase {...otherProps} />
)(({ theme, bgColor, textColor }) => ({
        '&.me': {
            paddingLeft: 40,
            alignItems: 'flex-start',
            justifyContent: 'flex-end',

            '& .message-data-user': {
                alignItems: 'center',
                justifyContent: 'flex-end',
            },

            '& .message-data-user-group': {
                alignItems: 'center',
                justifyContent: 'flex-end',
            },

            '& .message-data-user-readtime': {
                alignItems: 'center',
                justifyContent: 'flex-end',
            },

            '& .message-data-client-name': {
                alignItems: 'center',
                justifyContent: 'flex-end',
            },

            '& .message-error': {
                alignItems: 'center',
                justifyContent: 'flex-end',
            },

            '& .avatar': {
                order: 2,
                margin: '0 0 0 16px',
            },

            '& .bubble': {
                // marginLeft: 'auto',
                backgroundColor: bgColor,
                color: textColor,
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
            },
            '&.first-of-group': {
                '& .bubble': {
                    borderTopRightRadius: 20,
                },
            },

            '&.last-of-group': {
                '& .bubble': {
                    borderBottomRightRadius: 20,
                },
            },
        },
    })
);

export default StyledMessageContainerRow;
import { socketStatus } from "app/auth/services/socket/Store/socketSlice";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { updateManagerInfo } from "./path/to/yourReduxSlice"; // Importa la acción correspondiente

const useConversationManager = () => {
    const dispatch = useDispatch();
    const isSocketConnected = useSelector(socketStatus);

    const fetchWhatsappConversationManager = useCallback(async (idConversacion, idCanal, enviados, carpeta, saludar = false, verificar = false) => {
        const data = {
            tipo: 1,
            idConversacion,
            idCanal,
            enviados,
            carpeta,
            saludar,
            verificar,
            operacion: "consultarEncargadoConversacion"
        };

        if (isSocketConnected) {
            dispatch({ type: 'socket/send', payload: data });
        } else {
            const result = await fetchWhatsappConversationManagerServices(data)
            //@todo impement whatsapp_consultar_etiqueta_conversacion
            /**
             *  if( consultarEtiqueta == true ){
                    that.whatsapp_consultar_etiqueta_conversacion(referencia);
                }


                if(enviados == true){
                    that.whatsapp_consultar_promedio_respuesta(referencia);
                    that.consultar_status_mensajes_enviados(referencia,'pending');
                    that.consultar_status_mensajes_enviados(referencia,'transmitted');
                }
             */
        }
    }, [dispatch, isSocketConnected]);

    return {
        fetchWhatsappConversationManager,
    };
};

export default useConversationManager;

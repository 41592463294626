const MessageTypeButton = ({ content }) => {
    const buttonText = content?.button?.text || '';

    return (
        <div className="flex">
            {buttonText && <p className="font-bold">{buttonText}</p>}
        </div>
    );
}

export default MessageTypeButton;

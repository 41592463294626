
import { addMessagesConversation as addMessagesConversationFb } from '../Utils/facebookUtil';
import { addMessagesConversation as addMessagesConversationIg } from '../Utils/instagramUtil';
import { adjustDateTimeByDifference, formatDateTimeInTimeZone } from 'app/utils/dateUtils';
import { closeSocialMediaPanel } from './stateSlice';
import { createAsyncThunk, createDraftSafeSelector, createSlice } from '@reduxjs/toolkit';
import { enviarMensaje, fetchResolutionsServices, obtenerMensajesConversacion } from 'src/app/services/socialMediaService';
import { orderMessages } from '../Utils/socialMediaUtil';
import { addMessagesToChannel, previewMessage, removeMessagesFromChannel, updateLastMessage, updateMessage } from '../Utils/whatsappUtil';
import { processChat } from '../Utils/shared/utils';
import { selectConversationList, selectSelectedConversation, selectSelectedConversationId, updateConversationList } from './conversationsSlice';
import { v4 as uuidv4 } from 'uuid';

export const getMessages = createAsyncThunk('socialMediaPanel/chat/getConversation', async ({ data }, { dispatch, getState }) => {
    try {
        let findChat = [];

        let chatState = selectChat(getState());

        const conversationList = [...selectConversationList(getState())];
        const conversation = conversationList.find((conversation) => conversation.id === data.params.idConversacion);

        if(data.params.tipo == 1){
            conversation.channels.forEach(result => {
                if (data.params.idCanal == null) {
                    findChat = [...findChat, ...result.messages];
                } else if (result.id == data.params.idCanal) {
                    findChat = [...findChat, ...result.messages];
                }
            });
        }else if([3,5].includes(data.params.tipo)){
            findChat = [...conversation.messages]
        }

        let processedChat = [];

        if (data.canLoadMoreFromAPI == undefined) {
            data.canLoadMoreFromAPI = true;
        }

        if ((findChat.length === 0 || findChat.length <= data.params.offset || findChat.length < 15) && data.canLoadMoreFromAPI) {

            const messages = await obtenerMensajesConversacion(data.params);
            if (data.params.limit > 1){
                const resolutions = await fetchResolutionsServices({ a: messages[messages.length - 1].createdDatetime, b: messages[0].createdDatetime, idConversacion: data.params.idConversacion, idCanal: data.params.idCanal, tipo: data.params.tipo });
                messages.push(...resolutions);
            }
            let reloadChat = (data.reloadChat) ? data.reloadChat : false;
            processedChat = await processChat({ messages, chatState, reloadChat });
            const idConversacion = data.params.idConversacion;

            if (data.params.tipo === 1) {
                dispatch(addMessagesToChannel({ idConversacion, messages }))
            } else if (data.params.tipo === 3) {
                dispatch(addMessagesConversationFb({ idConversacion, messages }))
            } else if (data.params.tipo === 5) {
                dispatch(addMessagesConversationIg({ idConversacion, messages }))
            }

        } else {
            processedChat = findChat;
        }
        return processedChat;
    } catch (error) {
        console.log(`error getMessages: ${error}`)
    }
});

export const sendMessage = createAsyncThunk(
    'socialMediaPanel/chat/sendMessage',
    async ({
        message,
        conversationNumber,
        conversationId,
        channelId,
        type,
        tempId,
        isRetry,
    }, { dispatch, getState }
) => {
    const params = {
        para: conversationNumber,
        idConversacion: conversationId,
        idCanal: channelId,
        type,
        contenido: message,
        tipo: 1,
    };

    const state = getState();
    const originalChat = state.socialMediaPanel.chat;
    let conversations = state.socialMediaPanel.conversation.conversationList;

    try {
        if (isRetry) {
            // Eliminar el mensaje temporal de la conversación en caso de reintento
            conversations = conversations.map((conversation) => {
                if (conversation.id === conversationId) {
                    return {
                        ...conversation,
                        channels: conversation.channels.map((channel) => {
                            if (channel.id === channelId) {
                                return {
                                    ...channel,
                                    messages: channel.messages.filter((msg) => msg.id !== tempId),
                                };
                            }
                            return channel;
                        }),
                    };
                }
                return conversation;
            });

            // Dispatch para eliminar el mensaje del canal
            dispatch(removeMessagesFromChannel({
                idConversation: conversationId,
                idChannel: channelId,
                idMessage: tempId,
            }));
        }

        // Enviar el mensaje a través de la API
        const data = await enviarMensaje(params);

        // Actualizar el mensaje en el chat
        const { updatedMessage } = updateMessage({
            originalChat,
            data,
            tempId,
        });

        // Actualizar el último mensaje en la lista de conversaciones
        const updatedLastMessage = updateLastMessage({
            conversations,
            messageObj: updatedMessage,
        });

        // Dispatch para actualizar la lista de conversaciones
        dispatch(updateConversationList(updatedLastMessage));

        return updatedMessage;
    } catch (error) {
        if (isRetry) {
            return false;
        }

        // Obtener detalles de la conversación
        const conversation = conversations.find((value) => value.id === conversationId);

        // Configuración de zona horaria
        let timeZone = 'America/Asuncion';
        let diferenceTimezone = '-04:00';
        const userSettingsString = sessionStorage.getItem('userSettings');

        if (userSettingsString) {
            const userSettings = JSON.parse(userSettingsString);
            timeZone = userSettings.timezone_diference;
            diferenceTimezone = userSettings.diference_timezone;
        }

        // Obtener la fecha y hora ajustada
        const now = formatDateTimeInTimeZone(timeZone);
        const adjustedDate = adjustDateTimeByDifference(now, diferenceTimezone);

        // Construir el mensaje a enviar
        const messageToSend = {
            id: tempId,
            conversationId,
            channelId,
            createdDatetime: adjustedDate,
            createdDatetimeConverted: adjustedDate,
            direction: 'sent',
            status: 'pending',
            type: 'text',
            content: {
                text: message,
            },
            message,
            conversationNumber,
            typeToSend: type,
            // MimeContentType:null
            // idUsuario: 3
            // NombreUsuario: "Support New Age",
            // idGrupo: 53,
            // NombreGrupo: "Ventas",
            // idCliente: null,
            // NombreCliente: null,
            // tmu: now,
            // response_time_A: null
            // costo: 0.000000
        };

        // Determinar el tipo de error
        if (error?.code === 'ERR_NETWORK') {
            messageToSend.isConnectionError = true;
        } else {
            messageToSend.isAnotherError = true;
        }

        console.log('messageToSend', messageToSend);

        // // Dispatch para agregar el mensaje
        // dispatch(addMessage(messageToSend));
        // dispatch(addMessagesToChannel({
        //     idConversacion: conversationId,
        //     messages: [messageToSend],
        // }));

        return false;
    }
});

export const sendMessageWhitFails = () => (dispatch, getState) => {
    const state = getState();
    let conversations = state.socialMediaPanel.conversation.conversationList;
    try {
        if (conversations?.length) {
            conversations.map((conversation) => {
                conversation.channels?.map((channel) => {
                    channel.messages?.map((message) => {
                        if (message?.isConnectionError) {
                            const msgObj = {
                                message: message.message,
                                conversationNumber: message.conversationNumber,
                                conversationId: message.conversationId,
                                channelId: message.channelId,
                                type: message.typeToSend,
                                tempId: message.id,
                                id: message.id,
                                isRetry: true,
                            };

                            dispatch(sendMessage(msgObj));
                        }
                    })
                })
            });
        }
    } catch (error) {
        console.log('Error to resent messages')
    }
}

export const previewSendedMessage = (message) => (dispatch, getState) => {
    const tempId = `temp-${uuidv4()}`;
    message.id = tempId;

    const conversationId = selectSelectedConversationId(getState());
    const selectedConversation = selectSelectedConversation(getState());
    const newMessage = previewMessage(message);

    if (selectedConversation) {
        dispatch(addMessagesToChannel({
            idConversacion: conversationId,
            messages: [newMessage],
        }));
    }   
    
    return tempId;
};

export const selectChat = (state) => state.socialMediaPanel.chat;

export const selectChatByConversationId = createDraftSafeSelector(
    [selectChat, (_, conversationId) => conversationId],
    (chat, conversationId) => {
        if (conversationId === undefined) return [];

        return chat.filter(({ conversationId: id }) => id === conversationId);
    }
);

export const selectChatByConversationIdAndType = createDraftSafeSelector(
    [selectChat, (_, filter) => filter],
    (chat, { conversationId, type }) => {
        if (conversationId === undefined) return [];

        return chat.filter(({ conversationId: id, tipo }) => id === conversationId && tipo === type);
    }
);

const chatSlice = createSlice({
    name: 'socialMediaPanel/chat',
    initialState: [],
    reducers: {
        removeChat: (state, action) => null,
        updateChat: (state, action) => {
            // return state = action.payload;
        },
        addItemChat: (state, action) => {
            /**
            let chatState = [...state];
            chatState.push(action.payload);
            chatState = orderMessages(chatState);

            state = chatState;
            return state;
            */
        },
        updateItemChat: (state, action) => {
            /**
            const id = action.payload.id;
            let chatState = [...state];
            const chatIndex = chatState.findIndex((item) => item.id === id);
            chatState[chatIndex] = action.payload;
            chatState = orderMessages(chatState);
            state = chatState;

            return state;
            */
        }
    },
    extraReducers: {
        [getMessages.fulfilled]: (state, action) => {
            return state = action.payload;
        },
        [sendMessage.fulfilled]: (state, action) => {
            //state.dialog = [...state.dialog, action.payload];
        },
        [closeSocialMediaPanel]: (state, action) => null,
    },
});

export const { removeChat, updateChat, addItemChat, updateItemChat } = chatSlice.actions;


export default chatSlice.reducer;
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import {formatNumber} from './Utils/shared/utils';
import { closeSocialMediaCart } from './store/cartSlice';


const CartPanelDiv = styled('div')(({ theme }) => ({
    background: theme.palette.background.paper,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    minWidth: 0,
    transition: 'min-width 0.35s linear'
  }));

const ItemList = (props) => {
    let type = props.type;
    return(
        <>
        {props.items.map((item,i) => {
            let url = item.url;
            let description = item.descripcion;
            let currency = item.currency;
            let price = (type === 'order') ? item.item_price : item.precio;
            let quantity = (item.quantity) ? item.quantity : null;
    
            return(
                <div style={{width:'100%',display:'flow-root'}} key={i}>
                <img src={url} style={{width:'80px',float:'left',margin:'5px 0 5px 5px'}}/>
                <div style={{width:'CALC(100% - 90px)',float:'left',padding:'5px',borderTop:'1px solid #9e9e9e'}}>
                    <p style={{width:'100%',float:'left'}}>{description}</p>
                    <p style={{width:'100%',float:'left',color:'#b1afaf'}}>
                        {currency} {formatNumber(price)} 
                        {quantity && (<>Cantidad: {quantity}</>)}
                    </p>
                </div>
                </div>
            )
        })
        }
        </>
    )
};


function CartPanel(props){
    const dispatch = useDispatch();
    const cartPanel = useSelector(({ socialMediaPanel }) => socialMediaPanel.cart);
    let objData = (cartPanel.length > 0) ? cartPanel[0] : cartPanel;
   
    const openCartPanel = {
        minWidth: (cartPanel.length > 0) ? 250 : 0
    };

    const CartPanelContent = (props) => {
        
        const closeCartPanel = () =>{
            dispatch(closeSocialMediaCart());
        }

        const cartPanelObj = props.content;
        const type = cartPanelObj.data.type;
        const items = (cartPanelObj.data.items) ? cartPanelObj.data.items : '';
        const sections = (cartPanelObj.data.sections) ? cartPanelObj.data.sections : '';
        const action = (cartPanelObj.data.action) ? cartPanelObj.data.action : '';
        let countItems = items.length;
        let countText = (countItems > 1) ? 'elementos' : 'elemento';
        let headerTitle;
        let total = (items.length > 0) ? Object.values(items).reduce((t, {item_price}) => t + item_price, 0) : '';
        let currency = (items.length > 0) ? items[0].currency : '';
    
        total = formatNumber(total)
    
        if(type === 'order'){
            headerTitle = 'Carrito enviado';
        }
    
        if(type === 'product' || type === 'product_list'){
            headerTitle = cartPanelObj.data.headerTitle;
        }
    
        return(
            <>
            <div className="flex w-full items-center" style={{background:'#919ea5',height:50}}>
                <button type="button" onClick={closeCartPanel}>
                    <Icon sx={{ fontSize: 50}} color="primary">arrow_back</Icon>
                </button>
                <p style={{fontSize: 16}}>{headerTitle}</p>
            </div>
            
            {items.length > 0 && (
                <>
                <div className="flex w-full">
                    <div style={{width:'100%',float:'left',padding:'30px 15px 15px 15px'}}>
                    <p style={{width:'100%',float:'left'}}>{countItems} {countText}</p>
                    <p style={{width:'100%',float:'left',color:'#b1afaf'}}>{currency} {total} (estimado)</p>
                    </div>
                </div>
    
                <div className="flex w-full">
                    <ItemList items={items} type={type}/>
                </div>
                </>
            )}
    
            {type === 'product' && (
                <div style={{width:'100%',display:'flow-root'}}>
                    <img src={action.url} style={{width:'80px',float:'left',margin:'5px 0 5px 5px'}}/>
                    <div style={{width:'CALC(100% - 90px)',float:'left',padding:'5px',borderTop:'1px solid #9e9e9e'}}>
                        <p style="width:100%;float:left;">{action.descripcion}</p>
                        <p style="width:100%;float:left;color:#b1afaf;">{action.currency} {formatNumber(action.precio)}</p>
                    </div>
                </div>
            )}
    
    
            {type === 'product_list' && (
                sections.map((item) => {
                    return(
                        <>
                        <p className="w-full flex" style={{float:'left',color:'#009688',fontSize:'16px',padding:'5px 5px 5px 15px'}}>
                            {item.title}
                        </p>
                        <ItemList items={item.product_items} type={type}/>
                        </>
                    )
                })
            )}
            </>
        )
    };

    return(
        <CartPanelDiv style={openCartPanel}>
            {cartPanel.length > 0 && (
                <CartPanelContent content={objData}/>
            )}
        </CartPanelDiv>
    )
}

export default CartPanel;
import { nn } from "date-fns/locale";
import moment from "moment";

export const orderConversations = (conversations) => {
    const normalizedConversations = JSON.parse(JSON.stringify(conversations));

    return normalizedConversations.sort((a, b) => {
        let dateA =  moment(subtractHour(a.updatedDatetime), "YYYY-MM-DD hh:mm:ss")


        let dateB = moment(subtractHour(b.updatedDatetime), "YYYY-MM-DD hh:mm:ss");


        if (a.lastMessage && a.lastMessage.createdDatetime) {
            dateA = moment(subtractHour(a.lastMessage.createdDatetime), "YYYY-MM-DD hh:mm:ss");
        }

        if (b.lastMessage && b.lastMessage.createdDatetime) {
            dateB = moment(subtractHour(b.lastMessage.createdDatetime), "YYYY-MM-DD hh:mm:ss");
        }

        return dateB.diff(dateA);
    });
};


export const filterConversationByUserPreferences = (conversations, preferencias) => {
    const typesDescrption = { 1: 'whatsapp', 3: "facebook", 5: "instagram" }

    return conversations.filter((conversation) => {
        return preferencias[typesDescrption[conversation.tipo]]
    })
}

export const orderMessages = (messages) => {
    messages = messages.sort(function (b, a) {
        if (a.createdDatetime && b.createdDatetime) {
            let contentA = a.createdDatetime.replace(/T/g, ' ').replace(/Z/g, '');
            let contentB = b.createdDatetime.replace(/T/g, ' ').replace(/Z/g, '');

            if (contentA.indexOf('.') > -1) {
                contentA = contentA.split('.')[0];
            }

            if (contentB.indexOf('.') > -1) {
                contentB = contentB.split('.')[0];
            }

            contentA = Number(contentA.replace(/ /g, '').replace(/-/g, '').replace(/:/g, ''));
            contentB = Number(contentB.replace(/ /g, '').replace(/-/g, '').replace(/:/g, ''));
            return contentA < contentB ? 1
                : contentA > contentB ? -1
                    : 0;
        }
        return a;
    });
    return messages;
}

export const subtractHour = (date) => {
    if (date == null) {
        //console.log('fecha nula')
        // console.trace();
        return '';
    }
    if (date.indexOf('T') > -1) {
        date = date.split('T');
    } else {
        date = date.split(' ');
    }

    let date1 = date[0].split('-');
    let hours = date[1].replace('Z', '').split(':');
    let newDate = new Date(Number(date1[0]), Number(date1[1]) - 1, Number(date1[2]), Number(hours[0]), Number(hours[1]), Number(hours[2]));

    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());

    let year = newDate.getFullYear();
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();

    let hour = newDate.getHours();
    let minute = newDate.getMinutes();
    let second = newDate.getSeconds();

    if (month < 10) { month = '0' + month; }
    if (day < 10) { day = '0' + day; }
    if (hour < 10) { hour = '0' + hour; }
    if (minute < 10) { minute = '0' + minute; }
    if (second < 10) { second = '0' + second; }

    newDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

    return newDate;
}
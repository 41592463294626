import { selectSelectedConversation } from "../store/conversationsSlice";
import { useSelector } from "react-redux";
import InfoConversationFb from "./facebook/InfoConversation";
import InfoConversationIg from "./instagram/InfoConversation";
import InfoConversationWsp from "./whatsapp/InfoConversation";

const InfoConversationType = ({ type, conversation }) => {
	let InfoConversation_ = () => <>Unsupported</>;

	if (type === 1) {
		InfoConversation_ = InfoConversationWsp;
	} else if (type === 3) {
		InfoConversation_ = InfoConversationFb;
	} else if (type == 5) {
		InfoConversation_ = InfoConversationIg;
	}

	return (
		<InfoConversation_ conversation={conversation} />
	);
}

const InfoConversation = () => {
	const selectedConversation = useSelector(selectSelectedConversation);

	return (
		<div className="flex flex-col items-left w-full">
			<InfoConversationType type={selectedConversation.tipo} conversation={selectedConversation} />
		</div>
	);
};

export default InfoConversation;


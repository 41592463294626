import AnalyticsDashboardAppConfig from './analytics/AnalyticsDashboardAppConfig';
import ConfigurationsDashboardAppConfig from './configurations/ConfigurationsDashboardAppConfig';
import StateUsersDashboardAppConfig from './StateUsers/StateUsersDashboardAppConfig';
import CryptoDashboardAppConfig from './crypto/CryptoDashboardAppConfig';
import FinanceDashboardAppConfig from './finance/FinanceDashboardAppConfig';
import ProjectDashboardAppConfig from './project/ProjectDashboardAppConfig';
import ResourcesDasbhoardAppConfig from './resources/ResourcesDashboardAppConfig';

const dashboardsConfigs = [
  AnalyticsDashboardAppConfig,
  ConfigurationsDashboardAppConfig,
  StateUsersDashboardAppConfig,
  CryptoDashboardAppConfig,
  FinanceDashboardAppConfig,
  ProjectDashboardAppConfig,
  ResourcesDasbhoardAppConfig
];

export default dashboardsConfigs;

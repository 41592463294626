import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { selectConversationList, selectFilteredBy, setFilteredBy, setIsFiltered, updateConversationList } from './store/conversationsSlice';

const ButtonToRemoveFilterByStatus = () => {
    const dispatch = useDispatch();
    const estado = useSelector(selectFilteredBy);
    const conversations = useSelector(selectConversationList)

    const onRemoveFilter = () => {

        const conversationsWithoutFiltered = conversations.filter((conversation) => !conversation.filtered);

        dispatch(updateConversationList(conversationsWithoutFiltered));
        dispatch(setIsFiltered(false));
        dispatch(setFilteredBy(''));
    };

    return estado ? (  
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>

            <Tooltip title="Quitar filtro">
                <IconButton onClick={onRemoveFilter}>
                    <CloseIcon />
                </IconButton>
            </Tooltip>

            <span style={{ fontSize: '14px', color: '#555' }}>
                Filtrado por <strong>{estado}</strong>
            </span>
        </div>
    ) : null;
};

export default ButtonToRemoveFilterByStatus;

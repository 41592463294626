import { format } from "date-fns";

/**
 * Formatea la fecha actual en el formato "baseName dd/MM/yyyy"
 * @param {string} baseName - El nombre base para el archivo
 * @return {string} - El nombre del archivo formateado
 */
export const getDateFormattedFileName = (baseName) => {
    const now = new Date();
    const formattedDate = format(now, 'dd/MM/yyyy');
    
    return `${baseName} ${formattedDate}`;
};
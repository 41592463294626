import React from 'react';
import { Box, Typography } from '@mui/material';

const TemplateTypeMedia = ({ elements }) => {
    return (
        <Box>
            {elements.map((element, i) => {
                if (element.url) {
                    let dato = element.url.replace('https://www.facebook.com/', '');
                    dato = dato.split('/photos/');
                    let a = dato[0];
                    let b = dato[1].split('/')[1];
                    return (
                        <img key={i} src={`/amg/fb/get_album_picture/${a}-${b}`} alt="" />
                    );
                }
                if (element.attachment_id) {
                    console.log(element);
                }
                return null;
            })}
        </Box>
    );
};

export default TemplateTypeMedia;

import { Picker } from "emoji-mart";
import { previewSendedMessage, sendMessage } from "../../store/chatSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import AudioRecorder from "../AudioRecorder";
import data from "@emoji-mart/data";
import DivEmojiPicker from "app/theme-layouts/shared-components/whatsappPanel/Utils/components/DivEmojiPicker";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";

const FormChatFb = ({ conversation }) => {
    const dispatch = useDispatch();

    const [chatIsBlocked, setChatIsblocked] = useState(false);
    const [menuPresetChat, setMenuPresetChat] = useState(null);
    const [message, setMessage] = useState("");
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const selectedUserId = conversation.fb_user;

    const { t } = useTranslation();

    const handleShowEmojiPicker = () => {
        let statePicker = showEmojiPicker ? false : true;
        setShowEmojiPicker(statePicker);
    };

    const handleEmojiSelected = (props) => {
        let emoji = props.native;
        let newText = message + emoji;
        setMessage(newText);
    };

    const handleMenuPresetChatClick = (e) => setMenuPresetChat(e.currentTarget);

    const handleMenuPresetChatClose = (event) => {
        setMenuPresetChat(null);
    }

    const onInputChange = (ev) => {
        setMessage(ev.target.value);
    };
    
    const onMessageSubmit = (ev) => {
        ev.preventDefault();
        if (message === "") {
            return;
        }

        let msgObj = {
            message,
            conversationUserId: selectedUserId,
            conversationId: conversation.id,
        };

        let tempId = dispatch(previewSendedMessage(msgObj));
        msgObj.tempId = tempId;

        setMessage("");
        dispatch(sendMessage(msgObj));
    };

    const EmojiPicker = (props) => {
        const ref = useRef();

        useEffect(() => {
            new Picker({ ...props, data, ref });
        }, []);

        return <div ref={ref} />;
    }

    // Handle sending audio from AudioRecorder component
    const handleSendAudio = (audioBlob) => {
        const reader = new FileReader();
        reader.readAsDataURL(audioBlob);
        reader.onloadend = () => {
            const base64Audio = reader.result.split(',')[1];

            // TODO: Upload audio to API
        };
    };

    return (
        <form
            onSubmit={onMessageSubmit}
            className="relative left-0 right-0"
        >
            <Paper className="rounded-none flex items-center relative shadow-none">
                <div className="flex relative">
                    {showEmojiPicker && (
                        <>
                            <DivEmojiPicker id="divEmojiPicker">
                                <EmojiPicker
                                    onEmojiSelect={handleEmojiSelected}
                                />
                            </DivEmojiPicker>
                        </>
                    )}
                    <IconButton
                        onClick={handleShowEmojiPicker}
                        size="small"
                        className="ml-[15px]">
                        <Icon>insert_emoticon</Icon>
                    </IconButton>
                </div>
                <InputBase
                    autoFocus={false}
                    id="message-input"
                    className="flex flex-1 grow shrink-0 mx-16 ltr:mr-48 rtl:ml-48 my-8"
                    placeholder={t("wsPanel.formChat.writeMessage")}
                    onChange={onInputChange}
                    // onPaste={onInputPaste}
                    value={message}
                />
                <IconButton
                    aria-owns={menuPresetChat ? "chats-more-menu" : null}
                    aria-haspopup="true"
                    onClick={handleMenuPresetChatClick}
                    size="small">
                    <Icon>more_vert</Icon>
                </IconButton>
                <Menu
                    id="chats-more-menu"
                    anchorEl={menuPresetChat}
                    open={Boolean(menuPresetChat)}
                    onClose={handleMenuPresetChatClose}>
                    <MenuItem onClick={handleMenuPresetChatClose}>
                        {t("wsPanel.formChat.presetTemplates")}
                    </MenuItem>
                </Menu>
                <AudioRecorder
                    onSendAudio={handleSendAudio}
                    chatIsBlocked={chatIsBlocked}
                    t={t}
                />
            </Paper>
        </form>
    );
}

export default FormChatFb
import { styled } from "@mui/material/styles";

const DivEmojiPicker = styled("div")(({ theme }) => ({
    display: "flex",
    position: "absolute",
    left: 0,
    bottom: "30px",
    zIndex: 1000,
}));

export default DivEmojiPicker;

import { configureStore } from '@reduxjs/toolkit';
import stateReducer from './stateSlice';
import tabChannelReducer from './tabChannelSlice';
import conversationsReducer from './conversationsSlice';
import userReducer from '../../../../store/userSlice';
import usersStatesReducer from '../../../../store/usersStatesSlice';
import socketReducer from '../../../../auth/services/socket/Store/socketSlice';
import chatReducer from './chatSlice';
import settingsReducer from '../../../../store/fuse/settingsSlice';
import i18nReducer from '../../../../store/i18nSlice';

const rootReducer = {
    state: stateReducer,
    tabChannel: tabChannelReducer,
    conversations: conversationsReducer,
    user: userReducer,
    usersStates: usersStatesReducer,
    settings: settingsReducer,
    i18n: i18nReducer,
    socket: socketReducer,
    chat: chatReducer,
};

const store = configureStore({
    reducer: rootReducer,
});

export default store;

import { useState } from 'react';
import MessageTypeFile from 'app/whatsapp-panel/components/MessageType/MessageTypeFile';

function MessageTypeDocument(props) {
    const [file, setFile] = useState(null);
    const item = props.item;
    
    return (
        <>
            <MessageTypeFile item={item} />
        </>
    )
}

export default MessageTypeDocument
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';

const IconStyled = styled(Icon)({
    fontSize: 15,
});

const statusAttributes = {
    failed: { color: 'red', icon: 'close' },
    rejected: { color: 'red', icon: 'close' },
    transmitted: { color: 'default', icon: 'lens' },
    pending: { color: 'rgb(155 155 155)', icon: 'schedule' },
    sending: { color: 'rgb(155 155 155)', icon: 'schedule' },
    sent: { color: 'default', icon: 'check' },
    delivered: { color: 'default', icon: 'done_all' },
    read: { color: '#4fb6ec', icon: 'done_all' }
};

function MessageStatusWsp({ status }) {
    const { color, icon } = statusAttributes[status] || {};

    return (
        <div className="flex items-center ml-2">
            {icon && (
                <IconStyled style={{ color }}>{icon}</IconStyled>
            )}
        </div>
    );
}

export default MessageStatusWsp;

import React from 'react';

const TypeErrorDefault = ({ error = {} }) => {
    const { title = '', message = '' } = error;
    const displayError = title || message;

    const styleError = {
        color: '#f44336',
        display: 'flex',
    };

    return (
        <div className="message-error">
            <p style={styleError}>{displayError}</p>
        </div>
    );
};

export default TypeErrorDefault;

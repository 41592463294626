/* eslint import/no-extraneous-dependencies: off */
import _ from '@lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setInitialSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import history from '@history';
import jwtService from '../auth/services/jwtService';
import settingsConfig from 'app/configs/settingsConfig';

export const setUser = createAsyncThunk(
    'user/setUser',
    async (user, { dispatch, getState }) => {
        /*You can redirect the logged-in user to a specific route depending on his role*/
        if (user.loginRedirectUrl) {
            settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
        }

        return user;
    }
);

export const updateUserSettings = createAsyncThunk(
    'user/updateSettings',
    async (settings, { dispatch, getState }) => {
        const { user } = getState();
        const newUser = _.merge({}, user, { data: { settings } });

        dispatch(setUser(newUser));
        // dispatch(updateUserData(newUser));

        return newUser;
    }
);

export const updateUserShortcuts = createAsyncThunk(
    'user/updateShortucts',
    async (shortcuts, { dispatch, getState }) => {
        const { user } = getState();
        const newUser = {
            ...user,
            data: {
                ...user.data,
                shortcuts,
            },
        };

        dispatch(updateUserData(newUser));

        return newUser;
    }
);

export const updateUserPreferences = createAsyncThunk(
    'user/updatePreferences',
    async ({ preferences, displayNotification = true }, { dispatch, getState }) => {
        const { user } = getState();

        const newUser = {
            ...user,
            data: {
                ...user.data,
                preferences,
            },
        };

        try {
            await jwtService.updateUserPreferences({ preferences });
            if (displayNotification) {
                dispatch(showMessage({ message: 'Se han guardado tus preferencias', variant: 'success' }));
            }
        } catch (error) {
            dispatch(showMessage({
                message: error.response?.data?.msg || 'Error al guardar preferencias',
                variant: 'error',
            }));
        }

        return newUser;
    }
);


export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (user, { dispatch, getState }) => {
      const { user: currentUser } = getState();
      const newUser = _.merge({}, currentUser, user);

      dispatch(setUser(newUser));

      return newUser;
    }
  );

export const updateUserData = (user) => async (dispatch, getState) => {
    if (!user.role || user.role.length === 0) {
        // is guest
        return;
    }
    /**
    jwtService
        .updateUserData(user)
        .then(() => {
            dispatch(showMessage({ message: 'User data saved with api' }));
        })
        .catch((error) => {
            dispatch(showMessage({ message: error.message }));
        });
    */
};

const userSlice = createSlice({
    name: 'user',
    initialState: null,
    reducers: {
        userLoggedOut: (state, action) => null,
    },
    extraReducers: {
        [updateUserSettings.fulfilled]: (state, action) => action.payload,
        [updateUserShortcuts.fulfilled]: (state, action) => action.payload,
        [setUser.fulfilled]: (state, action) => action.payload,
        [updateUser.fulfilled]: (state, action) => action.payload,
    },
});

export const logoutUser = () => async (dispatch, getState) => {
    history.push({
        pathname: '/',
    });

    dispatch(setInitialSettings());

    return dispatch(userLoggedOut());
};

export const { userLoggedOut } = userSlice.actions;

export const selectUser = ({ user }) => user;

export const selectUserShortcuts = ({ user }) => user.data.shortcuts;

export const selectUserPreferences = ({ user }) => user.data.preferences;

export default userSlice.reducer;

// Http request handler configuration file
import axios from 'axios';
import JwtService from 'src/app/auth/services/jwtService';

const httpInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

httpInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (err) => {
        console.log(err);
        if (err.response) {
            if (err.response.status && err.response.status == 403) {
                JwtService.logout();
                dispatch({ type: 'socket/disconnect' });
            }
        }
        return new Promise((resolve, reject) => {
            throw err;
        });
    }
);

const http = {
    get: (url, config) => httpInstance.get(url, config),
    post: (url, data, config) => httpInstance.post(url, data, config),
    put: (url, data, config) => httpInstance.put(url, data, config),
    delete: (url, config) => httpInstance.delete(url, config),
    patch: (url, data, config) => httpInstance.patch(url, data, config),
    head: (url, config) => httpInstance.head(url, config),
    options: (url, config) => httpInstance.options(url, config),
};

export { httpInstance, http };

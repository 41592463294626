import { styled } from '@mui/material/styles';

const ImgStyled  = styled('img')(({ theme }) => ({
    width: 200,
    height: 200,
}));

const MessageTypeSticker = ({ type, content }) => {
    const stickerUrl = (type === 'sticker') ? content.sticker.url : content.whatsappSticker.link;

    return (
        <div className="flex">
            <ImgStyled src={stickerUrl} alt="sticker" />
        </div>
    );
}

export default MessageTypeSticker;

import { Avatar } from "@mui/material";
import React from 'react'

const AvatarWs = ({ alt }) => {
    return (
        <div className="relative">
            <Avatar src="/static/blank-profile.png" alt={alt}/>
            <div className="absolute left-0 bottom-0 transform -translate-x-1/3 translate-y-1/3">
                <img
                    width="23px"
                    src="assets/images/logo/logo-ws-solid.svg"
                    alt="Logo Whatsapp"
                />
            </div>
        </div>
    )
}

export default AvatarWs
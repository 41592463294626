import MessageTypeFile from 'app/whatsapp-panel/components/MessageType/MessageTypeFile';

function MessageTypeAudio(props) {
    const { item } = props;
    const audioAttachment = item.attachments.find(att => att.type === 'audio');

    if (!audioAttachment) {
        return null;
    }

    const fileItem = {
        type: audioAttachment.type,
        payload: audioAttachment.payload,
    };

    return (
        <>
            <MessageTypeFile item={fileItem} />
        </>
    );
}

export default MessageTypeAudio;

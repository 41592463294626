import { configureStore } from '@reduxjs/toolkit';
import createReducer from './rootReducer';
import { persistStore,persistReducer } from 'redux-persist'
import sessionStorage from "redux-persist/es/storage/session"
import {Socket} from '../auth/services/socket/socket';
import {socketMiddleware} from '../auth/services/socket/socketMiddleware';

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ["user","socialMediaPanel"],
  timeout: null,
}

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

const socketMiddleware1 = socketMiddleware(new Socket());

const middlewares = [socketMiddleware1];

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({ collapsed: (getState, action, logEntry) => !logEntry.error });
  //deshabilitado para tener la consola más limpia
  //middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, createReducer());

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
});

store.asyncReducers = {};
export const persistor = persistStore(store,{ manualPersist: true });
store.persistor = persistor;

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(persistReducer(persistConfig, createReducer(store.asyncReducers)));
  store.persistor.persist();
  return store;
};

export default store;

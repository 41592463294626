const ConversationParticipantsSummary = ({ encargados }) => (
    <>
        {encargados.map((encargado, index) => (
            <div
                className={index === encargados.length - 1 ? 'mt-5' : 'mt-0'}
                key={index}
            >
                <strong>Departamento:</strong>{' '}
                <span>{encargado.NombreGrupo}</span> |{' '}
                <strong>Usuario:</strong>{' '}
                <span>{encargado.NombreUsuario}</span>
            </div>
        ))}
    </>
);

export default ConversationParticipantsSummary;
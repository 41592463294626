import React from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const RetryModal = ({ open, onClose, onRetry }) => (
    <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="retry-modal-title"
        aria-describedby="retry-modal-description"
    >
        <Box sx={style}>
            <Typography id="retry-modal-title" variant="h6" component="h2">
                Reintentar envío
            </Typography>
            <Typography id="retry-modal-description" sx={{ mt: 2 }}>
                No se enviaron tu mensaje ni tus acciones sobre este mensaje. ¿Quieres reintentar el envío?
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={onRetry} variant="contained" color="primary">
                    Volver a intentar
                </Button>
            </Box>
        </Box>
    </Modal>
);

export default RetryModal;

import { Avatar} from "@mui/material";
import React from 'react'

const AvatarFb = ({ user_pic, page_pic }) => {
  return (
      <div className="relative">
          {user_pic ? (
              <Avatar src={`${user_pic}`} />
          ) : (<Avatar src="/static/blank-profile.png" />)}
          <div className="absolute left-0 bottom-0 transform -translate-x-1/3 translate-y-1/3">
              <img
                  width="25px"
                  src="assets/images/logo/logo-ms-solid.svg"
                  alt="Logo messenger"
              />
          </div>
          <div className="absolute right-0 bottom-0 transform translate-x-1/3 translate-y-1/3 rounded-full overflow-hidden">
              <img
                  width="25px"
                  src={page_pic}
                  alt="Logo fan page"
                  className="rounded-full"
              />
          </div>
      </div>
  )
}

export default AvatarFb
import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const TemplateTypeGeneric = ({ element }) => {
    const { image_url, title, subtitle, item_url, buttons } = element;

    return (
        <Box sx={{ width: 200 }}>
            {image_url && (
                <Box sx={{ width: '100%' }}>
                    <img src={image_url} alt={title} style={{ width: '100%' }} />
                </Box>
            )}
            <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold' }}
            >
                {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{color: "#fff"}}>
                {subtitle}
            </Typography>
            {item_url && (
                <Link href={item_url} target="_blank" rel="noopener" sx={{ wordBreak: 'break-word' }}>
                    {item_url}
                </Link>
            )}
            {buttons && buttons.map((button, i) => (
                <Typography key={i} variant="button" display="block">
                    {button.title}
                </Typography>
            ))}
        </Box>
    );
};

export default TemplateTypeGeneric;

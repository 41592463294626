import { lazy } from 'react';
// import { Navigate } from 'react-router-dom';

const ReportesApp = lazy(() => import('./ReportesApp'));

const ReportesAppConfig = {
  settings: {
    layout: {
      config: {},
    },   
    param: 'reportes'
  },
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/apps/reportes`,
      element: <ReportesApp />,
    },
  ],
};

export default ReportesAppConfig;   
const formatWhatsAppText = (text) => {
    if (typeof text !== 'string') {
        return text; // Verificar si el texto es una cadena
    }

    let newText = text;

    // Replace ```text``` with <pre>text</pre> for monospaced text
    newText = newText.replace(/```([^`]+)```/g, '<pre class="bg-gray-800 text-white p-4 rounded my-4">$1</pre>');

    // Replace `> text` with <blockquote>text</blockquote>
    newText = newText.replace(/^> (.*$)/gim, '<blockquote class="bg-gray-100 border-l-4 border-blue-500 text-gray-700 italic p-4 my-4">$1</blockquote>');

    // Replace `text` with <code>text</code> for inline code
    newText = newText.replace(/`([^`]+)`/g, '<code class="bg-gray-200 text-black-600 p-4 rounded">$1</code>');

    // Replace `_text_` with <i>text</i> for italic
    newText = newText.replace(/_([^_]+)_/g, '<i>$1</i>');

    // Replace `*text*` with <b>text</b> for bold
    newText = newText.replace(/\*(\S(.*?\S)?)\*/g, '<b>$1</b>');

    // Replace `~text~` with <s>text</s> for strikethrough
    newText = newText.replace(/~([^~]+)~/g, '<s>$1</s>');

    // Handle bullet lists with asterisk or hyphen
    newText = newText.replace(/((^\s*[\*\-]\s+.*$\n?)+)/gm, (match) => {
        const items = match.trim().split('\n').map(item => item.trim().replace(/^[\*\-]\s+/, '')).filter(item => item);
        return `<ul class="list-disc ml-16">${items.map(item => `<li>${item}</li>`).join('')}</ul>`;
    });

    // Handle numbered lists
    newText = newText.replace(/((^\s*\d+\.\s+.*$\n?)+)/gm, (match) => {
        const items = match.trim().split('\n').map(item => item.trim().replace(/^\d+\.\s+/, '')).filter(item => item);
        return `<ul class="list-disc ml-16">${items.map(item => `<li>${item}</li>`).join('')}</ul>`;
    });

    // Line breaks for <br />
    newText = newText.replace(/\n/g, '<br/>');

    return newText;
};

export default formatWhatsAppText;

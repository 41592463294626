import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const RetryButton = ({ onClick }) => (
    <Tooltip title="Reintentar envío">
        <IconButton onClick={onClick} color="error">
            <ErrorIcon />
        </IconButton>
    </Tooltip>
);

export default RetryButton;

import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";

const StyledTab = styled(Tab)({
    minHeight: 30,
    fontSize: 11,
    padding: '5px 2px 5px 2px',
});

const ItemTabChannel = ({ channelName, value, ...other }) => {
	return (
		<StyledTab
			label={channelName}
			value={value}
			{...other}
		/>
	);
};

export default ItemTabChannel;

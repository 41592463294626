import { lazy } from 'react';

const ResourcesDashboardApp = lazy(() => import('./ResourcesDashboardApp'));

const ConfigurationsDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/resources',
      element: <ResourcesDashboardApp />,
    },
  ],
};

export default ConfigurationsDashboardAppConfig;

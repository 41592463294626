import React, { useState, useEffect } from 'react';

const MessageTypeAudio = (props) => {
    const { item } = props;
    const [audioFile, setAudioFile] = useState(null);
    const mimeContentType = item?.MimeContentType;

    useEffect(() => {
        const preloadAudio = () => {
            const audioUrl = item?.content?.audio?.url;
            if (audioUrl) {
                const audio = new Audio(audioUrl);
                audio.preload = 'auto';
                setAudioFile(audio);
            }
        };

        preloadAudio();
        
        return () => {
            if (audioFile) {
                audioFile.pause();
                audioFile.src = '';
            }
        };
    }, [item]);

    return (
        <div>
            {mimeContentType && audioFile && <audio controls src={audioFile.src} />}
            {item?.content?.audio?.transcripcion && (
                <div style={{ marginTop: '10px' }}>
                    <span style={{ color: 'grey', fontStyle: 'italic', marginTop: '10px' }}>Transcripción:</span>
                    <div style={{ backgroundColor: '#cddde4', padding: '10px', borderRadius: '5px'}}>
                        <p style={{ color: 'grey', margin: 0 }}>{item.content.audio.transcripcion}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MessageTypeAudio;

import { createSlice } from '@reduxjs/toolkit';

const TAB_INITIAL_VALUE = "0";

const tabChannelSlice = createSlice({
    name: 'socialMediaPanel/tabChannel',
    initialState: {
        selectedChannelId: TAB_INITIAL_VALUE,
    },
    reducers: {
        clearSelectedChannelId(state) {
            state.selectedChannelId = TAB_INITIAL_VALUE;
        },
        setSelectedChannelId(state, action) {
            state.selectedChannelId = action.payload;
        },
    }
});

export const selectSelectedChannelId = ({ socialMediaPanel }) => socialMediaPanel.tabChannel.selectedChannelId;
export const initialSelectedChannelId = () => TAB_INITIAL_VALUE

export const { clearSelectedChannelId, setSelectedChannelId } = tabChannelSlice.actions;
export default tabChannelSlice.reducer;

import { calculateTimeLeft } from 'app/utils/dateUtils';
import { Typography } from '@mui/material';

const LastMessageTimeToResponse = ({ lastMessageTime }) => {
    const { hours, minutes } = lastMessageTime ? calculateTimeLeft(lastMessageTime) : { hours: 0, minutes: 0 };
    const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    let colorClass = 'text-green-500';
    if (hours < 2) {
        colorClass = 'text-red-500';
    } else if (hours <= 6) {
        colorClass = 'text-orange-500';
    }

    return (
        <Typography className={`whitespace-nowrap font-medium text-10 ${colorClass}`} color="textSecondary">
            {timeString}
        </Typography>
    );
};

export default LastMessageTimeToResponse;
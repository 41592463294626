import { createSlice } from '@reduxjs/toolkit';

const stateSlice = createSlice({
  name: 'socialMediaPanel/state',
  initialState: true,
  reducers: {
    toggleSocialMediaPanel: (state, action) => !state,
    openSocialMediaPanel: (state, action) => true,
    closeSocialMediaPanel: (state, action) => false,
  },
  extraReducers: {},
});

export const { toggleSocialMediaPanel, openSocialMediaPanel, closeSocialMediaPanel } = stateSlice.actions;

export const selectSocialMediaPanelState = ({ socialMediaPanel }) => socialMediaPanel.state;

export default stateSlice.reducer;

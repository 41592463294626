import { http } from "./services";

export const listarRegistros = async (params) => {
    const response = await http.post("listas", params);
    const data = await response.data;
    return data;
}

export const getCityList = async (params) => {
    const response = await listarRegistros({
        operacion: "ver_localidades",
        ...params
    });
    const data = await response;
    return data;
}

export const getDepartmentList = async (params) => {
    const response = await listarRegistros({
        operacion: "ver_departamentos",
        ...params
    });
    const data = await response;
    return data;
}

export const getNeighborhoodList = async (params) => {
    const response = await listarRegistros({
        operacion: "ver_barrios",
        ...params
    });
    const data = await response;
    return data;
}

export const getAllSocialMedia = async (params) => {
    const response = await listarRegistros({
        operacion: "ver_redes_sociales",
        ...params
    });
    const data = await response;
    return data;
}
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { lazy } from "react";
import { Navigate } from "react-router-dom";

const UsersApp = lazy(() => import("./UsersApp"));

const queryClient = new QueryClient();
const UsuariosAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/apps/usuarios`,
      element: (
        <QueryClientProvider client={queryClient}>
          <UsersApp />
        </QueryClientProvider>
      ),
    },
    {
      path: `${process.env.PUBLIC_URL}/apps/usuarios`,
      element: <Navigate to={`${process.env.PUBLIC_URL}/apps/usuarios`} />,
    },
  ],
};

export default UsuariosAppConfig;

import { lazy } from 'react';

const ConfigurationsDashboardApp = lazy(() => import('./ConfigurationsDashboardApp'));

const ConfigurationsDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/configuraciones',
      element: <ConfigurationsDashboardApp />,
    },
  ],
};

export default ConfigurationsDashboardAppConfig;

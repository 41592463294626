import { Button } from "@mui/base";
import { calculateTimeLeft } from "app/utils/dateUtils";
import { Picker } from "emoji-mart";
import { previewSendedMessage, sendMessage } from "../../store/chatSlice";
import { selectConnectionStatus } from "app/store/connectionSlice";
import { selectSelectedChannelId } from 'app/theme-layouts/shared-components/whatsappPanel/store/tabChannelSlice';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import AudioRecorder from "../AudioRecorder";
import data from "@emoji-mart/data";
import DivEmojiPicker from "app/theme-layouts/shared-components/whatsappPanel/Utils/components/DivEmojiPicker";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";

const FormChatWsp = ({ conversation }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isConnected = useSelector(selectConnectionStatus);

    const [chatIsBlocked, setChatIsblocked] = useState(false);
    const [menuPresetChat, setMenuPresetChat] = useState(null);
    const [message, setMessage] = useState("");
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const selectedChannelId = useSelector(selectSelectedChannelId);
    const selectedConversationNumber = conversation?.msisdn ?? null;

    const handleShowEmojiPicker = () => {
        setShowEmojiPicker(!showEmojiPicker);
    };

    const handleEmojiSelected = ({ native }) => {
        setMessage((prev) => prev + native);
    };

    const handleMenuPresetChatClick = (e) => setMenuPresetChat(e.currentTarget);

    const handleMenuPresetChatClose = () => {
        setMenuPresetChat(null);
    };

    const onInputChange = ({ target: { value } }) => {
        setMessage(value);
    };

    const onMessageSubmit = (ev) => {
        ev.preventDefault();

        if (!message) {
            return;
        }

        const msgObj = {
            message,
            conversationNumber: selectedConversationNumber,
            conversationId: conversation.id,
            channelId: selectedChannelId,
            type: "hsm",
        };

        const tempId = dispatch(previewSendedMessage(msgObj));
        msgObj.tempId = tempId;

        setMessage("");
        dispatch(sendMessage(msgObj));
    };

    const EmojiPickerComponent = (props) => {
        const ref = useRef();

        useEffect(() => {
            new Picker({ ...props, data, ref });
        }, []);

        return <div ref={ref} />;
    };

    // Handle sending audio from AudioRecorder component
    const handleSendAudio = (audioBlob) => {
        const reader = new FileReader();
        reader.readAsDataURL(audioBlob);
        reader.onloadend = () => {
            const base64Audio = reader.result.split(',')[1];

            /**
            const msgObj = {
                message: '',
                conversationNumber: selectedConversationNumber,
                conversationId: conversation.id,
                channelId: selectedChannelId,
                type: "audio",
                audio: base64Audio,
                filename: `audio_${Date.now()}.webm`,
            };

            const tempId = dispatch(previewSendedMessage(msgObj));
            msgObj.tempId = tempId;

            dispatch(sendMessage(msgObj));
            */
        };
    };

    useEffect(() => {
        const lastMessageTime = conversation?.lastMessage?.createdDatetime ?? null;
        const { hours } = calculateTimeLeft(lastMessageTime);

        setChatIsblocked(hours <= 0);
    }, [conversation]);

    useEffect(() => {
        setChatIsblocked(!isConnected);
    }, [isConnected]);

    return (
        <form
            onSubmit={onMessageSubmit}
            className="relative left-0 right-0"
        >
            <Paper className="rounded-none flex items-center relative shadow-none">
                <div className="flex relative">
                    {showEmojiPicker && (
                        <>
                            <DivEmojiPicker id="divEmojiPicker">
                                <EmojiPickerComponent
                                    onEmojiSelect={handleEmojiSelected}
                                />
                            </DivEmojiPicker>
                        </>
                    )}
                    <IconButton
                        onClick={handleShowEmojiPicker}
                        size="small"
                        className="ml-[15px]"
                        disabled={chatIsBlocked}>
                        <Icon>insert_emoticon</Icon>
                    </IconButton>
                </div>
                <InputBase
                    autoFocus={false}
                    id="message-input"
                    className="flex flex-1 grow shrink-0 mx-16 ltr:mr-48 rtl:ml-48 my-8"
                    placeholder={
                        !isConnected
                            ? t('wsPanel.formChat.isNotConnected')
                            : chatIsBlocked
                            ? t('wsPanel.formChat.selectPresetTemplate')
                            : t('wsPanel.formChat.writeMessage')
                    }
                    onChange={onInputChange}
                    value={message}
                    disabled={chatIsBlocked}
                    autoComplete="off"
                />
                <Button
                    variant="contained"
                    color="secondary"
                    className={`text-white font-medium rounded-lg text-sm px-10 py-5 me-2 ${
                        chatIsBlocked
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-green-700 hover:bg-green-800'
                    }`}
                    onClick={onMessageSubmit}
                    disabled={chatIsBlocked}>
                    {t('wsPanel.formChat.presetTemplates')}
                </Button>
                <IconButton
                    aria-owns={menuPresetChat ? 'chats-more-menu' : null}
                    aria-haspopup="true"
                    onClick={handleMenuPresetChatClick}
                    size="small"
                    disabled={chatIsBlocked}>
                    <Icon>more_vert</Icon>
                </IconButton>
                <Menu
                    id="chats-more-menu"
                    anchorEl={menuPresetChat}
                    open={Boolean(menuPresetChat)}
                    onClose={handleMenuPresetChatClose}>
                    <MenuItem onClick={handleMenuPresetChatClose}>
                        {t('wsPanel.formChat.presetTemplates')}
                    </MenuItem>
                </Menu>
                <AudioRecorder
                    onSendAudio={handleSendAudio}
                    chatIsBlocked={chatIsBlocked}
                    t={t}
                />
            </Paper>
        </form>
    );
};

export default FormChatWsp;

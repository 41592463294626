import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import iconMarker from 'leaflet/dist/images/marker-icon.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';

import { styled } from '@mui/material/styles';

const MapContainerStyled = styled(MapContainer)(({ theme }) => ({
    width: 300,
    height: 300
}));

const icon = L.icon({
    iconRetinaUrl: iconRetina,
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
});

function MessageTypeLocation({ content }) {
    const { latitude, longitude } = content.location;
    const position = [latitude, longitude];

    return (
        <MapContainerStyled center={position} zoom={20} scrollWheelZoom={false} >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position} icon={icon} />
        </MapContainerStyled>
    );
}

export default MessageTypeLocation;

import { lazy } from 'react';
const CrmApp = lazy(() => import('./CrmApp'));

const CrmAppConfig = {
  settings: {
    layout: {},
    param: 'crm'
  },
  routes: [
    {
      path: 'apps/crm',
      element: <CrmApp />,
    }
  ],
};

export default CrmAppConfig;

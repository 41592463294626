import { useState, useEffect, useRef } from "react";
import axios from "axios";

// const useAxiosPost = (url, payload, timeout = 0) => {
//   const [data, setData] = useState(null);
//   const [error, setError] = useState(false);
//   const [errorMessage, setErrorMessage] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [doFetch, setDoFetch] = useState(false);

//   const doFetchHandler = () => {
//     setDoFetch(true);
//   };

//   useEffect(() => {
//     if (!doFetch) return;

//     let unmounted = false;
//     let source = axios.CancelToken.source();
//     axios
//       .post(url, {
//         cancelToken: source.token,
//         timeout: timeout,
//         data: payload,
//       })
//       .then((resp) => {
//         if (!unmounted) {
//           setData(resp.data);
//         }
//       })
//       .catch(function (e) {
//         if (!unmounted) {
//           setError(true);
//           setErrorMessage(e.message);
//           if (axios.isCancel(e)) {
//             console.log(`request cancelled:${e.message}`);
//           } else {
//             console.log("another error happened:" + e.message);
//           }
//         }
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//     return function () {
//       unmounted = true;
//       source.cancel("Cancelacion en limpieza de componente");
//     };
//   }, [doFetch]);

//   return [{ data, loading, error, errorMessage }, doFetchHandler];
// };

// export default useAxiosPost;

const useAxios = (url, method, payload) => {
  // const [newPayload, setNewPayload] = useState(null);
  const [doFetch, setDoFetch] = useState({ run: false, payload: payload });
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const controllerRef = useRef(new AbortController());
  const cancel = () => {
    controllerRef.current.abort();
  };
  const handleDoFetch = (newPayload = null) => {
    setDoFetch(({ payload }) => ({
      run: true,
      payload: newPayload ?? payload,
    }));
  };
  useEffect(() => {
    let isMounted = true;
    const { run, payload } = doFetch;
    console.log(doFetch);
    if (!run) return;
    (async () => {
      try {
        const response = await axios.request({
          data: payload,
          signal: controllerRef.current.signal,
          method,
          url,
        });

        if (!isMounted) return;
        setData(response.data);
      } catch (error) {
        if (!isMounted) return;
        setError(error.message);
      } finally {
        if (!isMounted) return;
        setLoaded(true);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [doFetch]);

  return { cancel, data, error, loaded, fetch: handleDoFetch };
};

export default useAxios;

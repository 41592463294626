import {  ListItemButton, styled } from '@mui/material';
import { memo } from 'react';
import { selectSocialMediaPanelState } from '../../store/stateSlice';
import { useSelector } from 'react-redux';
import AvatarFb from './AvatarFb';
import LastMessage from './LastMessage';

const StyledListItem = styled(({ active, ...otherProps }) => (
    <ListItemButton {...otherProps} />
))(({ theme, active }) => ({
    backgroundColor: active
        ? theme.palette.whatsapp.background.paper
        : 'inherit',
    minHeight: '60px',
}));

const ConversationListItem = ({
    conversation: {
        fb_user = '',
        displayName = '',
        lastMessage = null,
        id_fan_page = '',
        updatedDatetime = '',
        user_pic = '',
        page_pic = '',
        encargado = null,
        channels,
    } = {},
    active,
    ...others
}) => {
    return (
        <StyledListItem active={active} {...others}>
            <AvatarFb user_pic={user_pic} page_pic={page_pic} />
            <LastMessage
                name={fb_user}
                displayName={displayName}
                lastMessage={lastMessage}
                encargados={[encargado]}
            />
        </StyledListItem>
    );
};

export default memo(ConversationListItem);

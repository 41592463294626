// Importa las dependencias necesarias para tu aplicación
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import store from './app/theme-layouts/shared-components/whatsappPanel/store/store';

// Otras importaciones necesarias para tu aplicación
import './i18n';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import App from './app/App'; 
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// Configuración de Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,          //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1,  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Configuración de la raíz de renderizado con createRoot
const container = document.getElementById('root');
const root = createRoot(container);

// Renderiza tu aplicación con el Provider de Redux
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// Configuración de service worker y reporte de métricas web vitals
reportWebVitals();
serviceWorker.unregister();  // Opcionalmente, puedes cambiar a serviceWorker.register() para habilitar el service worker

import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import { changeFacebookConversationState, changeInstagramConversationState, changeWhatsappConversationState, fetchConversationStates } from "app/services/conversationActionsServices/changeStateServices";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";

const ChangeState = ({ t, setSubMenu, selectedConversation, selectedChannelId, handleMenuHeaderChatClose }) => {
    const dispatch = useDispatch();
    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(false);


    const createChangeStateParams = (selectedConversation, selectedChannelId, state) => {

        const params = { idEstado: state.id, idConversacion: selectedConversation.id };

        if (selectedConversation.tipo === 1) {
            params.idCanal = selectedChannelId;
        }

        return params;
    };

    const fetchStates = async (params) => {
        try {
            const result = await consultBeforeTransferringState(params);
            return result;
        } catch (error) {
            console.error('Error fetching states:', error);
            return [];
        }
    };

    const fetchTranferConversationToState = async (params) => {
        try {
            const result = await transferStateConversation(params);
            return result;
        } catch (error) {
            console.error('Error fetching states:', error);
            return [];
        }
    };

    const createStateMenuItems = (states, handleStateClick) => {
        return states.map(state => (
            <MenuItem
                key={state.id}
                onClick={() => handleStateClick(state)}
                style={{ color: state.color_text }}
            >
                {state.descripcion}
            </MenuItem>
        ));
    };

    const handleChangeState = async () => {
        setLoading(true);
        try {
            const states = await fetchConversationStates();

            if (states.length) {
                const stateMenuItems = createStateMenuItems(states ? states : [], handleStateClick);

                setSubMenu(stateMenuItems);
                setLoading(false);
            } else {
                setLoading(false);
                handleMenuHeaderChatClose();
                dispatch(showMessage({
                    message: 'No se encontraron estados para cambiar.',
                    variant: 'error',
                }));
            }
        } catch (error) {
            console.error('Error fetching states:', error);
            setLoading(false);
            return [];
        }

    };

    const handleStateClick = async (state) => {

        try {
            const params = createChangeStateParams(selectedConversation, selectedChannelId, state);
            
            let result = false
            
            switch (selectedConversation.tipo) {
                case 1:
                    result = await changeWhatsappConversationState(params);
                    break;
                case 3:
                    result = await changeFacebookConversationState(params);
                    break;
                case 5:
                    result = await changeInstagramConversationState(params);
                    break;
            }

            handleMenuHeaderChatClose();

            if (result) {
                dispatch(showMessage({
                    message: 'Estado de conversación cambiado',
                    variant: 'success',
                }));
            } else {
                dispatch(showMessage({
                    message: 'No se pudo modificar el Estado.',
                    variant: 'error',
                }));
            }
        } catch (error) {
            console.error('Error transferring to state:', error);
            return [];
        }

    };

    return (
        <MenuItem onClick={handleChangeState}>
            {loading ? <> {t('wsPanel.menuConversation.changeStatus')} <CircularProgress size={24} /></> : t('wsPanel.menuConversation.changeStatus')}
        </MenuItem>
    )
}

export default ChangeState
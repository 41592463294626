import { styled } from '@mui/material/styles';

const VideoStyled = styled('video')(({ theme }) => ({
    width: 250,
}));

const MessageTypeVideo = ({ content }) => {
    const videoUrl = content.video.url;

    return (
        <div className="flex">
            <VideoStyled className="block" src={videoUrl} controls>
                Your browser does not support the <code>video</code> element.
            </VideoStyled>
        </div>
    );
}

export default MessageTypeVideo;

import MessageTypeAudio from './TypeAudio'
import MessageTypeDeleted from './TypeDeleted'
import MessageTypeDocument from './TypeDocument'
import MessageTypeFallback from './TypeFallback'
import MessageTypeImage from './TypeImage'
import MessageTypeLocation from './TypeLocation'
import MessageTypeReaction from './TypeReaction'
import MessageTypeSticker from './TypeSticker'
import MessageTypeTemplate from './TypeTemplate'
import MessageTypeText from './TypeText'
import MessageTypeVideo from './TypeVideo'

function MessageType(props) {
    const item = props.item;
    let type = item && item.text ? "text" : "unsupported";
    if (item && item.attachments) {
        item.attachments.forEach((attachments, index) => {
            type = attachments.type;
        })
    }

    const content = (item) ? item.text : props.content;
    const context = (item && item.context) ? item.context : null;

    if (type == "template") {
        type = "unsupported";
    }

    if (props.type == 'reaction') {
        return (
            <MessageTypeReaction content={content} />
        )
    }

    if (item?.text || type === "unsupported" || type === "text") {
        return (
            <>
                <MessageTypeText text={content} type={type} context={context} />
            </>
        )
    }

    return (
        <>
            {item.attachments.map((attachment, index) => {
                return (
                    <>
                        {(attachment.type === "audio" || attachment.file_url) && (
                            <MessageTypeAudio item={item} />
                        )}
                        {/* {attachment.type === "deleted" && (
                    <MessageTypeDeleted />
                )} */}
                        {/* {attachment.type === "document" && (
                    <MessageTypeDocument item={item} />
                )} */}
                        {attachment.type === "fallback" && (
                            <MessageTypeFallback content={attachment} />
                        )}
                        {(attachment.type === "image" || attachment.image_data) && (
                            <MessageTypeImage content={attachment} />
                        )}
                        {attachment.type === "location" && (
                            <MessageTypeLocation content={content} />
                        )}
                        {/* {type === "reaction" && (
                    <MessageTypeReaction content={content} />
                )} */}
                        {/* {(attachment.type === "sticker" || type === "whatsappSticker") && (
                    <MessageTypeSticker content={content} type={type} />
                )} */}
                        {attachment.type === "template" && (
                            <MessageTypeTemplate item={props.item} />
                        )}
                        {(attachment.type === "video" || attachment.video_data) && (
                            <MessageTypeVideo content={content} />
                        )}
                    </>
                )
            })}
        </>
    )

}

export default MessageType 
import { isActionAllowed, isPreferredAction } from './Utils/shared/utils';
import { obtenerEstadosConversacionResumen } from 'app/services/socialMediaService';
import { selectEstadosConversacion, setSelectEstadosConversacion } from './store/conversationsSlice';
import { socketStatus } from 'src/app/auth/services/socket/Store/socketSlice';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ConversationStates from './ConversationStates';
import { selectUserPreferences, selectUser } from 'app/store/userSlice';

const FilterByStatusBar = () => {
    const dispatch = useDispatch();
    const isSocketConnected = useSelector(socketStatus);
    const estadosConversacion = useSelector(selectEstadosConversacion);
    const preferences = useSelector(selectUserPreferences);
	const user = useSelector(selectUser);

    const [count, setCount] = useState(0);

    useEffect(() => {
        getConversationStatus();
    }, []);

    useEffect(() => {
        const interval = setInterval(getConversationStatus, 60000);

        return () => clearInterval(interval);
    }, [count]);

    const consolidarEstados = (data) => {
        const resultado = {};

        data.forEach((socialData) => {
            const { nombreRedSocial, estados } = socialData;
            estados.forEach((estado) => {
                const { descripcion, conteo, color_text, id } = estado;

                if (!resultado[descripcion]) {
                    resultado[descripcion] = {
                        total: 0,
                        detalles: {},
                        color: color_text,
                        id,
                    };
                }

                resultado[descripcion].total += conteo;
                resultado[descripcion].detalles[nombreRedSocial] = conteo;
            });
        });
        return resultado;
    };

    const getConversationStatus = async () => {
        try {
            const datos = {
                _base: 'bck',
            };

            if (isSocketConnected) {
                const eventos = [
                    'wsConsultarEstadosConversacionesResumen',
                    'fbConsultarEstadosConversacionesResumen',
                    'igConsultarEstadosConversacionesResumen',
                ];

                const redesSocialesByEvento = {
                    wsConsultarEstadosConversacionesResumen: 'whatsapp',
                    fbConsultarEstadosConversacionesResumen: 'facebook',
                    igConsultarEstadosConversacionesResumen: 'instagram',

                };
                eventos.map((evento) => {
                    if (
                        isActionAllowed(user.permissions, redesSocialesByEvento[evento]) &&
                        isPreferredAction(preferences, redesSocialesByEvento[evento]))
                    {
                        datos.operacion = evento;
                        dispatch({ type: 'socket/send', payload: datos });
                    }
                });
            } else {
                let estadosConversacionResumen = [];

                const uris = {
                    1: '/ws/consultar_estados_conversaciones_resumen',
                    3: '/fb/consultar_estados_conversaciones_resumen',
                    5: '/ig/consultar_estados_conversaciones_resumen',
                };

                const redesSocialesById = {
                    1: 'whatsapp',
                    3: 'facebook',
                    5: 'instagram',
                };

                for (const key in uris) {
                    if (
                        isActionAllowed(user.permissions, redesSocialesById[key]) &&
                        isPreferredAction(preferences, redesSocialesById[key]))
                    {
                        estadosConversacionResumen.push({
                            nombreRedSocial: redesSocialesById[key],
                            estados: await obtenerEstadosConversacionResumen(
                                uris[key]
                            ),
                        });
                    }
                }

                estadosConversacionResumen = consolidarEstados(estadosConversacionResumen);

                dispatch(setSelectEstadosConversacion(estadosConversacionResumen));
            }
        } catch (error) {
            console.log('error to get conversation:', error);
        }
    };

    return (
        <div className="px-7 py-14">
            <Typography>
                Estado de conversaciones de los ultimos 30 días
            </Typography>
            <ConversationStates estadosConversacion={estadosConversacion} />
        </div>
    );
};

export default FilterByStatusBar;

import React from 'react'
import {
    Card,
    CardContent,
    Typography,
} from "@mui/material";
import { subtractTime } from 'app/utils/dateUtils';

const MessageTypeResolution = ({ resolution, index }) => {
  return (
      <Card key={index} variant="outlined" style={{ marginBottom: "16px", backgroundColor: "#f5a34e5c" }}>
          <CardContent>
              <div style={{ display: "flex", marginBottom: "8px" }}>
                  <Typography variant="subtitle2" style={{ width: "100px", backgroundColor: "#f5a34e", color: "#666", padding: "2px" }}>
                      Resolución:
                  </Typography>
                  <Typography variant="body2" style={{ color: "#d66719", fontWeight: "bold", marginLeft: "5px" }}>
                      {resolution.resolucion}
                  </Typography>
                  <Typography variant="subtitle2" style={{ width: "70px", backgroundColor: "#f5a34e", color: "#666", padding: "2px", marginLeft: "15px" }}>
                      Detalle:
                  </Typography>
                  <Typography variant="body2" style={{ color: "#d66719", fontWeight: "bold" }}>
                      {resolution.detalle || ""}
                  </Typography>
              </div>

              {resolution.sub_detalle && (
                  <div style={{ display: "flex", marginBottom: "8px" }}>
                      <Typography variant="subtitle2" style={{ width: "75px", backgroundColor: "#f5a34e", color: "#666", padding: "2px" }}>
                          Sub Detalle:
                      </Typography>
                      <Typography variant="body2" style={{ color: "#d66719", fontWeight: "bold" }}>
                          {resolution.sub_detalle}
                      </Typography>
                  </div>
              )}

              {resolution.medio && (
                  <div style={{ display: "flex", marginBottom: "8px" }}>
                      <Typography variant="subtitle2" style={{ width: "47px", backgroundColor: "#f5a34e", color: "#666", padding: "2px" }}>
                          Medio:
                      </Typography>
                      <Typography variant="body2" style={{ color: "#d66719", fontWeight: "bold" }}>
                          {resolution.medio}
                      </Typography>
                  </div>
              )}

              {resolution.producto_servicio && resolution.producto_servicio.length > 0 && (
                  <div style={{ backgroundColor: "#f5a34e5c", padding: "8px" }}>
                      {resolution.producto_servicio.map((product, idx) => (
                          <Typography key={idx} variant="body2" style={{ fontStyle: "italic", marginLeft: "10px" }}>
                              {product.nombre}
                          </Typography>
                      ))}
                  </div>
              )}

              {resolution.Comentario && (
                  <div style={{ backgroundColor: "#f5a34e5c", padding: "8px", borderTop: "1px solid #f5a34e", marginTop: "8px" }}>
                      <Typography variant="body2" style={{ fontStyle: "italic", marginLeft: "10px" }}>
                          {resolution.Comentario}
                      </Typography>
                  </div>
              )}

              <Typography variant="caption" style={{ color: "#a8aab1", textAlign: "right", display: "block", marginTop: "8px" }}>
                  {resolution.usuario} - {subtractTime(resolution.creacion)}
              </Typography>
          </CardContent>
      </Card>
  )
}

export default MessageTypeResolution
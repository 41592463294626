import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import FuseNavItem from '../FuseNavItem';
import List from '@mui/material/List';

const StyledList = styled(List)(({ theme }) => ({
    '& .fuse-list-item': {
        '&:hover': {
            backgroundColor:
                theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.05)'
                    : 'rgba(0,0,0,.04)',
        },
        '&:focus:not(.active)': {
            backgroundColor:
                theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.06)'
                    : 'rgba(0,0,0,.05)',
        },
    },
    '& .fuse-list-item-text': {
        margin: 0,
    },
    '& .fuse-list-item-text-primary': {
        lineHeight: '20px',
    },
    '&.active-square-list': {
        '& .fuse-list-item, & .active.fuse-list-item': {
            width: '100%',
            borderRadius: '0',
        },
    },
    '&.dense': {
        '& .fuse-list-item': {
            paddingTop: 0,
            paddingBottom: 0,
            height: 32,
        },
    },
}));

const excludedItems = ['facebook', 'instagram', 'whatsapp', 'webchat', 'facebook_desarrollo'];

function FuseNavVerticalLayout1(props) {
    const { navigation, layout, active, dense, className, onItemClick } = props;

    const filterNavigation = (items) => {
        return items.map((item) => {
            if (item.children) {
                return {
                    ...item,
                    children: item.children.filter(
                        (child) =>
                            !excludedItems.includes(child.param.toLowerCase())
                    ),
                };
            }
            return item;
        });
    };

    const filteredNavigation = filterNavigation(navigation);

    return (
        <StyledList
            className={clsx(
                'navigation whitespace-nowrap px-12 py-0',
                `active-${active}-list`,
                dense && 'dense',
                className
            )}>
            {filteredNavigation.map((item) => (
                <FuseNavItem
                    key={item.id}
                    type={`vertical-${item.type}`}
                    item={item}
                    nestedLevel={0}
                    onItemClick={onItemClick}
                />
            ))}
        </StyledList>
    );
}

export default FuseNavVerticalLayout1;

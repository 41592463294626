import { getMessages, selectChatByConversationId } from '../../store/chatSlice';
import { selectSelectedConversation } from '../../store/conversationsSlice';
import { TabContext, TabPanel } from '@mui/lab';
import { updateOffsetConversation } from '../../Utils/facebookUtil';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import Conversation from '../../Conversation';

const backgroundConversation = {
    backgroundImage: `url('assets/images/backgrounds/bg_chat.png')`,
    backgroundColor: `rgb(249 207 142 / 26%)`,
};

export const ConversationContainerFb = ({ conversation }) => {
    const dispatch = useDispatch();
    const chat = useSelector(selectChatByConversationId);
    const selectedConversation = useSelector(selectSelectedConversation);

    const offset = useRef(0);
    const chatTabScroll = useRef({});
    const canLoadMoreFromAPI = useRef(true);
    const [isLoading, setIsLoading] = useState(false);
    const [lastMessageTime, setLastMessageTime] = useState(null);
    const { id: selectedConversationId, lastMessage } = conversation;

    const fetchMessages = async (
        chat,
        dispatch,
        lastMessage,
        selectedConversationId,
        setLastMessageTime,
    ) => {
        if (!canLoadMoreFromAPI.current) {
            setIsLoading(false);
            return;
        }

        const lastMessageTime = lastMessage ? lastMessage.updatedDatetime : null;
        const offsetQuery = offset.current || 0;
        const limit = 15;
        const params = {
            tipo: 3,
            idConversacion: selectedConversationId,
            idCanal: null,
            limit,
            offset: offsetQuery,
            IdMensaje: '',
            reverse: false,
        };

        const data = {
            params,
            lastMessage: false,
            canLoadMoreFromAPI: canLoadMoreFromAPI.current,
        };

        setLastMessageTime(lastMessageTime);

        await dispatch(getMessages({ data })).then((data) => {
            if (
                data.payload?.length > 0 &&
                data.payload?.length !== chat.length
            ) {
                const conversationSelectedMessages = data.payload.filter((item) => {
                        return item.conversationId === selectedConversationId;
                });

                if (offset.current + 15 > conversationSelectedMessages.length) {
                    canLoadMoreFromAPI.current = false;
                }

                offset.current = conversationSelectedMessages.length;

                const updateData = {
                    conversationId: selectedConversationId,
                    newOffset: conversationSelectedMessages.length,
                };
                
                dispatch(updateOffsetConversation(updateData));
            }
        });

        setIsLoading(false);
        scrollToBottom();
    };

    const loadConversations = () => {
        offset.current = 0;
        canLoadMoreFromAPI.current = true;

        fetchMessages(chat, dispatch, lastMessage, selectedConversationId, setLastMessageTime);
    };

    const handleScroll = (e) => {
        if (isLoading) return;

        if (e.currentTarget.scrollTop === 0) {
            setIsLoading(true);
            fetchMessages(chat, dispatch, lastMessage, selectedConversationId, setLastMessageTime);
        }
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            if (selectedConversationId) {
                const element = chatTabScroll.current[conversation.id];
                if (element) {
                    element.scrollTop = element.scrollHeight;
                }
            }
        }, 250);
    };

    useEffect(() => {
        if (selectedConversation) {
            if (selectedConversation.messages.length < 15) {
                loadConversations();
            }
        }

        scrollToBottom();
    }, []);

    return (
        <TabContext value={conversation.id}>
            <TabPanel
                className="overflow-y-auto overscroll-contain h-screen p-10"
                key={selectedConversationId + conversation.id}
                offset={conversation.id === selectedConversationId ? offset.current : 0}
                onScroll={handleScroll}
                ref={(ref) => (chatTabScroll.current[conversation.id] = ref)}
                style={backgroundConversation}
                value={conversation.id}>
                <Conversation
                    selectedConversation={conversation}
                    chat={chat}
                    isLoading={isLoading}
                />
            </TabPanel>
        </TabContext>
    );
};
import React from 'react'

const MessageTypeFallback = (props) => {
    if (props.payload && props.payload.url) {
        return (
            <div className="content-text">
                <div className="text">
                    <span>
                        {props.payload.title}
                    </span>
                    <br />
                    <span>
                        <a href={props.payload.url} title={props.payload.url} target="_blank" rel="noopener noreferrer">
                            {props.payload.url}
                        </a>
                    </span>
                </div>
            </div>
        );
    }
    return null;
}

export default MessageTypeFallback
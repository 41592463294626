import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";


export const NoConversationSelected = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col flex-1 items-center justify-center p-24">
            <Icon className="text-128" color="disabled">
                chat
            </Icon>
            <Typography
                className="px-16 pb-24 mt-24 text-center"
                color="textSecondary">
                {t('wsPanel.noConversation.startConversation')}
            </Typography>
        </div>
    );
};

import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import { useTranslation } from 'react-i18next';

const NotificationContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    backgroundColor: '#FFB900',
    color: theme.palette.warning.contrastText,
    borderRadius: theme.shape.borderRadius,
    marginBottom: '16px',
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const OfflineNotification = () => {
    const { t } = useTranslation();

    return (
        <NotificationContainer>
            <StyledIcon>signal_wifi_off</StyledIcon>
            <Typography >
                {t('global.messageOffline')}
            </Typography>
        </NotificationContainer>
    );
};

export default OfflineNotification;

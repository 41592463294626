/**
 * The ConversationWsp component filters and displays messages from a conversation, including reaction
 * emojis.
 * @returns The `ConversationWsp` component is being returned. It receives props `conversation` and
 * `others`, extracts `channelId` from `others`, filters messages based on `channelId`, creates a map
 * of emojis by reference_id from reaction messages, sorts the filtered messages by createdDatetime,
 * and then maps over the filtered messages to render `MessageRow` components with message, type, and
 * emoji props
 */
import MessageRow from '../MessageRow';
import FuseLoading from '@fuse/core/FuseLoading';

const ConversationWsp = ({ conversation, isLoading, ...others }) => {
    const { channelId } = others;

    let filteredChat = [];
    let reactionMessages = [];

    conversation.channels.forEach((channel) => {
        if (channelId === 0 || channel.id === channelId) {
            channel.messages.forEach((message) => {
                if (message.type === 'reaction') {
                    reactionMessages.push(message);
                } else {
                    filteredChat.push(message);
                }
            });
        }
    });

    // Crear un mapa de emojis por reference_id, tomando siempre el último mensaje
    const emojiMap = reactionMessages.reduce((acc, reaction) => {
        const { reference_id, emoji } = reaction.content.reaction;
        acc[reference_id] = emoji || null;
        return acc;
    }, {});

    filteredChat.sort((a, b) => new Date(a.createdDatetime) - new Date(b.createdDatetime));

    return (
        <div className="flex flex-col pt-10 ltr:pl-10 rtl:pr-10 pb-10">
            {!isLoading ?
                filteredChat.map((message) => (
                    <MessageRow
                        key={message.id}
                        message={message}
                        type={conversation.tipo}
                        emoji={emojiMap[message.id] || null}
                    />
                )) : <FuseLoading />}
        </div>
    );
};

export default ConversationWsp;

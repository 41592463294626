import { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import { decrypt } from "app/utils/cryptoUtils";
import { isActionAllowed } from "../Utils/shared/utils";
import { selectSelectedConversation } from "../store/conversationsSlice";
import { selectSelectedChannelId } from "../store/tabChannelSlice";
import TransferDepartmentActions from "./conversationActions/TransferDepartmentActions";
import TransferAgentAction from "./conversationActions/TransferAgentAction";
import ChangeState from "./conversationActions/ChangeState";
import ShowAssignedResolutions from "./conversationActions/ShowAssignedResolutions";

const MenuConversation = () => {
    const { t } = useTranslation();
    const [menuHeaderConversation, setMenuHeaderConversation] = useState(null);
    const [subMenu, setSubMenu] = useState(null);

    const user = useSelector(selectUser);

    const permissions = decrypt(user.permissions);
    const selectedConversation = useSelector(selectSelectedConversation);
    const selectedChannelId = useSelector(selectSelectedChannelId);

    const handleMenuHeaderChatClick = (event) => {
        if (event.currentTarget) {
            setMenuHeaderConversation(event.currentTarget);
        }
    };

    const handleMenuHeaderChatClose = () => {
        setMenuHeaderConversation(null);
        setSubMenu(null);
    };

    const isOpenMenuHeader = Boolean(menuHeaderConversation);

    return (
        <div>
            <IconButton
                aria-owns={isOpenMenuHeader ? 'chats-more-menu' : undefined}
                aria-haspopup="true"
                onClick={handleMenuHeaderChatClick}
                size="large">
                <Icon>more_vert</Icon>
            </IconButton>
            <Menu
                id="chats-more-menu"
                anchorEl={menuHeaderConversation}
                open={isOpenMenuHeader}
                onClose={handleMenuHeaderChatClose}
            >
                {subMenu ? subMenu : [
                    isActionAllowed(permissions, 'ws_transferir_departamento') && (
                        <TransferDepartmentActions
                            key="transfer-department"
                            t={t}
                            setSubMenu={setSubMenu}
                            handleMenuHeaderChatClose={handleMenuHeaderChatClose}
                            selectedConversation={selectedConversation}
                            selectedChannelId={selectedChannelId}
                        />
                    ),
                    isActionAllowed(permissions, 'ws_transferir_agente') && (
                        <TransferAgentAction
                            key="transfer-agent"
                            t={t}
                            setSubMenu={setSubMenu}
                            handleMenuHeaderChatClose={handleMenuHeaderChatClose}
                            selectedConversation={selectedConversation}
                            selectedChannelId={selectedChannelId}
                        />
                    ),
                    <ChangeState
                        key="change-state"
                        t={t}
                        setSubMenu={setSubMenu}
                        handleMenuHeaderChatClose={handleMenuHeaderChatClose}
                        selectedConversation={selectedConversation}
                        selectedChannelId={selectedChannelId}
                    />,
                    <MenuItem key="saved-messages" onClick={handleMenuHeaderChatClose}>
                        {t('wsPanel.menuConversation.savedMessages')}
                    </MenuItem>,
                    <MenuItem key="set-resolution" onClick={handleMenuHeaderChatClose}>
                        {t('wsPanel.menuConversation.setResolution')}
                    </MenuItem>,
                    <ShowAssignedResolutions
                        key="show-assigned-resolutions"
                        t={t}
                        selectedConversation={selectedConversation}
                        selectedChannelId={selectedChannelId}
                        handleMenuHeaderChatClose={handleMenuHeaderChatClose}
                    />,
                    isActionAllowed(permissions, 'ws_ver_tiempo_respuesta') && (
                        <MenuItem key="show-hide-response-time" onClick={handleMenuHeaderChatClose}>
                            {t('wsPanel.menuConversation.showHideResponseTime')}
                        </MenuItem>
                    ),
                    <MenuItem key="show-hide-customers" onClick={handleMenuHeaderChatClose}>
                        {t('wsPanel.menuConversation.showHideCustomersInMessages')}
                    </MenuItem>,
                    <MenuItem key="calendar" onClick={handleMenuHeaderChatClose}>
                        {t('wsPanel.menuConversation.calendar')}
                    </MenuItem>,
                    <MenuItem key="pay-link" onClick={handleMenuHeaderChatClose}>
                        {t('wsPanel.menuConversation.payLink')}
                    </MenuItem>,
                    isActionAllowed(permissions, 'ws_historial_asignaciones') && (
                        <MenuItem key="assigned-history" onClick={handleMenuHeaderChatClose}>
                            {t('wsPanel.menuConversation.assignedHistory')}
                        </MenuItem>
                    ),
                    <MenuItem key="scheduled-messages" onClick={handleMenuHeaderChatClose}>
                        {t('wsPanel.menuConversation.scheduledMessages')}
                    </MenuItem>,
                    <MenuItem key="tags" onClick={handleMenuHeaderChatClose}>
                        {t('wsPanel.menuConversation.tags')}
                    </MenuItem>,
                    <MenuItem key="search-previous-messages" onClick={handleMenuHeaderChatClose}>
                        {t('wsPanel.menuConversation.searchPreviousMessages')}
                    </MenuItem>
                ]}
            </Menu>
        </div>
    );
};

export default MenuConversation;

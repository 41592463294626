import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
// import GoogleMap from 'google-map-react';
import { MapContainer, TileLayer, Marker,Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import iconMarker from 'leaflet/dist/images/marker-icon.png'
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

const icon = L.icon({ 
    iconRetinaUrl:iconRetina, 
    iconUrl: iconMarker, 
    shadowUrl: iconShadow 
});


function MessageTypeLocation(props){
    let lat = props.content.location.latitude;
    let long = props.content.location.longitude;
    let position = [lat,long];

    return(
        <>
        <MapContainer center={position} zoom={20} scrollWheelZoom={false} 
        style={{ width: 300, height: 300}}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <Marker position={position} icon={icon}>
    </Marker>
  </MapContainer>
        </>
    )
}

export default MessageTypeLocation
import React from 'react';
import { Box, Typography } from '@mui/material';

const TemplateTypeProduct = ({ products, conversationId }) => {
    return products.map((product, i) => {
        const src = document.querySelector(`a[href="#fb-conversacion-id-${conversationId}"] img.fan-page-profile`).getAttribute('src');
        const fanPage = document.querySelector(`a[href="#fb-conversacion-id-${conversationId}"]`).getAttribute('fan-page');

        return (
            <Box
                key={i}
                id={`product-${product.id}`}
                sx={{
                    width: 150,
                    border: '1px solid #9e9e9e',
                    borderRadius: 2,
                    overflow: 'hidden',
                    display: 'inline-block',
                }}
            >
                <Box sx={{ width: '100%', padding: 1, background: '#f1f0f0' }}>
                    <img src={src} alt={fanPage} style={{ height: 28, borderRadius: '50%', float: 'left', marginTop: 5 }} />
                    <Typography sx={{ padding: '2px 10px', float: 'left', width: 'calc(100% - 28px)' }}>
                        <b>{fanPage}</b>
                    </Typography>
                </Box>
                <img src={product.image_url} alt={product.title} style={{ width: '100%', padding: 10 }} />
                <Box sx={{ width: '100%', background: '#f1f0f0' }}>
                    <Typography sx={{ width: '100%', padding: '2px 10px' }}>
                        <b>{product.title}</b>
                    </Typography>
                    <Typography sx={{ width: '100%', padding: '2px 10px' }}>
                        {product.subtitle}
                    </Typography>
                </Box>
            </Box>
        );
    });
};

export default TemplateTypeProduct;

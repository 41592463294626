import { useDispatch, useSelector } from 'react-redux';
import { formatNumber } from '../../../Utils/shared/utils';
import { openSocialMediaCart } from '../../../store/cartSlice';

const interactiveButtonStyle = {
    background: '#dcf8c6',
    border: 'none',
    padding: '5px',
    color: '#007bff',
    borderRadius: '5px',
    float: 'left',
    margin: '0.5px'
};

const Sections = (props) => {
    return (
        <>
            {props.sections.length > 0 && (
                props.sections[0].rows.map((item, i) => {
                    return (
                        <div className="flex w-full" key={i}
                            style={{ background: 'aliceblue', borderRadius: '5px', marginBottom: '5px', padding: ' 0 5px', color: '#00bcd4' }}>
                            <div className="flex w-full justify-start">
                                <p>{item.title}</p>
                            </div>

                            <div className="flex justify-end">
                                <input type="radio" disabled />
                            </div>
                        </div>
                    )
                })
            )}
        </>
    )
}

const Buttons = (props) => {
    return (
        <>
            {props.buttons.length > 0 && (
                props.buttons.map((btn, i) => {
                    let title = (btn.title) ? btn.title : btn.reply.title;
                    return (
                        <button type="button" className="w-full" style={interactiveButtonStyle} key={i}>
                            {title}
                        </button>
                    )
                })
            )}
        </>
    )
};

const Reply = (props) => {
    let titleReply = (props.reply) ? props.reply.text : '';
    let titleButtonReply = (props.buttonReply) ? props.buttonReply.title : '';
    let titleListReply = (props.listReply) ? props.listReply.title : '';
    return (
        <>
            {props.reply && titleReply && (
                <p>{titleReply}</p>
            )}

            {props.buttonReply && titleButtonReply && (
                <p>{titleButtonReply}</p>
            )}

            {props.listReply && titleListReply && (
                <p>{titleListReply}</p>
            )}
        </>
    )
}

const Product = (props) => {
    let currency = props.product.currency;
    let price = formatNumber(props.product.precio);
    let price2 = currency + " " + price;
    let url = props.product.url;
    let description = props.product.description;

    return (
        <>
            <img src={url} width="300" />
            <p className="w-full font-bold">{description}</p>
            <p className="w-full">{price2}</p>
        </>
    )

}

const ProductList = (props) => {
    return (
        <></>
    )
}

function MessageTypeInteractive(props) {
    const dispatch = useDispatch();
    const cartPanel = useSelector(({ socialMediaPanel }) => socialMediaPanel.cart);

    let type = props.content.interactive.type;
    let header = props.content.interactive.header;
    let headerImage = (header && header.image) ? header.image : "";
    let headerTitle = (header && header.text) ? header.text : "";
    let body = props.content.interactive.body;
    let footer = props.content.interactive.footer;
    let action = props.content.interactive.action;
    let buttons = (action && action.buttons) ? action.buttons : [];
    let sections = (action && action.sections) ? action.sections : [];
    let reply = props.content.interactive.reply;
    let buttonReply = props.content.interactive.button_reply;
    let listReply = props.content.interactive.list_reply;
    let tag = (type === 'product') ? 'Ver' : 'Ver artículos';
    let headerImgUrl;

    if (action) {
        if (headerImage) {
            headerImgUrl = ((headerImage.url !== undefined) ? headerImage.url : headerImage.link);
        }
    }

    const openCartPanel = () => {
        if (cartPanel.length === 0) {
            var data = {
                type: type,
                headerTitle: headerTitle,
                sections: sections,
                action: action
            }
            dispatch(openSocialMediaCart({ data }))
        }
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center" style={{ maxWidth: '300px', wordBreak: 'break-word' }}>
                {headerImage && (
                    <div className="w-full">
                        <img src={headerImgUrl} style={{ width: '300px', height: "auto" }} />
                        {header.caption && (
                            <p className="w-full">{header.caption}</p>
                        )}
                    </div>
                )}

                {type === 'product' && (
                    <Product product={action} />
                )}

                {type === 'product_list' && (
                    <ProductList />
                )}

                {body && body.text && (
                    <p className="w-full my-8">{body.text}</p>
                )}

                {footer && footer.text && (
                    <p className="w-full my-8">{footer.text}</p>
                )}

                <Sections sections={sections} />
                <Buttons buttons={buttons} />

                {(type === 'product' || type === 'product_list') && (
                    <button type="button"
                        style={{ width: '98%', padding: '10px', color: '#007bff', border: 'none', borderTop: '1px solid #9e9e9e', background: 'transparent' }}
                        onClick={openCartPanel}>
                        {tag}
                    </button>
                )}

                {(reply || buttonReply || listReply) && (
                    <Reply reply={reply} buttonReply={buttonReply} listReply={listReply} />
                )}

            </div>
        </>
    )
}

export default MessageTypeInteractive
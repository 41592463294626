import _ from '@lodash';
import { Controller, useForm } from 'react-hook-form';
import { createRoot } from 'react-dom/client';
import { LoadingButton } from '@mui/lab';
import { motion } from 'framer-motion';
import { styled, darken } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import jwtService from '../../auth/services/jwtService';
import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Root = styled('div')(({ theme }) => ({
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
        theme.palette.primary.dark,
        0.5
    )} 100%)`,
    color: theme.palette.primary.contrastText,

    '& .Login-leftSection': {},

    '& .Login-rightSection': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
            theme.palette.primary.dark,
            0.5
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },
}));

/**
 * Form Validation Schema
 */
const genarateSchema = () => {
    const { t } = useTranslation();

    return yup.object().shape({
        email: yup.string().email(t('signIn.validation.emailInvalid')).required(t('signIn.validation.emailRequired')),
        password: yup
            .string()
            .required(t('signIn.validation.passwordRequired'))
            .min(4, t('signIn.validation.passwordTooShort')),
        puentecheckbox: yup
            .boolean()
            .oneOf([true]),
    });
}

const recaptchaRef = React.createRef();

const defaultValues = {
    email: '',
    password: '',
    puentecheckbox: true,
};

function SignInPage() {
    const { t } = useTranslation();

    const [open, setOpen] = useState(true);
    const { control, formState, handleSubmit, setError, setValue } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(genarateSchema()),
    });
    const { isValid, dirtyFields, errors } = formState;
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onSubmit = ({ email, password }) => {
        setLoading(true);
        jwtService
            .signInWithEmailAndPassword(email, password, t)
            .then((user) => {
                // No need to do anything, user data will be set at app/auth/AuthContext
            })
            .catch((_errors) => {
                _errors.forEach((error) => {
                    setError(error.type, {
                        type: 'manual',
                        message: error.message,
                    });
                });
                if (_errors.length === 1) {
                    setValue("email", "")
                    setValue("password", "")
                    setValue("puentecheckbox", false)
                    const fieldCaptcha = document.getElementById('captcha')
                    const root = createRoot(fieldCaptcha);
                    const elementCaptcha = (
                        <ReCAPTCHA
                            id='recaptcha'
                            className='g-recaptcha'
                            sitekey="6LcpsOUUAAAAAGuXwR2ZO_lmAB9QRvpJMFwbw6K5"
                            onChange={onChange}
                            ref={recaptchaRef}
                        />
                    );
                    root.render(elementCaptcha)
                }
            }).finally(() => {
                setLoading(false);
            });
    }

    const signOffMessage = sessionStorage.signOffMessage;

    if (signOffMessage) {
        setTimeout(() => {
            sessionStorage.removeItem('signOffMessage');
        }, 5000)
    }

    const onChange = () => {
        if (grecaptcha.getResponse()) {
            puentecheckbox.click();
            setValue("puentecheckbox", true)
        } else {
            puentecheckbox.click();
            setValue("puentecheckbox", false)
        }
    }

    return (
        <Root className="flex flex-col flex-auto items-center justify-center shrink-0 ">
            <motion.div
                initial={{ opacity: 0, scale: 0.6 }}
                animate={{ opacity: 1, scale: 1 }}
                className="flex w-4/12 min-w-200 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden"
            >
                <Card
                    className="flex flex-col w-full items-center shadow-0"
                    square
                >
                    <CardContent className="flex flex-col items-center w-full py-96 max-w-320">
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { delay: 0.2 } }}
                        >
                            <div className="flex items-center mb-48">
                                <img className="logo-icon w-48" src="/assets/images/logo/nas-logo.png?v=2" alt="NAS Logo" />
                                <div className="border-l-1 mr-4 w-1 h-40" />
                                <div>
                                    <Typography className="text-24 font-semibold logo-text" color="inherit">
                                        NEW AGE SYSTEMS
                                    </Typography>
                                </div>
                            </div>
                        </motion.div>

                        {signOffMessage && (
                            <Box sx={{ width: '100%' }}>
                                <Collapse in={open}>
                                    <Alert variant="filled" severity="error"
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2 }}
                                    >
                                        {signOffMessage}
                                    </Alert>
                                </Collapse>
                            </Box>
                        )}

                        <form
                            name="loginForm"
                            noValidate
                            className="flex flex-col justify-center w-full mt-32"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mb-24"
                                        label={t('signIn.email')}
                                        autoFocus
                                        type="email"
                                        error={!!errors.email}
                                        helperText={errors?.email?.message}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                )}
                            />

                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mb-24"
                                        label={t('signIn.password')}
                                        type={showPassword ? 'text' : 'password'}
                                        error={!!errors.password}
                                        helperText={errors?.password?.message}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}
                            />

                            <div id="captcha" className="flex justify-center align-center"></div>

                            <Controller
                                name="puentecheckbox"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="puentecheckbox"
                                        style={{ "visibility": "hidden" }}
                                        className="mb-16"
                                        label="checkbox"
                                        type="checkbox"
                                    />
                                )}
                            />

                            <LoadingButton
                                loading={loading}
                                variant="contained"
                                color="primary"
                                className=" w-full mt-16"
                                aria-label="LOG IN"
                                disabled={_.isEmpty(dirtyFields) || !isValid}
                                type="submit"
                                size="large"
                            >
                                {t('signIn.login')}
                            </LoadingButton>
                        </form>
                    </CardContent>
                </Card>
            </motion.div>
        </Root>
    );
}

export default SignInPage;

import { styled } from "@mui/material";

const StyledMessageContainerRowBase = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    padding: '0 16px 30px 16px',
    marginBottom: '10px',
    flex: '0 0 auto',

    '& .avatar': {
        position: 'absolute',
        left: -32,
        margin: 0,
    },

    '& .message-extra-data': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '100%',
        margin: '0px 10px 0px 10px',
        fontSize: 11,
    },

    '& .message-data-user': {
        position: 'relative',
        display: 'flex',
        maxWidth: '100%',
        width: '100%',
    },

    '& .message-data-user-group': {
        position: 'relative',
        display: 'flex',
        maxWidth: '100%',
        width: '100%',
    },

    '& .message-data-user-readtime': {
        position: 'relative',
        display: 'flex',
        maxWidth: '100%',
        width: '100%',
        color: 'rgb(155 155 155)',
    },

    '& .message-data-client-name': {
        position: 'relative',
        display: 'flex',
        maxWidth: '100%',
        width: '100%',
    },

    '& .message-error': {
        position: 'relative',
        display: 'flex',
        maxWidth: '100%',
    },

    '& .bubble': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 12,
        maxWidth: '70%',
    },

    '& .message': {
        whiteSpace: 'pre-wrap',
        lineHeight: 1.2,
    },

    '& .time': {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        fontSize: 11,
        marginTop: 5,
        marginBottom: -10,
    },

    '&.contact': {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        flexDirection: 'row-reverse',

        '& .message-data-user': {
            alignItems: 'center',
            justifyContent: 'flex-start',
        },

        '& .message-data-user-group': {
            alignItems: 'center',
            justifyContent: 'flex-start',
        },

        '& .message-data-user-readtime': {
            alignItems: 'center',
            justifyContent: 'flex-start',
        },

        '& .message-data-client-name': {
            alignItems: 'center',
            justifyContent: 'flex-start',
        },

        '& .message-error': {
            alignItems: 'center',
            justifyContent: 'flex-start',
        },

        '& .bubble': {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.getContrastText(
                theme.palette.background.paper
            ),
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
        },
        '&.first-of-group': {
            '& .bubble': {
                borderTopLeftRadius: 20,
            },
        },
        '&.last-of-group': {
            '& .bubble': {
                borderBottomLeftRadius: 20,
            },
        },
    },
    '&.me': {
        paddingLeft: 40,
        alignItems: 'flex-start',
        justifyContent: 'flex-end',

        '& .message-data-user': {
            alignItems: 'center',
            justifyContent: 'flex-end',
        },

        '& .message-data-user-group': {
            alignItems: 'center',
            justifyContent: 'flex-end',
        },

        '& .message-data-user-readtime': {
            alignItems: 'center',
            justifyContent: 'flex-end',
        },

        '& .message-data-client-name': {
            alignItems: 'center',
            justifyContent: 'flex-end',
        },

        '& .message-error': {
            alignItems: 'center',
            justifyContent: 'flex-end',
        },

        '& .avatar': {
            order: 2,
            margin: '0 0 0 16px',
        },

        '& .bubble': {
            // marginLeft: 'auto',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
        },
        '&.first-of-group': {
            '& .bubble': {
                borderTopRightRadius: 20,
            },
        },

        '&.last-of-group': {
            '& .bubble': {
                borderBottomRightRadius: 20,
            },
        },
    },
    '&.contact + .me, &.me + .contact': {
        paddingTop: 20,
        marginTop: 20,
    },
    '&.first-of-group': {
        '& .bubble': {
            borderTopLeftRadius: 20,
            paddingTop: 13,
        },
    },
    '&.last-of-group': {
        '& .bubble': {
            borderBottomLeftRadius: 20,
            paddingBottom: 13,
        },
    },
}));

export default StyledMessageContainerRowBase;
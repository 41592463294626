import { orderMessages } from "./socialMediaUtil";
import { selectConversationList, updateConversationItem } from "../store/conversationsSlice";
import { getConversations } from "./shared/utils";

export const newMessage = (data) => (dispatch, getState) => {
    const conversations = selectConversationList(getState()) || [];

    data.items.forEach((mensaje) => {
        const { 
            id_conversation,
            direction, 
            status, 
            createdDatetime,
            text,
            attachments,
            id,
            comment,
            reply_to,
            referral,
            idUsuario,
            NombreUsuario
        } = mensaje;

        const conversation = conversations.find(conv => conv.id === id_conversation);

        if (!conversation) {
            // If the conversation doesn't exist, we need to fetch it
            dispatch(getConversations({
                id: id_conversation,
                limit: '0',
                offset: '0',
                tipo: 3
            }));
        } else {
            const newMessageObject = {
                attachments: attachments || [],
                comment,
                createdDatetime,
                direction,
                id,
                id_conversation,
                status,
                text,
                reply_to,
                referral,
                idUsuario,
                NombreUsuario
            };

            dispatch(addMessage(newMessageObject));
            dispatch(addMessagesConversation({
                idConversacion: id_conversation,
                messages: [newMessageObject]
            }));
        }
    });
};

const addMessage = (newMessage) => (dispatch, getState) => {
    const { id_conversation, direction, status, createdDatetime } = newMessage;

    const contactListState = [...selectConversationList(getState())];
    const contactByMessage = contactListState.find(i => i.id === id_conversation);

    if (!contactByMessage) {
        console.error('Conversation id not found in addMessage function');
        return;
    }

    const updatedContact = { ...contactByMessage };

    if (direction === 'received') {
        updatedContact.lastReceived = createdDatetime;
    } else if (direction === 'sent') {
        updatedContact.lastSent = createdDatetime;
    }

    updatedContact.lastMessage = newMessage;
    updatedContact.updatedDatetime = createdDatetime;

    dispatch(updateConversationItem(updatedContact));
};

export const addMessagesConversation = ({ idConversacion, messages }) => (dispatch, getState) => {
    const conversationList = [...selectConversationList(getState())];
    let conversationItem = conversationList.find((conversation) => conversation.id === idConversacion);

    if (conversationItem !== undefined) {
        let updatedConversationItem = { ...conversationItem };
        let mensajesAdd = [...updatedConversationItem.messages];

        if (mensajesAdd.length) {
            messages.forEach(item => {
                const existingIndex = mensajesAdd.findIndex(msg => msg.id === item.id);
                if (existingIndex === -1) {
                    mensajesAdd.push(item);
                } else {
                    mensajesAdd[existingIndex] = item;
                }
            });
            mensajesAdd = orderMessages(mensajesAdd);
        } else {
            mensajesAdd = messages;
        }

        // Solo actualizamos lastMessage si no ha sido actualizado previamente
        if (!updatedConversationItem.lastMessage || 
            new Date(updatedConversationItem.lastMessage.createdDatetime) < new Date(messages[0].createdDatetime)) {
            updatedConversationItem.lastMessage = { ...messages[0] };
            updatedConversationItem.updatedDatetime = messages[0].createdDatetime;
        }

        updatedConversationItem.messages = mensajesAdd;
        
        try {
            dispatch(updateConversationItem(updatedConversationItem));
        } catch (error) {
            console.log(`error addMessagesConversation: ${error}`)
        }
    }
};

export const updateOffsetConversation = (data) => (dispatch, getState) => {
    const { conversationId, newOffset } = data;
    const conversations = [...selectConversationList(getState())];
    const contactItem = conversations.find((conversation) => conversation.id === conversationId);
    if (contactItem !== undefined) {
        dispatch(
            updateConversationItem({
                ...contactItem,
                offset: newOffset,
            })
        );
    }
};
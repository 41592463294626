import CryptoJS from 'crypto-js';
import jwtService from 'app/auth/services/jwtService';
import jwtDecode from 'jwt-decode';

export const encrypt = (data) => {
    const accessToken = jwtService.getAccessToken();
    const token = jwtDecode(accessToken);
    const key = token.prv.substring(0, 32);

    const iv = CryptoJS.lib.WordArray.random(16);
    const keyWordArray = CryptoJS.enc.Utf8.parse(key);

    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), keyWordArray, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    const concatenatedWordArray = CryptoJS.lib.WordArray.create()
        .concat(iv)
        .concat(encrypted.ciphertext);

    return CryptoJS.enc.Base64.stringify(concatenatedWordArray);
};

export const decrypt = (data) => {
    if (typeof data !== 'string') {
        return data;
    }

    try {       
        const accessToken = jwtService.getAccessToken();
        const token = jwtDecode(accessToken);
        const key = token.prv.substring(0, 32);

        const binaryStr = atob(data);
        const bytes = new Uint8Array(binaryStr.length);

        for (let i = 0; i < binaryStr.length; i++) {
            bytes[i] = binaryStr.charCodeAt(i);
        }

        const iv = bytes.slice(0, 16);
        const encryptedBytes = bytes.slice(16);

        const ivWordArray = CryptoJS.lib.WordArray.create(iv);
        const ciphertextWordArray = CryptoJS.lib.WordArray.create(encryptedBytes);
        const keyWordArray = CryptoJS.enc.Utf8.parse(key);

        const decrypted = CryptoJS.AES.decrypt(
            { ciphertext: ciphertextWordArray },
            keyWordArray,
            {
                iv: ivWordArray,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );

        const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

        return JSON.parse(decryptedText);
    } catch (error) {
        throw new Error('Error decrypting data');
    }
};

import { orderConversations, orderMessages, subtractHour } from './socialMediaUtil';
import { selectConversationList, updateConversationItem, updateLastMessageByConversation } from '../store/conversationsSlice';
import { stickersvg } from './Svg';
import axios from 'axios';
import Icon from '@mui/material/Icon';
import formatWhatsAppText from '../components/whatsapp/Utils/FormatWhatsAppText';
import { getConversations, closeOpenTags } from './shared/utils';
import { buscarConversaciones } from 'app/services/socialMediaService';
import { addHour } from 'app/theme-layouts/shared-components/whatsappPanel/Utils/shared/utils';

export const updateOffsetChannel = (data) => (dispatch, getState) => {
    // REPENSAR PARA CAMBIAR EL OFFSET Y SEPARAR DEL LISTADO DE CONVERSACIONES
    const { conversationId, channelId, newOffset } = data;
    const conversations = [...selectConversationList(getState())];
    const contactItem = conversations.find((conversation) => conversation.id === conversationId);
    if (contactItem !== undefined) {
        dispatch(
            updateConversationItem({
                ...contactItem,
                channels: contactItem.channels.map((channel) => {
                    if (channel.id === channelId) {
                        return {
                            ...channel,
                            offset: newOffset,
                        };
                    }
                    return channel;
                }),
            })
        );
    }
};

export const newMessage = (data) => async (dispatch, getState) => {
    const conversations = selectConversationList(getState()) || [];
    const { message } = data.items;

    if (!message) {
        dispatch(updateUserGroupMessageReceived(data));
        return;
    }

    const {
        conversationId,
        channelId,
        oldConversationId,
        contactId,
        displayName,
        estadoConversacion,
        type,
        content,
        id
    } = message;

    const conversation = conversations.find(conv => conv.id === conversationId);
    const channel = conversation?.channels.find(ch => ch.id === channelId);

    if (!conversation || !channel) {
        if (oldConversationId) {
            const updatedOld = conversations.some(conv => {
                if (conv.id === oldConversationId) {
                    conv.id = conversationId;
                    conv.contact = { id: contactId, displayName };

                    return true;
                }

                return false;
            });

            if (!updatedOld && estadoConversacion !== 7) {
                dispatch(getConversations({
                    offset: '0',
                    limit: '0',
                    _conversation: data.items,
                    tipo: 1,
                }));
            }
        } else if (estadoConversacion !== 7) {
            dispatch(getConversations({
                offset: '0',
                limit: '0',
                _conversation: data.items,
                tipo: 1,
            }));
        }
    } else if (type === 'audio' && content?.audio?.transcripcion) {
        const conversationList = [...selectConversationList(getState())];
        const conversationItem = conversationList.find(conv => conv.id === conversationId);

        if (conversationItem) {
            const updatedChannels = conversationItem.channels.map(ch => {
                if (ch.id !== channelId) return ch;

                const updatedMessages = ch.messages.map(msg =>
                    msg.id === id
                        ? {
                            ...msg,
                            content: {
                                ...msg.content,
                                audio: {
                                    ...msg.content.audio,
                                    transcripcion: content.audio.transcripcion,
                                },
                            },
                        }
                        : msg
                );

                return { ...ch, messages: updatedMessages };
            });

            dispatch(updateConversationItem({
                ...conversationItem,
                channels: updatedChannels,
            }));
        }
    } else {
        dispatch(addMessage(message));
        dispatch(addMessagesToChannel({
            idConversacion: conversationId,
            messages: [message],
        }));
    }

    dispatch(updateUserGroupMessageReceived(data));
};

export const removeMessagesFromChannel =
    ({ idConversation, idChannel, idMessage }) =>
        (dispatch, getState) => {
            try {
                const conversationList = [...selectConversationList(getState())];
                const conversationItem = conversationList.find((conversation) => conversation.id === idConversation);
                if (conversationItem !== undefined) {
                    dispatch(
                        updateConversationItem({
                            ...conversationItem,
                            channels: conversationItem.channels.map((channel) => {
                                if (channel.id === idChannel) {
                                    const messages = channel.messages?.filter((message) => message.id !== idMessage);

                                    return {
                                        ...channel,
                                        messages,
                                    };
                                }
                            }),
                        })
                    );
                }
            } catch (error) {
                console.error('Error removing message from channel:', error);
            }
        };

export const removeChannelFromConversation =
    ({ idConversation, idChannel }) =>
        (dispatch, getState) => {
            try {
                const conversationList = [...selectConversationList(getState())];
                const conversationItem = conversationList.find((conversation) => conversation.id === idConversation);
                if (conversationItem !== undefined) {
                    dispatch(
                        updateConversationItem({
                            ...conversationItem,
                            channels: conversationItem.channels.map((channel) => {
                                if (channel.id != idChannel) {
                                    return {
                                        ...channel,
                                    };
                                }
                            }),
                        })
                    );
                }
            } catch (error) {
                console.error('Error removing message from channel:', error);
            }
        };

export const addMessagesToChannel = ({ idConversacion, messages }) =>
        (dispatch, getState) => {
            const conversationList = [...selectConversationList(getState())];
            const conversationItem = conversationList.find((conversation) => conversation.id === idConversacion);

            // console.log('addMessagesToChannel--------------------------------------------------------------------')
            // console.log('messages ', messages)
            // console.log( 'conversationItem ', conversationItem )

            if (conversationItem !== undefined) {
                try {
                    dispatch(
                        updateConversationItem({
                            ...conversationItem,
                            channels: conversationItem.channels.map((channel) => {
                                const mensajes = messages.filter((message) => message.channelId == channel.id || message.idCanal == channel.id);
                                let mensajesAdd = JSON.parse(JSON.stringify(channel.messages));

                                // console.log('msg A ', mensajes)
                                // console.log('msg B ', mensajesAdd)

                                mensajes.forEach((item) => {
                                    const { id } = item;
                                    const { status } = item;
                                    let agregar = true;

                                    channel.messages.forEach((i) => {
                                        if (i.id == id) {
                                            if (i.direction == 'sent') {
                                                let actualizar = false;

                                                if (i.status == 'pending' && (status == 'transmitted' || status == 'sent' || status == 'delivered' || status == 'read')) {
                                                    actualizar = true;
                                                }

                                                if (i.status == 'transmitted' && (status == 'sent' || status == 'delivered' || status == 'read')) {
                                                    actualizar = true;
                                                }

                                                if (i.status == 'sent' && (status == 'delivered' || status == 'read')) {
                                                    actualizar = true;
                                                }

                                                if (i.status == 'delivered' && item.status == 'read') {
                                                    actualizar = true;
                                                }

                                                if (actualizar == true) {
                                                    mensajesAdd = mensajesAdd.map((x) => {
                                                        if (x.id == id) {
                                                            x.status = status;
                                                        }
                                                        return x;
                                                    });
                                                }
                                            }

                                            agregar = false;
                                        }
                                    });

                                    if (agregar == true) {
                                        mensajesAdd.push(item);
                                    }
                                });

                                if (mensajesAdd.length > 0) {
                                    return {
                                        ...channel,
                                        messages: mensajesAdd,
                                    };
                                }

                                return channel;
                            }),
                        })
                    );
                } catch (error) {
                    console.error(error);
                    // Expected output: ReferenceError: nonExistentFunction is not defined
                    // (Note: the exact output may be browser-dependent)
                }
            }
        };

export const addMessage = (newMessage) => (dispatch, getState) => {
    const { conversationId } = newMessage;
    const { channelId } = newMessage;
    const { direction } = newMessage;
    const { status } = newMessage;
    let { createdDatetime } = newMessage;

    if (createdDatetime.indexOf('.') > -1) createdDatetime = createdDatetime.split('.')[0];

    const contactListState = [...selectConversationList(getState())];

    const findConversationById = contactListState.find((i) => i.id === conversationId);
    const contactByMessage = findConversationById !== undefined ? { ...findConversationById } : null;

    if (contactByMessage == null) {
        alert('Conversacion id no encontrada en addMessage function');
        return;
    }

    const findChannel = contactByMessage.channels.find((i) => i.id === channelId);
    const channel = findChannel !== undefined ? { ...findChannel } : null;

    let add = true;
    let notReaded = true;

    const message = null;

    if (message != null) {
        if (message.direction == 'sent') {
            let update = false;

            if (message.status == 'pending' && (status == 'transmitted' || status == 'sent' || status == 'delivered' || status == 'read')) {
                update = true;
            }

            if (message.status == 'transmitted' && (status == 'sent' || status == 'delivered' || status == 'read')) {
                update = true;
            }

            if (message.status == 'sent' && (status == 'delivered' || status == 'read')) {
                update = true;
            }

            if (message.status == 'delivered' && status == 'read') {
                update = true;
            }

            if (update == true) {
                message.status = status;
            }
        }
        add = false;
        if (message.mensajenoleido) notReaded = false;
    }

    if (message == null && add) {
        if (direction == 'received') {
            if (notReaded) {
                let fecha = new Date(createdDatetime);
                fecha.setSeconds(fecha.getSeconds() - 1);
                const ano = fecha.getFullYear();
                let mes = fecha.getMonth() + 1;
                let dia = fecha.getDate();

                let hora = fecha.getHours();
                let minuto = fecha.getMinutes();
                let segundo = fecha.getSeconds();

                if (mes < 10) {
                    mes = `0${mes}`;
                }
                if (dia < 10) {
                    dia = `0${dia}`;
                }
                if (hora < 10) {
                    hora = `0${hora}`;
                }
                if (minuto < 10) {
                    minuto = `0${minuto}`;
                }
                if (segundo < 10) {
                    segundo = `0${segundo}`;
                }

                fecha = `${ano}-${mes}-${dia} ${hora}:${minuto}:${segundo}`;
            }
            channel.lastReceived = createdDatetime;
        }

        if (direction == 'sent') {
            channel.lastSent = createdDatetime;
        }

        contactByMessage.channels.map((ch) => (ch.id === channel.id ? (ch = channel) : null));
    }

    dispatch(updateLastMessageByConversation(contactByMessage, newMessage));
};

export const updateUserGroupMessageReceived = (data) => () => {
    if (data.actualizados && data.actualizados.length > 0) {
        /**
        let chatCopy = [...getState().socialMediaPanel.chat];
        chatCopy = JSON.parse(JSON.stringify(chatCopy));
        //console.log("updateUserGroupMessageReceived");
        data.actualizados.map((item) => {
            let id = item.id;
            let groupName = item.NombreGrupo;
            let userName = item.NombreUsuario;
            let groupId = item.idGrupo;
            let userId = item.idUsuario;

            chatCopy.map((i) => {
                if (i.id == id) {
                    i.NombreGrupo = groupName;
                    i.NombreUsuario = userName;
                    i.idGrupo = groupId;
                    i.idUsuario = userId;
                }
            })

        })
        dispatch(updateChat(chatCopy));
        */
    }
};

export const formatLastMessage = (item) => {
    let texto = '';
    let mensaje = '';

    switch (item.type) {
        case 'interactive': {
            if (item.content.interactive && item.content.interactive.action && item.content.interactive.body) {
                mensaje = item.content.interactive.body.text.replace(/<br>/g, ' ');
            }

            if (item.content.interactive && item.content.interactive.reply) {
                mensaje = item.content.interactive.reply.text;
            }

            if (item.content.interactive && item.content.interactive.button_reply) {
                mensaje = item.content.interactive.button_reply.title;
            }

            if (mensaje.length > 25) {
                mensaje = `${mensaje.substring(0, 25)}...`;
            }

            texto = mensaje;
            break;
        }
        case 'text': {
            if (item.content.text.indexOf('Consumer shared a contact') > -1) {
                mensaje = <Icon>contact_phone</Icon>;
            } else {
                mensaje = formatWhatsAppText(item.content.text); // item.content.text.replace(/<br>/g, ' ');
                if (mensaje.length > 25) {
                    if (mensaje.substring(24, 25) == ' ') {
                        mensaje = closeOpenTags(`${mensaje.substring(0, 24)}...`);
                    } else {
                        mensaje = closeOpenTags(`${mensaje.substring(0, 25)}...`);
                    }
                }
            }

            texto = mensaje;
            break;
        }
        case 'image': {
            texto = (
                <span className="flex items-center justify-center">
                    <Icon>camera_alt</Icon> Imagen
                </span>
            );
            break;
        }
        case 'whatsappSticker': {
            texto = stickersvg;
            break;
        }
        case 'audio': {
            texto = (
                <span className="flex items-center justify-center">
                    <Icon>mic</Icon> Audio
                </span>
            );
            break;
        }
        case 'video': {
            texto = (
                <span className="flex items-center justify-center">
                    <Icon>videocam</Icon> Video
                </span>
            );
            break;
        }
        case 'file': {
            texto = (
                <span className="flex items-center justify-center">
                    <Icon>insert_drive_file</Icon> Archivo
                </span>
            );
            break;
        }
        case 'location': {
            texto = (
                <span className="flex items-center justify-center">
                    <Icon>location_on</Icon> Ubicación
                </span>
            );
            break;
        }
        case 'hsm': {
            mensaje = item.content.hsm.body ? item.content.hsm.body.text : item.content.hsm;

            if (!mensaje) mensaje = '';

            if (item.content.hsm.components) {
                mensaje = 'PLANTILLA';
                let { components } = item.content.hsm;
                if (!Array.isArray(components)) {
                    components = Object.values(components);
                }
                components.forEach((result) => {
                    if (result.type.toLowerCase() == 'header' || result.type.toLowerCase() == 'body' || result.type.toLowerCase() == 'footer') {
                        if (result.parameters) {
                            if (result.parameters.length > 0) {
                                if (result.parameters[0].type == 'text') {
                                    mensaje = result.parameters[0].text;
                                }
                            }
                        } else if (result.type.toLowerCase() == 'body') {
                            if (result.text) {
                                mensaje = result.text;
                            }
                        }
                    }
                });
            }

            mensaje = mensaje.replace(/<br>/g, '');

            if (mensaje.length > 25) {
                texto += `${mensaje.substring(0, 25)}...`;
            } else {
                texto += mensaje;
            }
            break;
        }
        case 'order': {
            const elementos = item?.content?.order?.product_items?.length;
            const elementosText = elementos > 1 ? 'elementos' : 'elemento';
            texto = (
                <>
                    <Icon>shopping_cart</Icon> {elementos} {elementosText}
                </>
            );
            break;
        }
        case 'reaction': {
            if (item.content.reaction == null || !item.content.reaction.emoji) {
                texto += `Reacción`;
            } else {
                texto += `Reacción con ${item.content.reaction.emoji} a un mensaje:`;
            }
            break;
        }
        case 'unsupported': {
            texto += `Mensaje no soportado`;
            break;
        }
        default: {
            texto += item.type;
            break;
        }
    }

    if (item.status == 'deleted') {
        texto = (
            <span className="flex items-center justify-center">
                <Icon>not_interested</Icon> Este mensaje fue eliminado
            </span>
        );
    }
    return texto;
};

export const searchConversation = (text) => async (dispatch, getState) => {
    const params = {
        tipo: 1,
        limit: 0,
        offset: 0,
        status: 'active',
        buscar: text,
    };
    const data = await buscarConversaciones(params);

    const contactsState = selectConversationList(getState());
    const copyContacts = contactsState ? JSON.parse(JSON.stringify(contactsState)) : [];

    data.map((item) => {
        const findConversation = copyContacts.find((c) => c.id === item.id);
    });
};

export const queryFileMessage = async (item) => {
    const url = '/amg/whatsapp/consultar_archivo_mensaje'; // este endpoint no existe aun para probar localmente jajaj
    const params = {
        id: item.id,
        channelId: item.channelId,
        content: item.content,
        type: item.type,
    };
    const response = await axios.post(url, params);
    const data = await response.data;
    if (data.url) {
    }
};

export const imgLoad = (e, offset) => {
    const status = $(e.target).closest(`.message-content`).find('.message-data-time').attr('data-status');
    if (status !== 'sending') {
        $(e.target).closest(`.message-content`).find('.loader').remove();
    }

    if (e.target.height == 0) {
        if (offset == undefined || offset == 0) {
            try {
                $(e.target)
                    .closest('.chat-history')
                    .animate({ scrollTop: $(e.target).closest('.chat-history')[0].scrollHeight }, 50);
            } catch (err) {
                console.log(err.message);
                return;
            }
        }

        const MimeContentType = {
            width: e.target.naturalWidth,
            height: e.target.naturalHeight,
        };

        let alto = MimeContentType.height;
        const ancho = MimeContentType.width;

        if (alto == ancho) {
            alto = 200;
        } else if (alto > ancho) {
            const proporcion = (ancho * 100) / alto;
            alto = (200 * 100) / proporcion;
        } else if (alto < ancho) {
            const proporcion = (alto * 100) / ancho;
            alto = (proporcion * 200) / 100;
        }

        e.target.height = alto.toFixed(0);
        const id = $(e.target).closest(`.message-content`).attr('id').replace('message-content-id-', '');

        if (e.target.src.indexOf('/media/v1/') > -1) {
            asignar_content_type_mensaje(id, MimeContentType);
        } else if (status !== 'sending') {
            const element = $(e.target).closest(`.message-container`);

            if (element.hasClass('message-out')) {
                asignar_content_type_mensaje(id, MimeContentType);
            } else {
                const item = {
                    id,
                    type: 'image',
                    content: {
                        image: {
                            url: e.target.src,
                        },
                    },
                };

                buscar_archivo(item, id, offset, MimeContentType);
            }
        }
    } else if (status == 'sending') {
        $(e.target)
            .closest('.chat-history')
            .animate({ scrollTop: $(e.target).closest('.chat-history')[0].scrollHeight }, 10);
    }
};

export const assignContentType = (id, MimeContentType) => {
    const url = '/amg/whatsapp/asignar_content_type_mensaje'; // este endpoint no existe aun para probar localmente
    const params = {
        id,
        MimeContentType,
    };
    const response = axios.post(url, params);
};

export const searchFile = async (item, id, offset, MimeContentType) => {
    const url = '/amg/whatsapp/buscar_archivo'; // este endpoint no existe aun para probar localmente
    const params = {
        url: item.content[item.type].url,
    };
    const response = await axios.post(url, params);
    const data = await response.data;

    if (MimeContentType) {
        data.map((value) => {
            MimeContentType[key] = value;
        });
        const asignar = await assignContentType(item.id, MimeContentType);
    } else {
        item.MimeContentType = data;
        const asignar = assignContentType(item.id, item.MimeContentType);
        // let showFile = await showFile(item,id,offset);
        // return showFile;
    }
};

export const searchFileMessage = async (item) => {
    const url = '/amg/whatsapp/consultar_archivo_mensaje'; // este endpoint no existe aun para probar localmente
    const params = {
        id: item.id,
        channelId: item.channelId,
        content: item.content,
        type: item.type,
    };
    const response = await axios.post(url, params);
    const data = await response.data;
    if (data.url) {
        return data.url;
    }
};

export const previewMessage = ({ channelId, contactId, message, type, id }) => {
    if (type !== 'hsm') {
        return null;
    }

    return {
        id,
        NombreGrupo: null,
        NombreUsuario: null,
        channelId,
        contenido: '',
        content: { text: message },
        conversationId: contactId,
        createdDatetime: addHour(),
        direction: 'sent',
        idGrupo: null,
        idUsuario: null,
        status: 'sending',
        type: 'text',
        updatedDatetime: '',
    };
};

export const updateMessage = (props) => {
    const { originalChat, data, tempId } = props;
    const copyChat = [...originalChat?.filter((elem) => elem.id != tempId)];
    const message = originalChat.filter((elem) => elem.id === tempId);
    let message2;

    if (data.type === 'audio' && data.content.audio.transcripcion) {
        message2 = { ...data };
    } else {
        message2 = { ...message[0] };
        message2.id = data.id;
    }

    copyChat.push(message2);
    const chat = orderMessages(copyChat);
    const objReturn = {
        chat,
        updatedMessage: message2,
    };

    return objReturn;
};

export const updateLastMessage = ({ conversations, messageObj }) => {
    const { conversationId, createdDatetime } = messageObj;

    const updatedConversations = conversations
        .filter(conv => conv.id !== conversationId)
        .concat(
            (() => {
                const conversation = conversations.find(conv => conv.id === conversationId);
                return {
                    ...conversation,
                    updatedDatetime: createdDatetime,
                    lastMessage: {
                        ...messageObj,
                        createdDatetime: subtractHour(createdDatetime)
                    }
                };
            })()
        )
        .sort(orderConversations);

    return updatedConversations;
};


export const formatHsm = (hsm) => { };

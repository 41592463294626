import { styled } from '@mui/material/styles';

function MessageTypeReaction(props){
    let emoji = props?.content?.emoji;

    const Reaction = styled('div')({
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: '-15px',
        bottom: '-15px',
        width: '30px',
        height: '30px',
        zIndex: '1000',
        backgroundColor: '#b9b3b3',
        fontSize: '15px',
        textAlign: 'center',
        borderRadius: '50px',
        '&::after': {
            position: 'relative',
            left: 0,
            bottom: 0,
            content: `"${emoji}"`,
        }
      });

    return(
        <>
        <Reaction className="font-sans"></Reaction>
        </>
    )
}

export default MessageTypeReaction
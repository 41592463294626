import { lazy, Suspense } from 'react';
import { styled } from '@mui/material/styles';
import WhatsAppFormattedText from 'app/theme-layouts/shared-components/whatsappPanel/components/WhatsAppFormattedText';

const DivStyled = styled('div')(({ theme }) => ({
    borderRadius: '0px 10px 10px 0px',
    padding: '10px',
    background: 'rgb(249, 249, 249)',
    boxShadow: '-10px 0px 0px 0px rgba(0, 0, 0, 0.50)',
    marginLeft: '10px',
    backgroundColor: 'red'
}));

function MessageTypeText({ type, text: propsText, context }) {
    const text = (type === "unsupported") ? "Mensaje no soportado" : propsText;

    let messageContext = null;
    if (context) {
        const MessageTypeComponent = lazy(() => import("."));
        messageContext = <MessageTypeComponent type={context.type} content={context.content} context={context.context} />;
    }

    return (
        <>
            {context && (
                <Suspense fallback={<div>Loading...</div>}>
                    <DivStyled>
                        {messageContext}
                    </DivStyled>
                </Suspense>
            )}
            {text && (
                <div className={context ? "my-8" : ""}>
                    <WhatsAppFormattedText text={text} />
                </div>
            )}
        </>
    );
}

export default MessageTypeText;

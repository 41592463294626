import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import {
    fbConsultBeforeTransferringGroup,
    fbTransferGroupConversation,
    igConsultBeforeTransferringGroup,
    igTransferGroupConversation,
    wsConsultBeforeTransferringGroup,
    wsTransferGroupConversation
} from "app/services/conversationActionsServices/transferDepartmentActionsServices";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import { removeConversationItem, updateConversationItem } from "../../store/conversationsSlice";
import {Box } from "@mui/material";
import useConversationManager from "../../hooks/conversations/conversationActions/useConversationManager";

const TransferDepartmentActions = ({ t, setSubMenu, selectedConversation, selectedChannelId, handleMenuHeaderChatClose }) => {
    const dispatch = useDispatch();
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(false);
    const { fetchWhatsappConversationManager } = useConversationManager();

    const createConsultBeforeTransferParams = (selectedConversation, selectedChannelId) => {
        const params = { tipo: selectedConversation.tipo };

        if (selectedConversation.tipo === 1) {
            params.idConversacion = selectedConversation.id;
            params.idCanal = selectedChannelId;
            params.numeroTelefonico = selectedConversation.msisdn;
        } else if ([3, 5].includes(selectedConversation.tipo)) {
            params.id = selectedConversation.id;
        }

        return params;
    };

    const createTransferParams = (selectedConversation, selectedChannelId, department) => {
        const params = { tipo: selectedConversation.tipo, idGrupo: department.id };

        if (selectedConversation.tipo === 1) {
            params.idConversacion = selectedConversation.id;
            params.idCanal = selectedChannelId;
        } else if ([3, 5].includes(selectedConversation.tipo)) {
            params.id = selectedConversation.id;
        }

        return params;
    };

    const fetchDepartments = async (params) => {
        try {
            let result = false;;
            switch (params.tipo) {
                case 1:
                    result= await wsConsultBeforeTransferringGroup(params);
                    break;
                case 3:
                    result = await fbConsultBeforeTransferringGroup(params);
                    break;
                case 5:
                    result = await igConsultBeforeTransferringGroup(params);
                    break;
            }
            return result;
        } catch (error) {
            console.error('Error fetching departments:', error);
            return [];
        }
    };

    const fetchTranferConversationToDepartment = async (params) => {
        try {
            let result = false;
            switch (params.tipo) {
                case 1:
                    result = await wsTransferGroupConversation(params);
                    break;
                case 3:
                    result = await fbTransferGroupConversation(params);
                    break;
                case 5:
                    result = await igTransferGroupConversation(params);
                    break;
            }
            return result;
        } catch (error) {
            console.error('Error fetching departments:', error);
            return [];
        }
    };

    const createDepartmentMenuItems = (departments, handleDepartmentClick) => {
        return departments.map(department => (
            <MenuItem 
                key={department.id}
                onClick={() => handleDepartmentClick(department)}
                >
                <Box
                    component="span"
                    sx={{
                        display: 'inline-block',
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        bgcolor: department.activos > 0 ? `#5cb85c` : `#d43f3a`,
                        mr: 1
                    }}
                />
                {department.descripcion}
            </MenuItem>
        ));
    };

    const handleTransferDepartment = async () => {
        setLoading(true);
        try {
            const params = createConsultBeforeTransferParams(selectedConversation, selectedChannelId);

            const departments = await fetchDepartments(params);

            if (departments.length) {
                const departmentMenuItems = createDepartmentMenuItems(departments, handleDepartmentClick);
                setSubMenu(departmentMenuItems);
                setLoading(false);
            } else {
                setLoading(false);
                handleMenuHeaderChatClose();
                dispatch(showMessage({
                    message: 'No hay departamentos disponibles para transferir la conversación',
                    variant: 'error',
                }));
            }
        } catch (error) {
            console.error('Error fetching departments:', error);
            setLoading(false);
            return [];
        }

    };

    const handleDepartmentClick = async (department) => {

        if (department.activos > 0) {

            try {
                const params = createTransferParams(selectedConversation, selectedChannelId, department);

                const data = await fetchTranferConversationToDepartment(params);

                handleMenuHeaderChatClose();

                if (data.status == true || data.status == 'success') {
                    dispatch(showMessage({
                        message: result.mensaje,
                        variant: 'success',
                    }));

                    let datos = {};

                    if (params.tipo == 1) {
                        datos = {
                            operacion: 'enviarMensaje',
                            mensaje: {
                                tipo: params.tipo,
                                items: [],
                                enviados: [],
                                trans_grupo: [],
                                trans_usuario: [],
                                actualizados: []
                            },
                            sendTo: [data.usuario_add]
                        };

                        datos.mensaje.trans_grupo.push({ ...selectedConversation, buscado: false, operacion: 'add' });

                        dispatch({ type: 'socket/send', payload: datos });

                        if (data.usuario_del) {
                            if (data.usuario_del.usuario_actual == data.usuario_del.usuario_conversacion) {
                                if (data.usuario_del.tiene_permiso == true) {
                                    fetchWhatsappConversationManager(selectedConversation.id, selectedChannelId, false, true);
                                } else {
                                    const channels = selectedConversation.channels.filter((channel) => channel.id !== selectedChannelId)

                                    if (channels.length == 0) {
                                        dispatch(removeConversationItem(selectedConversation.id));
                                    }

                                    const conversation = selectedConversation

                                    conversation.channels = channels;

                                    dispatch(updateConversationItem(conversation));
                                }
                            } else {
                                if (params.idConversacion && params.idCanal) {
                                    datos.mensaje.trans_usuario[0] = {
                                        idConversacion: params.idConversacion,
                                        idCanal: params.idCanal,
                                        operacion: 'delete'
                                    };
                                }

                                datos.sendTo = [data.usuario_del.usuario_conversacion];

                                dispatch({ type: 'socket/send', payload: datos });

                                fetchWhatsappConversationManager(selectedConversation.id, selectedChannelId, false, true);
                            }
                        }
                    }else if([3, 5].includes(params?.tipo)){
                        datos = {
                            operacion: 'enviarMensaje',
                            mensaje: {
                                'tipo': params.tipo,
                                'items': [],
                                'enviados': [],
                                'trans_grupo': [],
                                'trans_usuario': [
                                    {
                                        'idConversacion': id,
                                        'operacion': 'add'
                                    }
                                ],
                                'actualizados': []
                            },
                            sendTo: [data.usuario_add]
                        };
                    }
                } else {
                    dispatch(showMessage({
                        message: 'No se pudo transferir la conversación',
                        variant: 'error',
                    }));
                }
            } catch (error) {
                console.error('Error transferring to department:', error);
                return [];
            }
        }else{
            handleMenuHeaderChatClose();
            dispatch(showMessage({
                message: 'El grupo elegido no tiene usuarios activos de momento',
                variant: 'error',
            }));
        }
    };

    return (
        <MenuItem onClick={handleTransferDepartment}>
            {loading ? <>{t('wsPanel.menuConversation.transferToAnotherDepartment')} <CircularProgress size={24} /></> : t('wsPanel.menuConversation.transferToAnotherDepartment')}
        </MenuItem>
    );
};

export default TransferDepartmentActions;
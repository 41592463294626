import { actualizarPreferenciasUsuario, actualizarUsuario, iniciarSesion, registrarUsuario } from 'src/app/services/authService';
import { decrypt } from 'app/utils/cryptoUtils';
import { httpInstance } from 'src/app/services/services';
import axios from 'axios';
import FuseUtils from '@fuse/utils/FuseUtils';
import jwtDecode from 'jwt-decode';

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (err) => {
                return new Promise((resolve, reject) => {
                    if (
                        err.response.status === 401 &&
                        err.config &&
                        !err.config.__isRetryRequest
                    ) {
                        // if you ever get an unauthorized response, logout the user
                        this.emit('onAutoLogout', 'Invalid access_token');
                        this.setSession(null);
                    }
                    throw err;
                });
            }
        );
    };

    handleAuthentication = () => {
        const access_token = this.getAccessToken();

        if (!access_token) {
            this.emit('onNoAccessToken');

            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token);
            this.emit('onAutoLogin', true);
        } else {
            this.setSession(null);
            this.emit('onAutoLogout', 'access_token expired');
        }
    };

    createUser = (data) => {
        return new Promise((resolve, reject) => {
            registrarUsuario().then((response) => {
                if (response.data.user) {
                    this.setSession(response.data.access_token);
                    resolve(response.data.user);
                    this.emit('onLogin', response.data.user);
                } else {
                    reject(response.data.error);
                }
            });
        });
    };

    signInWithEmailAndPassword = (email, password, translate) => {
        return new Promise((resolve, reject) => {
            const datos = {
                user: email,
                password: password,
            };

            iniciarSesion(datos)
            .then(({ user, token, error, settings, hash }) => {
                if (user) {
                    const iconMenuMap = {
                        configuraciones: 'settings',
                        dashboard: 'dashboard',
                        mensajes_interactivos: 'swap_horiz',
                        panel_de_control: 'attach_money',
                        productos: 'category',
                        reportes: 'bar_chart',
                        status_usuarios: 'people',
                        supervisor: 'business',
                        tickets: 'confirmation_number',
                    };

                    const extendedMenu = {
                        id: 'applications',
                        icon: 'apps',
                        title: 'MENÚ PRINCIPAL',
                        translate: 'MENÚ PRINCIPAL',
                        type: 'group',
                        children: user.menu.children.map((item) => ({
                            ...item,
                            id: item.param,
                            icon: iconMenuMap[item.param] || 'help_outline',
                            translate: item.title,
                            type: 'item',
                            url: '/dashboards/' + item.param,
                        })),
                    };

                    const userData = {
                        from: user.from,
                        hash: hash,
                        menu: extendedMenu,
                        password: '',
                        permisos: user.permisos,
                        permissions: user.permissions,
                        role: 'admin',
                        uuid: user.uuid,
                        data: {
                            displayName: user.displayName,
                            photoURL: user.photoURL,
                            email: user.email,
                            preferences: user.preferences,
                            settings: {
                                layout: {
                                    style: 'layout1',
                                    config: {
                                        scroll: 'content',
                                        navbar: {
                                            display: true,
                                            folded: true,
                                            position: 'left',
                                        },
                                        toolbar: {
                                            display: true,
                                            style: 'fixed',
                                            position: 'below',
                                        },
                                        footer: {
                                            display: true,
                                            style: 'fixed',
                                            position: 'below',
                                        },
                                        mode: 'fullwidth',
                                    },
                                },
                                customScrollbars: true,
                            },
                            shortcuts: ['calendar', 'mail', 'contacts'],
                        },
                    };

                    this.setSession(token);
                    sessionStorage.user = JSON.stringify(userData);
                    sessionStorage.userSettings = JSON.stringify(settings);
                    resolve(userData);
                    this.emit('onLogin', userData);
                } else {
                    reject(error);
                }
            })
            .catch((error) => {
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.msg
                    ) {
                        const message = error.response.data.msg;
                        if (message === 'Invalid user name') {
                            err('email', translate('signIn.autenthicateError.InvalidUserName'));
                        } else if (message === 'Invalid_Domain') {
                            err('email', translate('signIn.autenthicateError.InvalidDomain'));
                        } else if (message === 'wrong authentication') {
                            err('password', translate('signIn.autenthicateError.WrongAuthentication'));
                        }
                    } else {
                        err('password', translate('signIn.autenthicateError.CannotLoginAtTheMoment'));
                    }

                    function err(tipo, mensaje) {
                        const errores = [];
                        errores.push({
                            type: tipo,
                            message: mensaje,
                        });

                        reject(errores);
                    }
                });
        });
    };

    signInWithToken = () => {
        return new Promise((resolve, reject) => {
            let user = JSON.parse(sessionStorage.user);
            resolve(user);
        });
    };

    updateUserData = (user) => {
        return actualizarUsuario(user);
    };

    updateUserPreferences = (preferences) => {
        return actualizarPreferenciasUsuario(preferences);
    }

    setSession = (access_token) => {
        if (access_token) {
            sessionStorage.setItem('jwt_access_token', access_token);
            httpInstance.defaults.headers.common.Authorization = `Bearer ${access_token}`;
            // axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
        } else {
            sessionStorage.removeItem('jwt_access_token');
            delete httpInstance.defaults.headers.common.Authorization;
        }
    };

    logout = (data) => {
        const alertLogout = data;
        this.setSession(null);
        sessionStorage.clear();

        if (alertLogout && alertLogout.length > 0) {
            sessionStorage.signOffMessage = alertLogout;
        }
        this.emit('onLogout', alertLogout);
    };

    isAuthTokenValid = (access_token) => {
        if (!access_token) {
            return false;
        }
        
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;

        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        }

        return true;
    };

    isSuperUser = async () => {
        try {
            const token = this.getAccessToken();

            if (!this.isAuthTokenValid(token)) {
                return false;
            }

            const decoded = jwtDecode(token);
            return 'super_user' in decoded && decoded.super_user === true;
        } catch (error) {
            console.error('Error checking super user status:', error);
            return false;
        }
    };

    getAccessToken = () => {
        return window.sessionStorage.getItem('jwt_access_token');
    };

    getUserPermissions = () => {
        return new Promise((resolve, reject) => {
            try {
                const user = JSON.parse(sessionStorage.getItem('user'));
                if (user?.permissions) {
                    const decryptedPermissions = decrypt(user.permissions);
                    resolve(decryptedPermissions);
                } else {
                    resolve(null);
                }
            } catch (error) {
                console.error('Error getting permissions:', error);
                reject(error);
            }
        });
    };

    getUserPreferences = () => {
        try {
            return JSON.parse(sessionStorage.getItem('userSettings')) || {};
        } catch {
            return {};
        }
    };

    updateUserPermissions = (newPermissions) => {
        const user = JSON.parse(sessionStorage.getItem('user'));

        if (!user) {
            console.error('No se encontró usuario en sessionStorage');
            return;
        }

        user.permissions = newPermissions;
        sessionStorage.setItem('user', JSON.stringify(user));
    };
}

const instance = new JwtService();

export default instance;

import { decrypt } from "app/utils/cryptoUtils";
import { getMessages } from "../../store/chatSlice";
import { obtenerConversaciones } from "app/services/socialMediaService";
import { orderMessages } from "../socialMediaUtil";
import { selectConversationList, updateConversationList, updateLastMessageByIdConversation } from "../../store/conversationsSlice";
import { sendSocketData } from "app/auth/services/socket/Store/socketSlice";
import FuseUtils from '@fuse/utils';

export const getConversations = (params) => async (dispatch, getState) => {
    const { limit, offset, filtros, _conversation, tipo } = params;

    const conversationId = _conversation?.message?.conversationId || _conversation?.conversationId;

    const requestParams = {
        filtros,
        id: conversationId,
        limit,
        offset,
        tipo,
    };

    try {
        const response = await obtenerConversaciones(requestParams);

        if (response.status && ['fail', 'error'].includes(response.status)) {
            return alert(response.mensaje);
        }
        
        if (!Array.isArray(response)) {
            throw new Error('Datos API obtenerConversaciones no válidos.');
        }

        const currentConversations = [...selectConversationList(getState())];

        response.forEach((conversation) => {
            const { channels, messages, id } = conversation;

            if (tipo === 1) {
                conversation.channels = channels?.map(channel => ({
                    ...channel,
                    offset: channel.offset || 15,
                    messages: channel.messages || [],
                })) || [];
            } else if ([3, 5].includes(tipo)) {
                conversation.messages = messages || [];
            }

            if (filtros) {
                conversation.filtered = true;
            }
            
            conversation.tipo = tipo;

            const existingConversationIndex = currentConversations.findIndex(conv => conv.id === id);
            if (existingConversationIndex !== -1) {
                currentConversations[existingConversationIndex] = {
                    ...currentConversations[existingConversationIndex],
                    ...conversation
                };
            } else {
                currentConversations.push(conversation);
            }
        });

        await dispatch(updateConversationList(currentConversations));
        await dispatch(searchLastMessage(_conversation ? response : currentConversations));
    } catch (error) {
        console.error(`Error in getConversations: ${error}`);
    }
};


export const searchLastMessage = (conversations) => async (dispatch, getState) => {
    const { socket: socketConnected } = getState();

    // Auxiliar function to handle the retrieval and update of the last message
    const handleLastMessage = async (params) => {
        const requestData = {
            params,
            lastMessage: true
        };

        try {
            const response = await dispatch(getMessages({ data: requestData }));
            const { payload, meta } = response;

            if (Array.isArray(payload)) {
                const conversationId = meta.arg.data.params.idConversacion;
                const lastMessage = payload.find(
                    (message) => message.conversationId === conversationId
                );

                if (lastMessage) {
                    dispatch(updateLastMessageByIdConversation(lastMessage.conversationId, lastMessage));
                }
            }
        } catch (error) {
            console.error(`Error in searchLastMessage: ${error}`);
        }
    };

    await Promise.all(conversations.map(async (item) => {
        try {
            const baseParams = {
                tipo: item.tipo,
                idConversacion: item.id,
                idCanal: '',
                limit: 1,
                offset: 0,
                IdMensaje: ''
            };

            // Socket is connected, send request through socket
            if (socketConnected) {
                const socketParams = { ...baseParams, operacion: 'mensajesconversacion' };
                dispatch(sendSocketData(socketParams));
            }

            // Socket is not connected or last message is missing, make request through API
            const needsLastMessage = [3, 5].includes(item.tipo) || !socketConnected;

            if (needsLastMessage) {
                await handleLastMessage(baseParams);
            }

        } catch (error) {
            console.error(`Error in searchLastMessage for conversation ID ${item.id}: ${error}`);
        }
    }));
};

export const processChat = ({ messages, chatState, reloadChat }) => {
    let copyChat1 = [...chatState];

    if (reloadChat) {
        let removeItems = copyChat1.filter((elem1) => !messages.some((elem2) => elem2.conversationId === elem1.conversationId));
        copyChat1 = removeItems;
    }

    const diff = messages.filter((elem1) => !copyChat1.some((elem2) => elem2.id === elem1.id));
    if (diff.length > 0) {
        diff.map((item) => copyChat1.push(item));
    }

    let copyChat2 = [...copyChat1.filter((elem) => elem.type !== "reaction")];
    let copyChat3 = [];
    return Promise.all(
        copyChat1.map(async (item, i) => {
            if (item.type == "reaction") {
                let message = copyChat2.filter((elem) => elem.id === item.content.reaction.reference_id);
                let message2 = { ...message[0] };
                message2.reaction = item.content.reaction.emoji;

                copyChat3 = (copyChat3.length === 0) ?
                    copyChat2.filter((elem) => elem.id !== item.content.reaction.reference_id) :
                    copyChat3.filter((elem) => elem.id !== item.content.reaction.reference_id);

                copyChat3.push(message2);
            }
        })
    )
        .then(() => {
            let chat = (copyChat3.length > 0) ? copyChat3 : copyChat2;
            chat = orderMessages(chat);
            return chat;
        });
};

export const isActionAllowed = (permissions, action) => {
    try {
        const decryptedPermissions = decrypt(permissions);
        const isSuperUser = FuseUtils.isSuperUser();

        if (isSuperUser) {
            return true;
        }

        return decryptedPermissions.includes(action);
    } catch (error) {
        console.error(`Error in isActionAllowed: ${error}`);
        return false;
    }
}

export const isPreferredAction = (preferences, action) => {
    return preferences[action] !== undefined ? preferences[action] : true;
}

export const customDateFormat = (text) => {
    const [datePart, timePart] = text.split(' ');
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = String(now.getMonth() + 1).padStart(2, '0');
    const currentDay = String(now.getDate()).padStart(2, '0');

    const formattedCurrentDate = `${currentYear}${currentMonth}${currentDay}`;

    if (datePart.replace(/-/g, '') === formattedCurrentDate) {
        const [hours, minutes] = timePart.split(':');
        return `${hours}:${minutes}`;
    }

    const [year, month, day] = datePart.split('-').map(part => parseInt(part, 10));
    const messageDate = new Date(year, month - 1, day);
    const diffTime = now - messageDate;
    const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (totalDays === 1) {
        return 'Ayer';
    }

    if (totalDays < 7) {
        const weekDays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        return weekDays[messageDate.getDay()];
    }

    return `${String(day).padStart(2, '0')}-${String(month).padStart(2, '0')}-${year}`;
};

export const addHour = () => {
    const fechaNueva = new Date();
    const zona = fechaNueva.getTimezoneOffset();
    fechaNueva.setMinutes(fechaNueva.getMinutes() + zona);

    const ano = fechaNueva.getFullYear();
    let mes = fechaNueva.getMonth() + 1;
    let dia = fechaNueva.getDate();

    let hora = fechaNueva.getHours();
    let minuto = fechaNueva.getMinutes();
    let segundo = fechaNueva.getSeconds();

    if (mes < 10) {
        mes = `0${mes}`;
    }
    if (dia < 10) {
        dia = `0${dia}`;
    }
    if (hora < 10) {
        hora = `0${hora}`;
    }
    if (minuto < 10) {
        minuto = `0${minuto}`;
    }
    if (segundo < 10) {
        segundo = `0${segundo}`;
    }

    return `${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}Z`;
};

export const formatNumber = (nStr) => {
    nStr += '';
    const x = nStr.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? `.${x[1]}` : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
};

export const closeOpenTags = (truncatedMessage) => {
    const tagStack = [];
    const tagPattern = /<\/?([a-zA-Z]+)[^>]*>/g;
    let match;

    while ((match = tagPattern.exec(truncatedMessage)) !== null) {
        if (match[0][1] === '/') {
            tagStack.pop();
        } else {
            tagStack.push(match[1]);
        }
    }

    while (tagStack.length > 0) {
        truncatedMessage += `</${tagStack.pop()}>`;
    }

    return truncatedMessage;
}
import { http } from "./services";
import { listarRegistros } from "./commonServices";

export const obtenerListaUsuarios = async (params) => {
    const response = await listarRegistros({
        operacion: "ver_usuarios",
        ...params
    });
    const data = await response.data;
    return data;
}

export const consultarPermisos = async () => {
    const response = await http.post("/consultar_permisos");
    const data = await response.data;
    return data;
}

export const consultarUsuariosActivos = async () => {
    const response = await http.post("/statususuarios/consultar_usuarios_activos");
    const data = await response.data;
    return data;
}

export const consultarEstadosConversaciones = async () => {
    const response = await http.post("/statususuarios/consultar_estados_conversaciones");
    const data = await response.data;
    return data;
}

export const consultarUltimoEstado = async (params) => {
    const response = await http.post("/statususuarios/consultar_ultimo_estado", params);
    const data = await response.data;
    return data;
}

export const consultarResumenEstados = async (params) => {
    const response = await http.post("/statususuarios/consultar_resumen_estados", params);
    const data = await response.data;
    return data;
}

export const consultarEstadosUsuarios = async () => {
    const response = await http.get("/consultar_estados_usuarios");
    const data = await response.data;
    return data;
}

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Input from '@mui/material/Input';
import Icon from '@mui/material/Icon';
import { searchConversation } from './Utils/whatsappUtil';
import { useTranslation } from 'react-i18next';

const Searchbar = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '440px',
  minHeight: '45px',
}));

const SearchBar = () => {
  const { t } = useTranslation();

  const handleSearchText = (e) => {
    if (e.keyCode == 13) {
      const { value } = e.target;
      searchConversation(value);
    }
  };

  return (
    <Searchbar className="border-b-2">
      <Toolbar className="px-16" style={{ minHeight: 45 }}>
        <Paper className="flex items-center w-full px-8 shadow">
          <Input
            placeholder={t('fuseSearch.searchPlaceholder')}
            className="flex flex-1 px-8"
            disableUnderline
            fullWidth
            inputProps={{
              'aria-label': t('fuseSearch.searchPlaceholder'),
            }}
            onKeyDown={handleSearchText}
          />
          <Icon color="action">search</Icon>
        </Paper>
      </Toolbar>
    </Searchbar>
  );
};

export default SearchBar;

import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Icon from '@mui/material/Icon';

const IconStyled = styled(Icon)(({ theme }) => ({
    fontSize: 25
}));

const MessageTypeDeleted = () =>  {
    const { t } = useTranslation();
    
    return (
        <div className="flex items-center">
            <IconStyled color="primary">
                delete_forever
            </IconStyled>
            <p className="italic">{t('messageTypes.deletedMessage')}</p>
        </div>
    );
}

export default MessageTypeDeleted;

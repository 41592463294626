import _ from 'lodash';
import { convertUTCToTimeZone } from 'app/utils/dateUtils';
import { formatLastMessage } from '../../Utils/whatsappUtil';
import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ChannelParticipantsSummary from './ChannelParticipantsSummary';
import ConversationParticipantsContainer from '../../Utils/components/ConversationParticipantsContainer';
import DisplayName from '../../Utils/components/DisplayName';
import HtmlTooltip from '../../Utils/components/HtmlTooltip';
import jwtService from 'app/auth/services/jwtService';
import LastMessageTime from '../../Utils/components/LastMessageTime';
import LastMessageTimeToResponse from '../../Utils/components/LastMessageTimeToResponse';
import MessageStatus from '../MessageStatus';

const LastMessage = ({ msisdn, name, displayName, lastMessage, channels }) => {
    const { t } = useTranslation();
    const hasMessage = Boolean(lastMessage);
    const lastMessageTime = lastMessage?.createdDatetime;
    const lastMessageText = hasMessage ? formatLastMessage(lastMessage) : '';
    const lastMessageStatus = hasMessage ? <MessageStatus status={lastMessage.status} /> : null;

    const truncatedName = useMemo(() => _.truncate(name, { length: 18, omission: '...' }), [name]);
    const truncatedDisplayName = useMemo(() => _.truncate(displayName, { length: 18, omission: '...' }), [displayName]);

    const userSettings = useMemo(() => { return jwtService.getUserPreferences(); }, []);
    const clientLastMessageTime = useMemo(() => {
        if (!lastMessageTime) return null;

        const date = new Date(lastMessageTime);
        const formattedDatetime = date.toISOString().replace("T", " ").slice(0, -1) + "000";
        return convertUTCToTimeZone(formattedDatetime, userSettings?.diference_timezone);
    }, [lastMessageTime, userSettings?.diference_timezone]);

    // Truncar el texto del último mensaje si excede los 50 caracteres
    const truncatedLastMessageText = useMemo(() => {
        return lastMessageText.length > 50 ? _.truncate(lastMessageText, { length: 50, omission: '...' }) : lastMessageText;
    }, [lastMessageText]);

    return (
        <>
            <HtmlTooltip
                title={<ChannelParticipantsSummary channels={channels} />}
                placement="top"
            >
                <ConversationParticipantsContainer className="flex pl-7 pr-7 flex-col items-start justify-center border-r border-gray-900">
                    {channels.map(({ encargado: { color = '', NombreUsuario } = {} }, index) => (
                        <div
                            key={index}
                            className="max-w-full text-xs truncate"
                            style={{ color }}
                        >
                            {NombreUsuario?.length
                                ? NombreUsuario
                                : t('global.user.userNameNotAssigned')}
                        </div>
                    ))}
                </ConversationParticipantsContainer>
            </HtmlTooltip>

            <div className="flex w-full pl-7 flex-col items-center justify-center">
                <div className="flex w-full">
                    <Typography className="font-semibold text-12">{truncatedName}</Typography>
                </div>

                {!name && (
                    <div className="flex w-full">
                        <Typography className="font-medium text-11">
                            {msisdn}
                            <DisplayName>{!truncatedName && truncatedDisplayName}</DisplayName>
                        </Typography>
                    </div>
                )}

                <div className="flex w-full items-center">
                    {lastMessageStatus}
                    {typeof truncatedLastMessageText === "object" ? (
                        <Typography
                            className="pl-2 truncate text-11"
                            color="textSecondary"
                        >
                            {hasMessage ? truncatedLastMessageText : ''}
                        </Typography>
                    ) : (
                        <Typography
                            className="pl-2 truncate text-11"
                            color="textSecondary"
                            dangerouslySetInnerHTML={hasMessage ? { __html: truncatedLastMessageText } : undefined}
                        />
                    )}
                </div>
            </div>

            <div className="flex flex-col justify-center items-end">
                <div className="mb-8">
                    <LastMessageTime lastMessageTime={clientLastMessageTime} />
                </div>
                <div>
                    <LastMessageTimeToResponse lastMessageTime={clientLastMessageTime} />
                </div>
            </div>
        </>
    );
};

export default LastMessage;

import { selectSelectedChannelId } from "app/theme-layouts/shared-components/whatsappPanel/store/tabChannelSlice";
import { selectSelectedConversation } from "app/theme-layouts/shared-components/whatsappPanel/store/conversationsSlice";
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";

const AvatarStyled = styled(Avatar, {
    shouldForwardProp: (prop) => prop !== 'borderColor',
})(({ borderColor }) => ({
    width: 45,
    height: 45,
    border: borderColor ? `2px solid ${borderColor}` : 'none',
    borderRadius: 45,
}));

const InfoAgentConversation = () => {
	const { t } = useTranslation();
    const selectedConversation = useSelector(selectSelectedConversation);
    const selectedChannelId = useSelector(selectSelectedChannelId);

    const channel = selectedConversation?.channels?.find(channel => channel.id === selectedChannelId) || null;
    const responseTime = false;
    const borderColor = channel?.encargado?.color || null;

	return (
        <div className="flex justify-end w-full mr-4">
            <div className="flex mr-8 items-center">
                <AvatarStyled
                    src="/static/blank-profile.png"
                    alt={t('wsPanel.infoAgentConversation.manager')}
                    variant="square"
                    borderColor={borderColor}
                    />
            </div>

            <div>
                <Typography className="text-[9px] m-0">
                    {t('wsPanel.infoAgentConversation.department')}: {channel?.encargado?.NombreGrupo}
                </Typography>
                <Typography className="text-[9px] m-0">
                    {t('wsPanel.infoAgentConversation.manager')}: {channel?.encargado?.NombreUsuario || t('global.user.userNameNotAssigned')}
                </Typography>
                
                <Typography className="text-[9px] m-0">
                    {responseTime ? (
                        <>
                            <strong>{t('wsPanel.infoAgentConversation.averageGeneral')}:</strong><i> 00:00:01</i>
                        </>
                    ) : (
                        <>{t('wsPanel.infoAgentConversation.noResponseAverage')}</>
                    )}
                </Typography>
                <Typography className="text-[9px] m-0">
                    
                </Typography>

                {/* <Button variant="contained" size="small" className="text-[11px]">Tomar Conversacion</Button> */}
            </div>
        </div>
    );
};

export default InfoAgentConversation;

import ConversationFb from "./components/facebook/Conversation";
import ConversationIg from "./components/instagram/Conversation";
import ConversationWsp from "./components/whatsapp/Conversation";

const conversationComponentSelector = (type) => ({
    1: ConversationWsp,
    3: ConversationFb,
    5: ConversationIg
}[type] || (() => <>Unsupported</>));

const Conversation = ({ channelId, selectedConversation, isLoading }) => {
    const ConversationComponent = conversationComponentSelector(selectedConversation.tipo);

    return (
        <ConversationComponent
            conversation={selectedConversation}
            channelId={channelId}
            isLoading={isLoading}
        />
    );
};

export default Conversation;

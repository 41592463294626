import TemplateTypeGeneric from "./TemplateTypeGeneric";
import TemplateTypeMedia from "./TemplateTypeMedia";
import TemplateTypeProduct from "./TemplateTypeProduct";

function MessageTypeTemplate(props) {
    let content = null;
    
    const { template_type, elements, product } = props.payload;

    if (template_type === 'media') {
        content = <TemplateTypeMedia elements={elements} />;
    } else if (elements) {
        content = <TemplateTypeGeneric element={elements[0]} />;
    } else if (product) {
        content = <TemplateTypeProduct products={product.elements} conversationId={conversationId} />;
    }

    return <div>{content}</div>;
}

export default MessageTypeTemplate
import Icon from '@mui/material/Icon';
import { styled } from '@mui/material/styles';

const IconStyled = styled(Icon)(({ theme }) => ({
    fontSize: 50
}));

const MessageTypeContacts = ({ content }) => {
    const { contacts } = content;
    let name = '';
    let phoneNumber = '';

    contacts.forEach((item) => {
        name = item.name.formatted_name;

        item.phones.forEach((phone) => {
            phoneNumber = phone.phone;
        });
    });

    return (
        <div className="flex flex-col items-center justify-center">
            <IconStyled color="primary">
                contact_phone
            </IconStyled>
            <p>{name}</p>
            <p>{phoneNumber}</p>
        </div>
    );
}

export default MessageTypeContacts;

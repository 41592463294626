import { useEffect, useState } from 'react';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ShowFile } from './ShowFile';

function MessageTypeHsm(props) {
    const [contenthsm, setContenthsm] = useState([]);
    const [haveButtons, setHavebuttons] = useState(false);

    let itemObj = props.item;
    let components = (itemObj?.content?.hsm?.components) ? itemObj.content.hsm.components : null
    let components2 = [];
    let buttons = (itemObj?.content?.hsm?.buttons) ? itemObj.content.hsm.buttons : null;
    let urlImage = '';

    if (components !== null && typeof (components) === 'object') {
        Object.keys(components).map(function (objectKey, index) {
            var value = components[objectKey];
            components2.push(value);
        });
        components = components2;
    }

    useEffect(() => {
        let isMounted = true;
        if (components) {
            let componentsMap = components.map(async (item, i) => {
                let content;
                if (item !== null) {
                    let type = item.type;
                    let parameters = (item.parameters) ? item.parameters : null;
                    let text = (item.text) ? item.text : null;
                    let buttons = (item.buttons) ? item.buttons : null;

                    if (type.toLowerCase() === 'header' || type.toLowerCase() === 'body' || type.toLowerCase() === 'footer') {
                        if (parameters && parameters.length > 0) {
                            if (parameters[0].type === 'image') {
                                urlImage = (parameters[0].image.link) ? parameters[0].image.link : parameters[0].image.url;
                                content = (<img src={urlImage} style={{ maxWidth: 300 }} />)
                            }

                            if (parameters[0].type === 'video') {
                                let urlVideo = (parameters[0].video.link) ? parameters[0].video.link : parameters[0].video.url;
                                content = (<video src={urlVideo} style={{ display: 'block', width: '250px' }} controls>
                                    Your browser does not support the <code>video</code> element.
                                </video>);
                            }

                            if (parameters[0].type === 'text') {
                                content = (<p className="my-5">{parameters[0].text}</p>)
                            }

                            if (parameters[0].type === 'file' || parameters[0].type === 'document') {
                                let copyObj = { ...itemObj }
                                copyObj.content = {
                                    file: {}
                                };
                                copyObj.content.file = {
                                    url: parameters[0].file.url
                                }
                                content = (<ShowFile item={copyObj} />)
                            }
                        } else {
                            if (type.toLowerCase() === 'header') {
                                content = (<p className="my-5 font-bold">{text}</p>)
                            }

                            if (type.toLowerCase() === 'body') {
                                content = (<p className="my-5">{text}</p>)
                            }

                            if (type.toLowerCase() === 'footer') {
                                content = (<p className="text-xs" style={{ color: '#777' }}>{text}</p>)
                            }

                            if (type.toLowerCase() === 'buttons') {
                                buttons.map((btn) => {
                                    if (btn.type.toLowerCase() === 'url') {
                                        let title = (<Icon>launch</Icon>)

                                        if (btn.text) {
                                            title = (title + btn.text)
                                        }

                                        if (btn.label) {
                                            let title = (btn.label)
                                        }

                                        let content = (
                                            <a href={btn.url} target="_blank"
                                                style=
                                                {{
                                                    width: 'CALC(100% - 1px)',
                                                    background: '#dcf8c6',
                                                    border: 'none',
                                                    padding: 5,
                                                    color: '#007bff',
                                                    borderRadius: 5,
                                                    float: left
                                                }}>
                                                {titulo}
                                            </a>
                                        )
                                    }

                                    if (btn.type.toLowerCase() === 'quick_reply') {
                                        let content = (
                                            <a href={btn.url} target="_blank"
                                                style=
                                                {{
                                                    width: 'CALC(100% - 1px)',
                                                    background: '#dcf8c6',
                                                    border: 'none',
                                                    padding: 5,
                                                    color: '#007bff',
                                                    borderRadius: 5,
                                                    float: left
                                                }}>
                                                {btn.text}
                                            </a>
                                        )
                                    }
                                });
                            }

                        }
                    }

                    if (type.toLowerCase() === 'buttons') {
                        setHavebuttons(true);
                    }
                    content = (<div className="w-full" key={i}>{content}</div>);
                    return content;
                }

            });
            componentsMap = Promise.all(componentsMap)
                .then((data) => {
                    if (isMounted) {
                        setContenthsm([...contenthsm, data]);
                    }
                })
        }

        return () => isMounted = false;
    }, [])


    return (
        <>
            <div className="flex flex-col w-full">
                {(contenthsm) && (contenthsm.map((elem) => elem))}

                {!components && (
                    <p>{itemObj?.content?.hsm || 'Mensaje no soportado'}</p>

                )}

                {urlImage.length > 0 && (
                    <div className="flex justify-center items-stretch w-full" style={{ background: haveButtons && '#dcf8c6' }}>
                        <div className="flex w-full justify-start">
                            <Tooltip title="Descargar Archivo">
                                <IconButton
                                    // onClick={handleDownloadImage}
                                    size="small">
                                    <Icon>cloud_download</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div className="flex w-full justify-end">
                            <Tooltip title="Ver en tamaño mayor">
                                <IconButton

                                    size="small">
                                    <Icon>zoom_in</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                )}

                {buttons && buttons.length == 2 && (
                    <>
                        <button
                            style={{ width: 'CALC(50% - 1px)', background: '#dcf8c6', border: 'none', padding: '5px', color: '#007bff', borderRadius: '5px', float: 'left' }}
                            type="button">
                            {buttons[0].title}
                        </button>

                        <button
                            style={{ width: 'CALC(50% - 1px)', background: '#dcf8c6', border: 'none', padding: '5px', color: '#007bff', borderRadius: '5px', float: 'left' }}
                            type="button">
                            {buttons[1].title}
                        </button>
                    </>
                )}
            </div>
        </>
    )
}

export default MessageTypeHsm
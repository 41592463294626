import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import jwtService from '../auth/services/jwtService';
import { showMessage } from 'app/store/fuse/messageSlice';

export const setUser = createAsyncThunk('user/setUser', async (user, { dispatch, getState }) => {
    /*
      You can redirect the logged-in user to a specific route depending on his role
      */
    if (user.loginRedirectUrl) {
        settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
    }

    return user;
});

export const updateUserSettings = createAsyncThunk(
    'user/updateSettings',
    async (settings, { dispatch, getState }) => {
        const { user } = getState();
        const newUser = _.merge({}, user, { data: { settings } });
        console.log('updateUserSettings', newUser);
        dispatch(updateUserData(newUser));

        return newUser;
    });

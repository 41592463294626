import _ from "@lodash";
import { useCallback } from "react";
import { Avatar, Typography, IconButton } from "@mui/material";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { selectSelectedChannelId } from "../../store/tabChannelSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ItemActionConversation from "../ItemActionConversation";
import { showMessage } from "app/store/fuse/messageSlice";
import AvatarWs from "./AvatarWs";


const InfoConversationWsp = ({ conversation }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { channels, msisdn: selectedContactNumber = null } = conversation;

    const selectedChannelId = useSelector(selectSelectedChannelId);
    let selectedChannelState = channels.find(channel => channel.id === selectedChannelId)?.estado || null;

    if (!selectedChannelState) {
        if(channels[0]){
            selectedChannelState = channels[0]?.estado
        }
    }

    const actionsConversation = [
        {
            title: t('wsPanel.infoConversation.actions.addContact'),
            icon: 'person_add_icon',
            handleClickAction: () => {
                console.log(t('wsPanel.infoConversation.actions.addContact'))
            },
        },
        {
            title: t('wsPanel.infoConversation.actions.viewSharedFiles'),
            icon: "sync_alt",
            handleClickAction: () => {
                console.log(t('wsPanel.infoConversation.actions.viewSharedFiles'));
            },
        },
        {
            title: t('wsPanel.infoConversation.actions.viewLinkedTickets'),
            icon: "receipt",
            handleClickAction: () => {
                console.log(t('wsPanel.infoConversation.actions.viewLinkedTickets'));
            },
        },
        {
            title: t('wsPanel.infoConversation.actions.createTicket'),
            icon: "add_circle",
            handleClickAction: () => {
                console.log(t('wsPanel.infoConversation.actions.createTicket'));
            },
        },
        {
            title: t('wsPanel.infoConversation.actions.assignResolution'),
            icon: "note",
            handleClickAction: () => {
                console.log(t('wsPanel.infoConversation.actions.assignResolution'));
            },
        },
        {
            title: t('wsPanel.infoConversation.actions.endConversation'),
            icon: "done",
            handleClickAction: () => {
                console.log(t('wsPanel.infoConversation.actions.endConversation'));
            },
        },
    ];

    const handleCopyToClipboard = useCallback(() => {
        const digitsOnly = selectedContactNumber.replace(/\D/g, '');
        navigator.clipboard.writeText(digitsOnly);
        dispatch(
            showMessage({
                message: t('wsPanel.infoConversation.conversation.copiedToClipboard'),
                autoHideDuration: 3000,
            })
        );
    }, [dispatch, selectedContactNumber, t]);

    return (
        <div className="flex flex-col items-left w-full my-8" tabIndex={0}>
            <div className="flex">
                <div className="flex items-center">
                    <AvatarWs alt={conversation && conversation.Name} />
                </div>
                <div className="flex flex-col mx-8">
                    {conversation && (
                        <div className="flex">
                            <Typography
                                title={conversation.Name || conversation.displayName}
                                color="inherit"
                                className="text-14 font-semibold px-4">
                                {_.truncate(conversation.Name || conversation.displayName, { length: 33, omission: '...' })}
                            </Typography>
                        </div>
                    )}
                    <div className="w-full flex items-center">
                        <Typography className="text-[12px]">+{selectedContactNumber}</Typography>
                        <IconButton
                            size="small"
                            onClick={handleCopyToClipboard}
                            className="ml-2"
                        >
                            <ContentCopyIcon
                                fontSize="small"
                                style={{ fontSize: 12 }}
                            />
                        </IconButton>
                    </div>
                    <div className="flex items-center">
                        <Typography className="ml-4 text-[12px] text-sky-600">
                            {selectedChannelState
                                ? `${t(
                                    'wsPanel.infoConversation.conversation.title'
                                )} ${selectedChannelState.descripcion}`
                                : t(
                                    'wsPanel.infoConversation.conversation.selectAChannel'
                                )}
                        </Typography>
                    </div>
                </div>
                <div className="flex items-center ml-16">
                    {actionsConversation.map((action, index) => {
                        return (
                            <ItemActionConversation
                                key={index}
                                title={action.title}
                                icon={action.icon}
                                handleClickAction={action.handleClickAction}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default InfoConversationWsp;
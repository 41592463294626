import { createSlice } from '@reduxjs/toolkit';

export const sendSocketData = (payload) => (dispatch, getState) => {
    const isSocketConnected = socketStatus(getState());

    const data = {
        _base: 'bck', // _base por defecto
        ...payload,
    };

    if (isSocketConnected) {
        dispatch({ type: 'socket/send', payload: data });
    } else {
        console.error('Socked is not connected');
    }
};

const socketSlice = createSlice({
    name: 'socket',
    initialState: false,
    reducers: {
        setConnected: (state, action) => action.payload,
    },
    extraReducers: {},
});

export const { setConnected } = socketSlice.actions;

// Selector to get the socket status
export const socketStatus = (state) => state.socket;

export default socketSlice.reducer;

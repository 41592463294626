import { http } from "../services";

export const wsConsultBeforeTransferringAgent = async (params) => {
    const response = await http.post("ws/consultarantesdetransferiroperador", params);
    const data = await response.data;
    return data;
}

export const wsTransferAgentConversation = async (params) => {
    const response = await http.post("ws/transferirconversacionoperador", params);
    const data = await response.data;
    return data;
}

export const fbConsultBeforeTransferringAgent = async (params) => {
    const response = await http.post("fb/consultarantesdetransferiroperador", params);
    const data = await response.data;
    return data;
}

export const fbTransferAgentConversation = async (params) => {
    const response = await http.post("fb/transferirconversacionoperador", params);
    const data = await response.data;
    return data;
}

export const igConsultBeforeTransferringAgent = async (params) => {
    const response = await http.post("ig/consultarantesdetransferiroperador", params);
    const data = await response.data;
    return data;
}

export const igTransferAgentConversation = async (params) => {
    const response = await http.post("ig/transferirconversacionoperador", params);
    const data = await response.data;
    return data;
}
import { useState, useRef } from 'react';
import { Icon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { motion } from 'framer-motion';

// File type configurations
const FILE_TYPES = {
    AUDIO: {
        extensions: ['.aac', '.amr', '.mp3', '.m4a', '.ogg'],
        mimeTypes: [
            'audio/aac',
            'audio/amr',
            'audio/mpeg',
            'audio/mp4',
            'audio/ogg',
        ],
        maxSize: 16 * 1024 * 1024, // 16MB
    },
    DOCUMENT: {
        extensions: [
            '.txt',
            '.xls',
            '.xlsx',
            '.doc',
            '.docx',
            '.ppt',
            '.pptx',
            '.pdf',
        ],
        mimeTypes: [
            'text/plain',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/pdf',
        ],
        maxSize: 100 * 1024 * 1024, // 100MB
    },
    IMAGE: {
        extensions: ['.jpeg', '.jpg', '.png'],
        mimeTypes: ['image/jpeg', 'image/png'],
        maxSize: 5 * 1024 * 1024, // 5MB
    },
    STICKER: {
        extensions: ['.webp'],
        mimeTypes: ['image/webp'],
        maxSize: 500 * 1024, // 500KB for animated, 100KB for static
    },
    VIDEO: {
        extensions: ['.3gp', '.mp4'],
        mimeTypes: ['video/3gp', 'video/mp4'],
        maxSize: 16 * 1024 * 1024, // 16MB
    },
};

const FileUploader = ({ disabled, isDragActive, onClose, onFileSelect, t }) => {
    const dispatch = useDispatch();

    const [selectedFile, setSelectedFile] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const fileInputRef = useRef(null);

    const validateFile = (file) => {
        // Find the category of the file
        let fileCategory = null;
        let isValid = false;

        for (const [category, config] of Object.entries(FILE_TYPES)) {
            if (config.mimeTypes.includes(file.type)) {
                fileCategory = category;
                isValid = file.size <= config.maxSize;
                break;
            }
        }

        if (!fileCategory) {
            dispatch(
                showMessage({
                    message: t('wsPanel.fileUpload.unsupportedFormat'),
                    variant: 'error',
                })
            );
            return false;
        }

        if (!isValid) {
            dispatch(
                showMessage({
                    message: t('wsPanel.fileUpload.fileTooLarge'),
                    variant: 'error',
                })
            );
            return false;
        }

        return true;
    };

    const handleFileSelect = (file) => {
        if (!file || !validateFile(file)) return;

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64Content = reader.result.split(',')[1];
            setSelectedFile({
                content: base64Content,
                type: file.type,
                name: file.name,
                size: file.size,
            });

            // Create and store preview URL if it's an image or video
            if (file.type.startsWith('image/') || file.type.startsWith('video/')) {
                const url = URL.createObjectURL(file);
                setPreviewUrl(url);
            }
        };

        reader.readAsDataURL(file);
    };

    // Cleanup function for preview URL
    const cleanupPreview = () => {
        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
            setPreviewUrl(null);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget === e.target) {
            setIsDragging(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        handleFileSelect(file);
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    const handleInputChange = (e) => {
        const file = e.target.files[0];
        handleFileSelect(file);
    };

    const handleSend = () => {
        if (selectedFile) {
            onFileSelect(selectedFile);
            setSelectedFile(null);
            cleanupPreview();
        }
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setSelectedFile(null);
        cleanupPreview();
        onClose();
    };

    const isActive = isDragging || isDragActive;

    return (
        <motion.div
            className="w-full h-full flex flex-col items-center justify-center bg-white relative"
            animate={{
                backgroundColor: isActive ? 'rgba(249, 207, 142, 0.26)' : 'rgb(255 255 255)',
            }}
            transition={{ duration: 0.2 }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleClick}
        >
            <div className="absolute top-4 left-4 z-10">
                <IconButton
                    onClick={handleClose}
                    size="large"
                    className="hover:bg-gray-100"
                >
                    <Icon className="text-gray-500">arrow_back</Icon>
                </IconButton>
            </div>

            <div className={`
                p-8 rounded-lg 
                ${isActive ? 'border-4 border-green-500' : 'border-2 border-gray-300'} 
                border-dashed
                ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
                transition-all duration-200
                flex flex-col items-center justify-center
                min-h-[200px]
                w-full max-w-2xl
                mx-auto
            `}>
                <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={handleInputChange}
                    accept={Object.values(FILE_TYPES)
                        .flatMap((type) => type.mimeTypes)
                        .join(',')}
                    disabled={disabled}
                />

                <Icon className={`text-6xl mb-4 ${isActive ? 'text-green-500' : 'text-gray-400'}`}>
                    cloud_upload
                </Icon>

                <span className={`text-lg ${isActive ? 'text-green-600' : 'text-gray-600'} text-center`}>
                    {isActive
                        ? t('wsPanel.fileUpload.dropHere')
                        : t('wsPanel.fileUpload.dragOrClick')}
                </span>

                <span className="text-sm text-gray-500 mt-2">
                    {t('wsPanel.fileUpload.supportedFormats')}
                </span>

                {selectedFile && (
                    <div className="mt-4 w-full max-w-md">
                        <div className="flex items-center justify-between bg-white border border-gray-200 p-3 rounded my-4 ml-4">
                            <span className="text-sm text-gray-600 truncate max-w-[350px] pl-2">
                                {selectedFile.name}
                            </span>
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedFile(null);
                                    cleanupPreview();
                                }}>
                                <Icon>close</Icon>
                            </IconButton>
                        </div>

                        {selectedFile.type.startsWith('image/') && previewUrl && (
                            <img
                                src={previewUrl}
                                alt="Preview"
                                className="mt-2 max-w-full h-auto rounded mx-auto"
                            />
                        )}

                        {selectedFile.type.startsWith('video/') && previewUrl && (
                            <video
                                src={previewUrl}
                                controls
                                className="mt-2 max-w-full rounded mx-auto"
                            />
                        )}
                    </div>
                )}
                
                {selectedFile && (
                    <div className="mt-10 flex gap-4 justify-center">
                        <Button
                            variant="contained"
                            color="success"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleSend();
                            }}
                            className="px-8"
                            disabled={disabled}>
                            {t('wsPanel.fileUpload.send')}
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            className="px-6">
                            {t('wsPanel.fileUpload.cancel')}
                        </Button>
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default FileUploader;
import { format } from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

/* Calcula el tiempo restante hasta que se cumplan 24 horas desde el último mensaje.
 * @param {string} lastMessageTime - La fecha y hora del último mensaje en formato 'YYYY-m-d H:i:s'.
 * @return {object} - Un objeto que contiene las horas, minutos y segundos restantes.
 */
export const calculateTimeLeft = (lastMessageTime) => {
    const messageTime = new Date(lastMessageTime);
    const currentTime = new Date();
    const difference = 24 * 60 * 60 * 1000 - (currentTime - messageTime);

    if (difference < 0) {
        return { hours: 0, minutes: 0, seconds: 0 };
    }

    const hours = Math.floor(difference / (60 * 60 * 1000));
    const minutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((difference % (60 * 1000)) / 1000);

    return { hours, minutes, seconds };
};

/**
 * Formatea una fecha y hora dada en el formato 'dd/MM/yyyy HH:mm:ss'.
 * @param {Date|string|number} dateTime - La fecha y hora a formatear.
 * @return {string} - La fecha y hora formateada.
 */
export const formatDateTimeMessage = (dateTime) => {
    return format(dateTime, 'dd/MM/yyyy HH:mm:ss', { addSuffix: true });
};

/**
 * Formatea una fecha en el formato "dd/MM/yyyy"
 * @param {Date} date - La fecha a formatear
 * @return {string} - La fecha formateada
 */
export const formatDate = (date) => {
    return format(date, 'dd/MM/yyyy');
};

/**
 * Formatea una fecha en el formato "dd/mm/yy" pero utilizando la librería dayjs"
 * @param {Date|string} date - La fecha a formatear
 * @return {string} - La fecha formateada
 */
export const formatDateToAPIFormat = (date) => {
    return dayjs(date).format('DD/MM/YYYY');
};

/**
 * Formatea una fecha pero utilizando la librería dayjs"
 * @param {Date|string} date 
 * @returns 
 */
export const formatDateOnlyWidthDayJs = (date) => {
    return dayjs(date);
}

export const formatDateTimeInTimeZone = (timeZone, date = new Date()) => {
    const options = {
        timeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        fractionalSecondDigits: 3,
        hour12: false
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const parts = formatter.formatToParts(date);
    const dateTimeParts = {
        year: '',
        month: '',
        day: '',
        hour: '',
        minute: '',
        second: '',
        fractionalSecond: ''
    };

    parts.forEach(({ type, value }) => {
        if (dateTimeParts.hasOwnProperty(type)) {
            dateTimeParts[type] = value;
        }
    });

    const formattedDate = `${dateTimeParts.year}-${dateTimeParts.month}-${dateTimeParts.day} ${dateTimeParts.hour}:${dateTimeParts.minute}:${dateTimeParts.second}.${dateTimeParts.fractionalSecond}`;

    return formattedDate;
}
 
export const adjustDateTimeByDifference = (dateTimeStr, differenceTimezone) => {
    const [datePart, timePart] = dateTimeStr.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hour, minute, secondPart] = timePart.split(':');
    const [second, millisecond] = secondPart.split('.').map(Number);

    const dateObj = new Date(Date.UTC(year, month - 1, day, hour, minute, second, millisecond));

    const sign = differenceTimezone[0];
    const [hours, minutes] = differenceTimezone.slice(1).split(':').map(Number);
    const totalMinutes = hours * 60 + minutes;

    if (sign === '-') {
        dateObj.setUTCMinutes(dateObj.getUTCMinutes() + totalMinutes);
    } else {
        dateObj.setUTCMinutes(dateObj.getUTCMinutes() - totalMinutes);
    }

    const adjustedDateTimeParts = {
        year: dateObj.getUTCFullYear().toString(),
        month: (dateObj.getUTCMonth() + 1).toString().padStart(2, '0'),
        day: dateObj.getUTCDate().toString().padStart(2, '0'),
        hour: dateObj.getUTCHours().toString().padStart(2, '0'),
        minute: dateObj.getUTCMinutes().toString().padStart(2, '0'),
        second: dateObj.getUTCSeconds().toString().padStart(2, '0'),
        fractionalSecond: dateObj.getUTCMilliseconds().toString().padStart(3, '0')
    };

    const adjustedFormattedDate = `${adjustedDateTimeParts.year}-${adjustedDateTimeParts.month}-${adjustedDateTimeParts.day} ${adjustedDateTimeParts.hour}:${adjustedDateTimeParts.minute}:${adjustedDateTimeParts.second}.${adjustedDateTimeParts.fractionalSecond}`;

    return adjustedFormattedDate;
};

/**
 * Subtracts the local time from the given UTC time.
 * @param {string} utcTime - The UTC time in the format 'YYYY-MM-DD HH:mm:ss'.
 * @return {string} - The local time in the format 'YYYY-MM-DD HH:mm:ss'.
 */
export const subtractTime = (utcTime) => {
    if (utcTime == null) {
        console.log('null date');
        console.trace();
        return '';
    }
    if (utcTime.indexOf('T') > -1) {
        utcTime = utcTime.split('T');
    } else {
        utcTime = utcTime.split(' ');
    }

    let datePart = utcTime[0].split('-');
    let timePart = utcTime[1].replace('Z', '').split(':');
    let newDate = new Date(Number(datePart[0]), Number(datePart[1]) - 1, Number(datePart[2]), Number(timePart[0]), Number(timePart[1]), Number(timePart[2]));

    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());

    let year = newDate.getFullYear();
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();

    let hour = newDate.getHours();
    let minute = newDate.getMinutes();
    let second = newDate.getSeconds();

    if (month < 10) { month = '0' + month; }
    if (day < 10) { day = '0' + day; }
    if (hour < 10) { hour = '0' + hour; }
    if (minute < 10) { minute = '0' + minute; }
    if (second < 10) { second = '0' + second; }

    newDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

    return newDate;
};

/**
 * Calculates the time difference from the given UTC time to the current local time.
 * @param {string} utcTime - The UTC time in the format 'YYYY-MM-DD HH:mm:ss'.
 * @return {string} - The time difference in the format 'HH:mm:ss'.
 */
export const calculateStateTime = (utcTime) => {
    const now = new Date();

    let localTime = subtractTime(utcTime);
    let datePart = localTime.split(' ')[0].split('-');
    let timePart = localTime.split(' ')[1].split(':');
    let startDate = new Date(datePart[0], datePart[1] - 1, datePart[2], timePart[0], timePart[1], timePart[2]);

    let secNum = (now - startDate) / 1000;
    let days = Math.floor(secNum / (3600 * 24));
    let hours = Math.floor((secNum - (days * (3600 * 24))) / 3600);
    let minutes = Math.floor((secNum - (days * (3600 * 24)) - (hours * 3600)) / 60);
    let seconds = Math.floor(secNum - (days * (3600 * 24)) - (hours * 3600) - (minutes * 60));

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }

    return `${hours}:${minutes}:${seconds}`;
};

export const convertToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
};

export const convertToHMS = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

 
/**
 * Converts a given UTC time to a specified time zone offset.
 *
 * @param {string} utcTime - The UTC time to be converted, in ISO 8601 format.
 * @param {string} offset - The time zone offset in the format "HH:MM".
 * @returns {string} The converted time in the format "YYYY-MM-DD HH:MM:SS.SSSSSS".
 */
export const convertUTCToTimeZone = (utcTime, offset) => {
    const localDayjs = dayjs
        .extend(utc)
        .extend(timezone);

    const [datePart, timePartWithMs] = utcTime.split(' ');
    const [timePart, msWithOffset] = timePartWithMs.split('.');
    const milliseconds = msWithOffset.slice(0, 6);

    const [year, month, day] = datePart.split('-').map(Number);
    const [hour, minute, second] = timePart.split(':').map(Number);
    let date = new Date(Date.UTC(year, month - 1, day, hour, minute, second, milliseconds / 1000));

    // Automatically determine America/Asuncion offset if none is provided
    let adjustedOffset = offset;
    if (!offset) {
        const asuncionDate = localDayjs.utc(date).tz('America/Asuncion');
        const hoursOffset = String(asuncionDate.utcOffset() / 60).padStart(2, '0');
        adjustedOffset = `${asuncionDate.utcOffset() < 0 ? '-' : '+'}${hoursOffset}:00`;

        // Adjust to Asuncion time
        date = new Date(asuncionDate.format()); 
    } else {
        const [hoursOffset, minutesOffset] = offset.split(':').map(Number);
        const totalOffsetMinutes = hoursOffset * 60 + (hoursOffset < 0 ? -minutesOffset : minutesOffset);
        date.setUTCMinutes(date.getUTCMinutes() + totalOffsetMinutes);
    }

    // Format the adjusted date
    const adjustedFormattedDate = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')} ${String(date.getUTCHours()).padStart(2, '0')}:${String(date.getUTCMinutes()).padStart(2, '0')}:${String(date.getUTCSeconds()).padStart(2, '0')}.${milliseconds} ${adjustedOffset}`;

    return adjustedFormattedDate;
};
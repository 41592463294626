import { http } from "./services";

export const obtenerMensajesConversacion = async (params) => {
    const response = await http.post("/mensajesconversacion", params);
    const data = await response.data;
    return data;
}

export const obtenerConversaciones = async (params) => {
    const response = await http.post("/consultarconversaciones", params);
    const data = await response.data;
    return data;
}


export const enviarMensaje = async (params) => {
    const response = await http.post("/enviarmensaje", params);
    const data = await response.data;
    return data;
}


export const buscarConversaciones = async (params) => {
    const response = await http.post("/buscarconversaciones", params);
    const data = await response.data;
    return data;
}

export const obtenerEstadosConversacionResumen = async (url)=>{
    const response = await http.post(url);
    const data = await response.data;
    return data;
}

export const fetchResolutionsServices = async (params) => {
    const response = await http.post("/ws/consultar_resoluciones", params);
    const data = await response.data;
    return data;
}


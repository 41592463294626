import React, { useState } from "react";
import {
    MenuItem,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import { fetchAssignedResolutionsServices } from "app/services/conversationActionsServices/showAssignedResolutionsServices";
import MessageTypeResolution from "../MessageType/MessageTypeResolution";

const ShowAssignedResolutions = ({ t, selectedConversation, selectedChannelId, handleMenuHeaderChatClose }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [assignedResolutions, setAssignedResolutions] = useState([]);
    const dispatch = useDispatch();

    const handleAssignedResolutionsClick = async () => {
        setLoading(true);
        console.log("Modal intentando abrir...");
        try {
            const params = {
                idConversacion: selectedConversation.id,
                idCanal: selectedChannelId,
            };

            const result = await fetchAssignedResolutionsServices(params);
            // handleMenuHeaderChatClose();  // Intenta comentar esta línea por el momento

            if (result && result.length) {
                console.log("Asignaciones encontradas, abriendo modal.");
                setAssignedResolutions(result);
                setOpen(true);
            } else {
                dispatch(showMessage({
                    message: t('wsPanel.menuConversation.errorToShowAssignedResolutions'),
                    variant: 'error',
                }));
            }
        } catch (error) {
            console.error('Error fetching assigned resolutions:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        console.log("Modal cerrado");
        setOpen(false);
    };

    return (
        <>
            <MenuItem onClick={handleAssignedResolutionsClick}>
                {loading ? (
                    <>
                        {t('wsPanel.menuConversation.showAssignedResolutions')}
                        <CircularProgress size={24} />
                    </>
                ) : (
                    t('wsPanel.menuConversation.showAssignedResolutions')
                )}
            </MenuItem>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>{t('Resolución de la Conversación')}</DialogTitle>
                <DialogContent>
                    {assignedResolutions.length ? (
                        assignedResolutions.map((resolution, index) => (
                            <MessageTypeResolution  key={index} resolution={resolution} />
                        ))
                    ) : (
                        <Typography>{t('wsPanel.menuConversation.noAssignedResolutions')}</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {`cerrar`}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ShowAssignedResolutions;

import { useEffect, useState } from 'react';
import MessageTypeError from 'app/whatsapp-panel/components/MessageType/MessageTypeError';

const UNKNOWN_FILE_TYPE = 'Formato de archivo no reconocido.';

const MessageTypeFile = ({ item }) => {
    const [strFile, setStrFile] = useState(null);
    const [strCaption, setStrCaption] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const renderAudio = (url, mimeType) => (
            <audio controls preload="metadata" style={{ width: '100%', maxWidth: '300px' }}>
                <source src={url} type={mimeType || 'audio/mpeg'} />
                Tu navegador no soporta elementos de audio.
            </audio>
        );

        const renderPDF = (url) => (
            <embed src={url} type="application/pdf" style={{ width: '100%', height: '300px' }} />
        );

        const renderImage = (url, altText = 'Imagen') => (
            <img src={url} alt={altText} style={{ width: '100%', maxWidth: '300px' }} />
        );

        const renderOfficeDocument = (url, extension) => {
            const officeTypes = ['xls', 'doc', 'ppt'];
            if (officeTypes.includes(extension)) {
                return (
                    <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
                        style={{ width: '100%', height: '400px', border: 'none' }}
                        title={`${extension.toUpperCase()} Document`}
                    ></iframe>
                );
            }
            return null;
        };

        const renderTIFF = async (url) => {
            try {
                const TiffModule = await import('tiff');
                const Tiff = TiffModule.default;

                const response = await fetch(url);
                if (!response.ok) throw new Error('Error al cargar el archivo TIFF.');

                const arrayBuffer = await response.arrayBuffer();
                const tiff = new Tiff({ buffer: arrayBuffer });
                const canvas = tiff.toCanvas();

                if (canvas && isMounted) {
                    setStrFile(
                        <img
                            src={canvas.toDataURL()}
                            alt="TIFF Image"
                            style={{ width: '100%', maxWidth: '300px' }}
                        />
                    );
                } else {
                    throw new Error('No se pudo crear el canvas para TIFF.');
                }
            } catch (error) {
                console.error('Error al procesar TIFF:', error);
                if (isMounted) {
                    setStrFile(
                        <MessageTypeError error={{ message: 'Error al cargar la imagen TIFF.' }} />
                    );
                }
            }
        };

        const show = async () => {
            const { type, payload } = item;
            const url = payload.url;
            const caption = payload.caption || null;

            let extension = type;

            if (extension) {
                let renderedFile = null;

                switch (extension) {
                    case 'audio':
                        renderedFile = renderAudio(url, payload['Content-Type']);
                        break;
                    case 'pdf':
                        renderedFile = renderPDF(url);
                        break;
                    case 'image':
                        renderedFile = renderImage(url, payload.altText || 'Imagen');
                        break;
                    case 'xls':
                    case 'doc':
                    case 'ppt':
                        renderedFile = renderOfficeDocument(url, extension);
                        break;
                    case 'tiff':
                        await renderTIFF(url);
                        break;
                    default:
                        renderedFile = <MessageTypeError error={{ message: UNKNOWN_FILE_TYPE }} />;
                        break;
                }

                if (renderedFile && isMounted) {
                    setStrFile(renderedFile);
                }

                if (caption && isMounted) {
                    setStrCaption(<p>{caption}</p>);
                }
            } else {
                setStrFile(<MessageTypeError error={{ message: UNKNOWN_FILE_TYPE }} />);
            }
        };

        show();

        return () => {
            isMounted = false;
        };
    }, [item]);

    return (
        <>
            {strFile}
            {strCaption}
        </>
    );
};

export default MessageTypeFile;
import { styled } from "@mui/material/styles";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
	height: 20,
	marginRight: 10,
	width: 20,
}));

const ItemActionConversation = ({ title, icon, handleClickAction }) => {
	return (
        <Tooltip title={title}>
            <IconButtonStyled size="small" onClick={handleClickAction}>
                <Icon>{icon}</Icon>
            </IconButtonStyled>
        </Tooltip>
    );
};
 
export default ItemActionConversation;

import { filterConversationByUserPreferences } from './Utils/socialMediaUtil';
import { getConversations, isActionAllowed } from './Utils/shared/utils';
import { selectConversationList, updateConversationList } from './store/conversationsSlice';
import { updateUserPreferences, selectUserPreferences, selectUser, setUser} from 'app/store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useMemo, useCallback } from 'react';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';

const FilterBar = () => {
    const dispatch = useDispatch();
    const conversations = useSelector(selectConversationList);
    const preferencias = useSelector(selectUserPreferences);
    const user = useSelector(selectUser);
    const permissions = user.permissions;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const preferencesConfig = useMemo(() => [
        {
            platform: 'whatsapp',
            displayName: 'WhatsApp',
            permissionsKey: 'whatsapp',
            preferences: [
                { key: 'whatsapp', label: 'WhatsApp' },
            ],
            typeInfo: { type: 1, limit: 10 },
        },
        {
            platform: 'facebook',
            displayName: 'Facebook',
            permissionsKey: 'facebook',
            preferences: [
                { key: 'facebook', label: 'Messenger' },
                { key: 'facebookPosts', label: 'Publicaciones' },
                { key: 'facebookNotifications', label: 'Notificaciones' },
                { key: 'facebookComments', label: 'Comentarios' },
            ],
            typeInfo: { type: 3, limit: 5 },
        },
        {
            platform: 'instagram',
            displayName: 'Instagram',
            permissionsKey: 'instagram',
            preferences: [
                { key: 'instagram', label: 'Mensajes Directos' },
                { key: 'instagramPosts', label: 'Publicaciones' },
                { key: 'instagramNotifications', label: 'Notificaciones' },
                { key: 'instagramComments', label: 'Comentarios' },
            ],
            typeInfo: { type: 5, limit: 5 },
        },
    ], []);

    // Handle preferences change
    const handleChange = useCallback((name, event) => {
        const isChecked = event.target.checked;

        const newPreferencias = {
            ...preferencias,
            [name]: isChecked,
        };

        const newUser = {
            ...user,
            data: {
                ...user.data,
                preferences: newPreferencias,
            },
        };

        dispatch(setUser(newUser));

        dispatch(updateUserPreferences({
            preferences: newPreferencias,
            displayNotification: false,
        }));

        const preferenceType = preferencesConfig.find(
            (config) => config.preferences.some(pref => pref.key === name)
        );

        if (preferenceType && preferenceType.typeInfo) {
            if (isChecked) {
                dispatch(getConversations({
                    offset: '0',
                    limit: String(preferenceType.typeInfo.limit),
                    tipo: preferenceType.typeInfo.type
                }));
            } else {
                const conversationsFiltered = filterConversationByUserPreferences(
                    conversations,
                    newPreferencias
                );

                dispatch(updateConversationList(conversationsFiltered));
            }
        }
    }, [dispatch, preferencias, user, preferencesConfig, conversations]);

    // Dinamically render preferences
    const renderedSections = useMemo(() => {
        return preferencesConfig.map((config) => {
            const { platform, displayName, permissionsKey, preferences } = config;

            if (!isActionAllowed(permissions, permissionsKey)) {
                return null;
            }

            return (
                <div key={platform}>
                    <ListSubheader>{displayName}</ListSubheader>
                    {preferences.map(({ key, label }) => (
                        <MenuItem key={key}>
                            <Switch
                                checked={
                                    preferencias[key] !== undefined
                                        ? preferencias[key]
                                        : true
                                }
                                onChange={(event) => handleChange(key, event)}
                            />
                            {label}
                        </MenuItem>
                    ))}
                </div>
            );
        });
    }, [preferencesConfig, permissions, preferencias, handleChange]);

    return (
        <div>
            <IconButton onClick={handleMenuOpen} size="large">
                <Icon>filter_list</Icon>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {renderedSections}
            </Menu>
        </div>
    );
};

export default FilterBar;

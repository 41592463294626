import { addMessagesConversation as addMessagesConversationFb } from 'app/theme-layouts/shared-components/whatsappPanel/Utils/facebookUtil';
import { addMessagesConversation as addMessagesConversationIg } from 'app/theme-layouts/shared-components/whatsappPanel/Utils/instagramUtil';
import { consultarPermisos } from 'app/services/userServices';
import { isActionAllowed, isPreferredAction } from 'app/theme-layouts/shared-components/whatsappPanel/Utils/shared/utils';
import { newMessage as newMessageFb } from 'app/theme-layouts/shared-components/whatsappPanel/Utils/facebookUtil.js';
import { newMessage as newMessageIg } from 'app/theme-layouts/shared-components/whatsappPanel/Utils/instagramUtil';
import { newMessage as newMessageWs, updateLastMessage } from 'app/theme-layouts/shared-components/whatsappPanel/Utils/whatsappUtil';
import { selectConversationList, updateConversationList, updateEstadosConversacion, updateLastMessageByIdConversation } from 'app/theme-layouts/shared-components/whatsappPanel/store/conversationsSlice';
import { selectUser, selectUserPreferences } from 'app/store/userSlice';
import { setConnected } from './Store/socketSlice';
import { updateUser } from 'app/store/userSlice';
import { updateUserLastStates, updateUserResumeStates, updateUsersActives } from 'app/store/usersStatesSlice';
import jwtService from '../jwtService';

export const socketMiddleware = (socket) => (params) => (next) => (action) => {
    const { dispatch, getState } = params;
    const { type, payload } = action;

    const state = getState();

    switch (type) {
        case 'socket/connect':
            let hash = state?.user?.hash;
            let socketUrl = `${process.env.REACT_APP_WS}` + hash;

            socket.connect(socketUrl);

            socket.on('open', () => {
                console.log('Conectado');
                dispatch(setConnected(true));
            });

            socket.on('message', (e) => {
                let data = JSON.parse(e.data);
                const { mensaje, operacion, datos, status } = data;

                if (mensaje) {
                    if (!status && mensaje == 'No se reconoce el id de sesion') {
                        console.log(mensaje);
                    }

                    if (
                        !mensaje.items ||
                        !mensaje.enviados ||
                        !mensaje.trans_grupo ||
                        mensaje === 'sign_off' ||
                        mensaje === 'sign_out'
                    ) {
                        const signOffMessage = mensaje === 'sign_off'
                            ? 'Un supervisor cerro tu sesión'
                            : 'Sesion iniciada en otro dispositivo';

                        if (mensaje === 'sign_off' || mensaje === 'sign_out') {
                            jwtService.logout(signOffMessage);
                            socket.disconnect();
                            return;
                        }
                    }

                    const state = getState();
                    const user = selectUser(state);
                    const preferences = selectUserPreferences(state);

                    // Handle new messages based on user permissions and preferences
                    const handleNewMessage = (permissionKey, actionFn) => {
                        const isAllowed = isActionAllowed(user.permissions, permissionKey);
                        const isPreferred = isPreferredAction(preferences, permissionKey);

                        if (isAllowed && isPreferred) {
                            dispatch(actionFn(mensaje));
                        }
                    };

                    // Handle for each type of message
                    switch (mensaje.tipo) {
                        case 1: // WhatsApp
                            handleNewMessage('whatsapp', newMessageWs);
                            break;

                        case 2: // Instachat
                            break;

                        case 3: // Facebook
                            handleNewMessage('facebook', newMessageFb);
                            break;

                        case 4: // Webchat
                            break;

                        case 5: // Instagram
                            handleNewMessage('instagram', newMessageIg);
                            break;
                        default:
                            break;
                    }

                    // Reload permissions
                    if (mensaje == 'reload_permissions') {
                        const reloadPermissions = async () => {
                            const response = await consultarPermisos();
                            const { permissions } = response;

                            // Update permissions in jwtService and Redux store
                            jwtService.updateUserPermissions(permissions);
                            await dispatch(updateUser({ permissions })).unwrap();
                        };

                        reloadPermissions();
                    }
                }

                if (operacion) {
                    switch (operacion) {
                        case 'mensajesconversacion':
                            if (datos?.length > 0) {
                                const messageObj = datos[0];
                                const conversations = selectConversationList(getState());

                                if (conversations) {
                                    dispatch(updateLastMessageByIdConversation(
                                        messageObj.conversationId,
                                        messageObj
                                    ));
                                }
                            }
                            break;

                        case 'wsConsultarEstadosConversacionesResumen':
                            if (datos) {
                                dispatch(updateEstadosConversacion({
                                    nombreRedSocial: 'whatsapp',
                                    estados: datos,
                                }));
                            }
                            break;

                        case 'fbConsultarEstadosConversacionesResumen':
                            if (datos) {
                                dispatch(updateEstadosConversacion({
                                    nombreRedSocial: 'facebook',
                                    estados: datos,
                                }));
                            }
                            break;

                        case 'igConsultarEstadosConversacionesResumen':
                            if (datos) {
                                dispatch(updateEstadosConversacion({
                                    nombreRedSocial: 'instagram',
                                    estados: datos,
                                }));
                            }
                            break;
                        case 'consultarResumenEstados':
                            dispatch(updateUserResumeStates(data));
                            break;
                        case 'consultarUltimosEstados':
                            dispatch(updateUserLastStates(data));
                            break;
                        case 'consultarEncargadoConversacion':
                            if (data.datos) {
                                let referencia = [
                                    data.idConversacion,
                                    data.idCanal,
                                ];
                                whatsapp.whatsapp_mostrar_encargado_conversacion(
                                    data.datos,
                                    referencia,
                                    data.enviados,
                                    data.carpeta,
                                    data.saludar,
                                    data.verificar
                                );
                            }
                            break;

                        /**
                         * case 'contarmensajesnoleidos':
                         *   if (data.conteo > 0) {
                         *     $(`a[href="#conversacion-id-${data.idConversacion}"]`)
                         *       .find('.no-leidos')
                         *       .html(data.conteo)
                         *       .css('display', 'block');
                         *   }
                         *   break;
                         */

                        /**
                         * case 'verificarAgenda':
                         *     if (data.datos) {
                         *         mostarAgenda(data.datos);
                         *     }
                         *     break;
                         */

                        /**
                         * case 'consultarResumenTicketsEstados':
                         *     break;
                         */

                        /**
                         * case 'wsSaldoRestante':
                         *     if (data.datos) {
                         *         $('.ws-saldo-warning').remove();
                         *         $.each(data.datos, function (index, result) {
                         *             if (result.porcentaje) {
                         *                 let clase =
                         *                     result.porcentaje < 6
                         *                         ? 'danger'
                         *                         : 'warning';
                         *                 let color =
                         *                     result.porcentaje < 6
                         *                         ? '#dd4b39'
                         *                         : '#f39c12';
                         *                 let texto = `
                         *   <div class="ws-saldo-warning" style="border:1px solid ${color};color: ${color};">
                         *       <span style="float:left;width:100%;line-height:14px;font-size:12px;"><b>¡Atención!</b></span>
                         *       <span style="float:left;width:100%;line-height:20px;font-size:12px;">${result.billetera} ${result.porcentaje}%</span>
                         *   </div>`;
                         *
                         *                 $('.main-footer').append(texto);
                         *             }
                         *         });
                         *     }
                         *
                         *     break;
                         */

                        /**
                         *
                         * case 'consultarResumenEstados':
                         *     if (typeof mostar_resumen_estados == 'function') {
                         *         mostar_resumen_estados(data);
                         *     }
                         *     break;
                         *
                         * case 'consultarSaldo':
                         *     if ($('#index-billeteras').length > 0) {
                         *         $.each(data.datos, function (index, result) {
                         *             $(`#billetera-id-${result.id} .saldo`)
                         *                 .css({
                         *                     background: result.background,
                         *                     color: result.color,
                         *                 })
                         *                 .html(
                         *                     formatNumber.new(
                         *                         Number(result.saldo).toFixed(2),
                         *                         '$'
                         *                     )
                         *                 );
                         *         });
                         *     }
                         *     break;
                         *
                         * case 'consultarPlantillaImagen':
                         *     whatsapp.imageTemplate = data.datos.ws_templates;
                         *     whatsapp.ws_new_connection_channels =
                         *         data.datos.ws_new_connection_channels;
                         *     whatsapp.ws_channels_with_products =
                         *         data.datos.ws_channels_with_products;
                         *     break;
                         *
                         * case 'buscarMensaje':
                         *     whatsapp.whatsapp_mostrar_mensajes_encontrados(
                         *         data.datos
                         *     );
                         *     break;
                         *
                         * case 'consultarClienteConversacion':
                         *     if (data.datos) {
                         *         whatsapp.mostrar_cliente_conversacion(
                         *             data.datos
                         *         );
                         *     }
                         *     break;
                         *
                         * case 'consultarEncargadoConversacion':
                         *     if (data.datos) {
                         *         let referencia = [
                         *             data.idConversacion,
                         *             data.idCanal,
                         *         ];
                         *         whatsapp.whatsapp_mostrar_encargado_conversacion(
                         *             data.datos,
                         *             referencia,
                         *             data.enviados,
                         *             data.carpeta,
                         *             data.saludar,
                         *             data.verificar
                         *         );
                         *     }
                         *     break;
                         *
                         * case 'consultarEtiquetaConversacion':
                         *     if (data.datos) {
                         *         let referencia = [
                         *             data.idConversacion,
                         *             data.idCanal,
                         *         ];
                         *         whatsapp.whatsapp_mostrar_etiqueta_conversacion(
                         *             data.datos,
                         *             referencia
                         *         );
                         *     }
                         *     break;
                         *
                         * case 'wsPromedioRespuesta':
                         *     if (data.datos) {
                         *         let referencia = [
                         *             data.idConversacion,
                         *             data.idCanal,
                         *         ];
                         *         whatsapp.whatsapp_mostrar_promedio_respuesta(
                         *             data.datos,
                         *             referencia
                         *         );
                         *     }
                         *     break;
                         *
                         * case 'ticket_previo':
                         *     if (data.datos && data.datos > 0) {
                         *         mostrar_bandera_ticket_previo(
                         *             data._id,
                         *             data.id
                         *         );
                         *     }
                         *     break;
                         */

                        default:
                            break;
                    }
                }

                if (data.usuarios_activos) {
                    dispatch(updateUsersActives(data));
                }
            });

            socket.on('close', () => {
                // dispatch(setConnected(false));
            });
            break;

        case 'socket/disconnect':
            dispatch(setConnected(false));
            console.log('Desconectado');
            socket.disconnect();
            break;

        case 'socket/send':
            socket.send(payload);
            break;

        default:
            break;
    }

    return next(action);
};
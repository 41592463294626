export const getColorByLevel = (nivel) => {
    const colors = [
        'bg-gray-50',
        'bg-yellow-50',
        'bg-orange-50',
        'bg-blue-50',
        'bg-green-50',
        'bg-indigo-50',
        'bg-red-50',  
        'bg-teal-50',
        'bg-pink-50',
        'bg-purple-50',
    ];

    return colors[nivel % colors.length]; 
};

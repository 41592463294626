import { lazy } from 'react';

const SettingsApp = lazy(() => import('./SettingsApp'));

const SettingsAppProfile = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/settings',
      element: <SettingsApp />,
    },
  ],
};

export default SettingsAppProfile;

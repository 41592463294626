import Icon from '@mui/material/Icon';

function MessageTypeDeleted(props){

    return(
        <div className="flex items-center">
        <Icon sx={{ fontSize: "25px" }} color="primary">delete_forever</Icon>
        <p className="italic">Este mensaje fue eliminado</p>
        </div>
    )
}

export default MessageTypeDeleted
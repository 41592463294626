import { Fragment } from 'react';
import MessageTypeAudio from './TypeAudio';
// import MessageTypeDeleted from './TypeDeleted';
// import MessageTypeDocument from './TypeDocument';
import MessageTypeFallback from './TypeFallback';
import MessageTypeImage from './TypeImage';
import MessageTypeLocation from './TypeLocation';
import MessageTypeReaction from './TypeReaction';
// import MessageTypeSticker from './TypeSticker';
import MessageTypeTemplate from './TypeTemplate';
import MessageTypeText from './TypeText';
import MessageTypeVideo from './TypeVideo';

function MessageType(props) {
    const item = props.item;
    let type = item && item.text ? "text" : "unsupported";

    if (item && item.attachments) {
        item.attachments.forEach((attachments, index) => {
            type = attachments.type;
        })
    }

    const content = (item) ? item.text : props.content;
    const context = (item && item.context) ? item.context : null;

    if (props.type == 'reaction') {
        return (
            <MessageTypeReaction content={content} />
        )
    }

    if (item?.text || type === "unsupported" || type === "text") {
        return (
            <>
                <MessageTypeText text={content} type={type} context={context} />
            </>
        )
    }

    return (
        <>
            {item.attachments.map((attachment, index) => {
                let key = `${item.id}-${index}`

                if (attachment.type == "audio") {
                    key = `${item.id}-${index}-${attachment.file_url}`;
                } else if (attachment.type === "fallback") {
                    key = `${item.id}-${index}-${attachment.payload.url}`;
                } else if (attachment.type === "image") {
                    key = `${item.id}-${index}-${attachment.image_data}`;
                } else if (attachment.type === "location") {
                    key = `${item.id}-${index}-${attachment.location.latitude}-${attachment.location.longitude}`;
                } else if (attachment.type === "template") {
                    key = `${item.id}-${index}-${attachment.payload.template_type}`;
                } else if (attachment.type === "video") {
                    key = `${item.id}-${index}-${attachment.video.url}`;
                }

                return (
                    <Fragment key={key}>
                        {(attachment.type === "audio" || attachment.file_url) && (
                            <MessageTypeAudio key={key} item={item} />
                        )}
                        {/* {attachment.type === "deleted" && (
                            <MessageTypeDeleted />
                        )} */}
                        {/* {attachment.type === "document" && (
                            <MessageTypeDocument item={item} />
                        )} */}
                        {attachment.type === "fallback" && (
                            <MessageTypeFallback key={key} content={attachment} />
                        )}
                        {(attachment.type === "image" || attachment.image_data) && (
                            <MessageTypeImage key={key} content={attachment} />
                        )}
                        {attachment.type === "location" && (
                            <MessageTypeLocation key={key} content={content} />
                        )}
                        {/* {type === "reaction" && (
                            <MessageTypeReaction content={content} />
                        )} */}
                        {/* {(attachment.type === "sticker" || type === "whatsappSticker") && (
                            <MessageTypeSticker content={content} type={type} />
                        )} */}
                        {attachment.type === "template" && (
                            <MessageTypeTemplate key={key} payload={attachment.payload} />
                        )}
                        {(attachment.type === "video" || attachment.video_data) && (
                            <MessageTypeVideo key={key} content={content} />
                        )}
                    </Fragment>
                )
            })}
        </>
    )
}

export default MessageType;

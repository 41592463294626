import FuseLoading from '@fuse/core/FuseLoading';
import MessageRow from '../MessageRow';

const ConversationFb = ({ conversation, isLoading, ...others }) => {
    const filteredChat = [];
    const reactionMessages = [];
    const foundIds = [];

    conversation.messages.forEach((message) => {
        if (!foundIds.includes(message.id)) {
            if (message.type === 'reaction') {
                reactionMessages.push(message);
            } else {
                filteredChat.push(message);
            }
			
            foundIds.push(message.id);
        }
    });

    // Crear un mapa de emojis por reference_id, tomando siempre el último mensaje
    const emojiMap = reactionMessages.reduce((acc, reaction) => {
        const { reference_id, emoji } = reaction.content.reaction;
        acc[reference_id] = emoji || null;
        return acc;
    }, {});

    filteredChat.sort(
        (a, b) => new Date(a.createdDatetime) - new Date(b.createdDatetime)
    );

    return (
        <div className="flex flex-col pt-10 ltr:pl-10 rtl:pr-10 pb-10">
            {!isLoading ? (
                filteredChat.map((message) => (
                    <MessageRow
                        key={message.id}
                        message={message}
                        type={conversation.tipo}
                        emoji={emojiMap[message.id] || null}
                    />
                ))
            ) : (
                <FuseLoading />
            )}
        </div>
    );
};

export default ConversationFb;

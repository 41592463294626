import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useTranslation } from 'react-i18next';
import { setConnectionStatus } from 'app/store/connectionSlice';
import { sendMessageWhitFails } from 'app/theme-layouts/shared-components/whatsappPanel/store/chatSlice';

const FuseConnectionNotifier = ({ children }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const showNotification = (message, variant) => {
        dispatch(
            showMessage({
                message: message,
                autoHideDuration: 6000,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                variant: variant,
            })
        );
    };

    useEffect(() => {
        const handleOnline = () => {
            showNotification(t('global.messageOnline'), 'success');
            dispatch({ type: 'socket/connect' });
            dispatch(setConnectionStatus(true));
            dispatch(sendMessageWhitFails());
        };

        const handleOffline = () => {
            showNotification(
                t('global.messageOffline'),
                'warning'
            );
            dispatch({ type: 'socket/disconnect' });
            dispatch(setConnectionStatus(false));
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, [dispatch]);

    return children;
};

export default FuseConnectionNotifier;

const ChannelParticipantsSummary = ({ channels }) => (
    <>
        {channels.map((channel, index) => (
            <div
                className={index === channels.length - 1 ? 'mt-5' : 'mt-0'}
                key={index}
            >
                <strong>Canal:</strong> <span>{channel.id}</span> |{' '}
                <strong>Departamento:</strong>{' '}
                <span>{channel.encargado.NombreGrupo}</span> |{' '}
                <strong>Usuario:</strong>{' '}
                <span>{channel.encargado.NombreUsuario}</span>
            </div>
        ))}
    </>
);

export default ChannelParticipantsSummary;
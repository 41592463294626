import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'socialMediaPanel/cart',
  initialState: [],
  reducers: {
    openSocialMediaCart: (state,action) => {
        return state = [action.payload];
    },
    closeSocialMediaCart: (state,action) => []
  },
  extraReducers: {},
});

export const { openSocialMediaCart, closeSocialMediaCart } = cartSlice.actions;

export default cartSlice.reducer;

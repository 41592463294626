/**
 * Builds a hierarchy tree from a flat list of items
 * @param {Array} items - The flat list of items to build the hierarchy from
 * @returns {Array} The root items of the hierarchy tree
 */
export const buildHierarchy = (items) => {
    let map = {};
    let tree = [];

    // Create new objects instead of modifying existing ones
    const newItems = items.map((item) => ({
        ...item,
        children: [],
        nivel: Math.floor(item.jerarquia.length / 2),
    }));

    newItems.forEach((item) => {
        map[item.jerarquia] = item;
    });

    // Build the tree structure
    newItems.forEach((item) => {
        if (item.jerarquia.length > 2) {
            let parentJerarquia = item.jerarquia.slice(0, -2);
            if (map[parentJerarquia]) {
                map[parentJerarquia].children.push(item);
            }
        } else {
            tree.push(item);
        }
    });

    return tree;
};

/**
 * Sorts resources by hierarchy
 * @param {Array} resources - The resources to sort
 * @returns {Array} The sorted resources
 */
export const sortResources = (resources) => {
    const resourceMap = {};
    const sortedResources = [];

    // Create shallow copies of resources
    const newResources = resources.map((resource) => ({ ...resource }));

    newResources.forEach((resource) => {
        resourceMap[resource.jerarquia] = resource;
    });

    // Recursive function to sort children
    const sortChildren = (resource) => {
        if (resource.children && resource.children.length > 0) {
            resource.children.sort((a, b) =>
                a.jerarquia.localeCompare(b.jerarquia)
            );
            resource.children.forEach((child) => sortChildren(child));
        }
    };

    newResources.forEach((resource) => {
        if (resource.jerarquia.length === 2) {
            sortedResources.push(resource);
        }
    });

    // Sort main resources and their children
    sortedResources.sort((a, b) => a.jerarquia.localeCompare(b.jerarquia));
    sortedResources.forEach((resource) => sortChildren(resource));

    return sortedResources;
};

import _ from '@lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setInitialSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import history from '@history';
import jwtService from '../auth/services/jwtService';
import settingsConfig from 'app/configs/settingsConfig';

// Slice para los estados de usuarios
const usersStatesSlice = createSlice({
    name: 'usersStates',
    initialState: {
        userResumeStates: [],
        userLastStates: [],
        usersActives: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setUsersStates.fulfilled, (state, action) => {
            state.userResumeStates = action.payload.userResumeStates || [];
            state.userLastStates = action.payload.userLastStates || [];
            state.usersActives = action.payload.usersActives || [];
        });
    }
});

export const setUsersStates = createAsyncThunk(
    'usersStates/setUsersStates',
    async (userStates, { dispatch, getState }) => {
        return userStates;
    }
);

export const updateUserResumeStates = (data) => async (dispatch, getState) => {
    const usersStates = getState().usersStates;
    const { id, datos, tipo } = data;

    const userResumeStates = usersStates.userResumeStates || {};

    const newUserResumeStates = {
        ...userResumeStates,
        [id]: {
            ...userResumeStates[id],
            resumeStatesWhatsapp: userResumeStates[id]?.resumeStatesWhatsapp || {},
            resumeStatesFacebook: userResumeStates[id]?.resumeStatesFacebook || {},
            resumeStatesInstagram: userResumeStates[id]?.resumeStatesInstagram || {}
        }
    };

    const tiposDescripciones = {
        1: "resumeStatesWhatsapp",
        3: "resumeStatesFacebook",
        5: "resumeStatesInstagram"
    };

    if (tiposDescripciones[tipo]) {
        newUserResumeStates[id][tiposDescripciones[tipo]] = datos;
    } else {
        console.error(`Tipo no válido: ${tipo}`);
    }

    const newUser = {
        ...usersStates,
        userResumeStates: newUserResumeStates,
    };

    dispatch(setUsersStates(newUser));
    return newUser;
};

export const updateUserLastStates = (data) => async (dispatch, getState) => {
    const { usersStates } = getState();
    const { id, datos } = data;

    const newUserLastStates = {
        ...usersStates.userLastStates,
    };

    if (!newUserLastStates[id]) {
        newUserLastStates[id] = {};
    }

    newUserLastStates[id] = datos.datos;

    const newUser = {
        ...usersStates,
        userLastStates: newUserLastStates,
    };

    dispatch(setUsersStates(newUser));
    return newUser;
};

export const updateUsersActives = (data) => async (dispatch, getState) => {
    const { usersStates } = getState();
    const newUsersActives = [...usersStates.usersActives];

    try {
        if (data?.mensaje?.indexOf('Conectado como ') > -1) {
            const quienSeConecto = data.mensaje.replace('Conectado como ', '');
            // @todo Implementar mostrar mensaje de conexión
            // dispatch(showConnectionMessage(`ws connected as ${quienSeConecto}`));

        } else if (data?.mensaje?.indexOf('ha dejado la sala') > -1) {
            const id = data.mensaje.split('|')[1];

            // @todo Implementar actualizar el estado para marcar el usuario como desconectado 
            // dispatch(setUserOffline(id));

        } else if (data?.mensaje?.indexOf('se ha unido') > -1) {
            const datos = data.mensaje.split('|');

            //@todo Implementar actualizar el estado para marcar el usuario como conectado
            // dispatch(setUserOnline(datos[2]));

        }

        if (data?.usuarios_activos?.length) {
            dispatch(setUsersStates({ ...usersStates, usersActives: data.usuarios_activos.filter((user) => user.admin !== 1) }));
        }
    } catch (error) {
        console
    }
};


export const selectUserResumeStates = ({ usersStates }) => usersStates.userResumeStates;
export const selectUserLastStates = ({ usersStates }) => usersStates.userLastStates;
export const selectUsersActives = ({ usersStates }) => usersStates.usersActives;

export default usersStatesSlice.reducer;
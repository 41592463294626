function MessageTypeVideo(props){
    let videoUrl = props.content.video.url;

    return(
        <>
        <div className="flex">
        <video src={videoUrl} style={{display:"block",width:"250px"}} controls>
            Your browser does not support the <code>video</code> element.</video>
        </div>
        </>
    )
}

export default MessageTypeVideo
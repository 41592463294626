import { Component } from 'react';
import { connect } from 'react-redux';
import { decrypt } from 'app/utils/cryptoUtils';
import { matchRoutes } from 'react-router-dom';
import AppContext from 'app/AppContext';
import FuseUtils from '@fuse/utils';
import history from '@history';
import withRouter from '@fuse/core/withRouter';

let loginRedirectUrl = null;

class FuseAuthorization extends Component {
    constructor(props, context) {
        super(props);

        const { routes } = context;

        this.state = {
            accessGranted: true,
            routes,
        };

        this.defaultLoginRedirectUrl = props.loginRedirectUrl || '/';
    }

    componentDidMount() {
        if (!this.state.accessGranted) {
            this.redirectRoute();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.accessGranted !== this.state.accessGranted;
    }

    componentDidUpdate() {
        if (!this.state.accessGranted) {
            this.redirectRoute();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { location, permissions } = props;
        const { pathname } = location;

        let granted = false;
        let accessGranted = false;

        try {
            const decryptedPermissions = decrypt(permissions);
            const matchedRoutes = matchRoutes(state.routes, pathname);
            const matched = matchedRoutes ? matchedRoutes[0] : false;

            if (matched && matched.route && matched.route.settings) {
                const param = matched.route.settings.param !== undefined ? matched.route.settings.param : false;
                granted = FuseUtils.hasPermission(param, decryptedPermissions.map((p) => p));

                if (!granted) {
                    const lastUrlSegment = pathname.split('/').pop().split('?')[0];
                    granted = FuseUtils.hasPermission(lastUrlSegment, decryptedPermissions.map((p) => p));
                }
            }

            if (
                !_.isEmpty(decryptedPermissions) &&
                (pathname == '/dashboards/resources' || pathname == '/login')
            ) {
                granted = true;
            }

            if (_.isEmpty(decryptedPermissions) && pathname == '/login') {
                granted = true;
            }

            accessGranted = granted;
        } catch (error) {
            console.error('Error processing permissions:', error);
            accessGranted = false;
        }

        return {
            accessGranted,
        };
    }

    redirectRoute() {
        const { location, navigate, permissions } = this.props;
        const { pathname } = location;
        const redirectUrl = loginRedirectUrl || this.defaultLoginRedirectUrl;

        if (
            typeof permissions === 'undefined' ||
            (_.isEmpty(permissions) && FuseUtils.isSuperUser() === false)
        ) {
            setTimeout(() => history.push('/login'), 0);
            loginRedirectUrl = pathname;
        } else {
            setTimeout(() => history.push('/dashboards/resources'), 0);
            loginRedirectUrl = this.defaultLoginRedirectUrl;
        }
    }

    render() {
        // console.info('Fuse Authorization rendered', this.state.accessGranted);
        return this.state.accessGranted ? <>{this.props.children}</> : null;
    }
}

function mapStateToProps({ user }) {
    return {
        permissions: user ? user.permissions : [],
    };
}

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(FuseAuthorization));

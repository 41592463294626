import { memo, useEffect, useRef } from 'react';
import SocialMediaPanel from '../../shared-components/whatsappPanel/SocialMediaPanel';
import { selectUser } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import { socketStatus } from 'src/app/auth/services/socket/Store/socketSlice';

function RightSideLayout1(props) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isFirstLoading = useRef(false);
  const isSocketConnected = useSelector(socketStatus);

  useEffect(() => {
    if (user && isFirstLoading) {
      isFirstLoading.current=true;
      dispatch({ type: "socket/connect" });
    }
  }, []);

  if (!user) {
    return <FuseLoading />;
  }

  return (
    <SocialMediaPanel />
  );
}

export default memo(RightSideLayout1);

import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import { fbConsultBeforeTransferringAgent, fbTransferAgentConversation, igConsultBeforeTransferringAgent, igTransferAgentConversation, wsConsultBeforeTransferringAgent, wsTransferAgentConversation } from "app/services/conversationActionsServices/transferAgentActionServices";
import { sendMessage } from "../../store/chatSlice";
import { removeConversationItem, updateConversationItem } from "../../store/conversationsSlice";
import { Box } from "@mui/material";
import useConversationManager from "../../hooks/conversations/conversationActions/useConversationManager";
import { removeChannelFromConversation } from "../../Utils/whatsappUtil";
const TransferAgentAction = ({ t, setSubMenu, selectedConversation, selectedChannelId, handleMenuHeaderChatClose}) => {
    const dispatch = useDispatch();
    const [agents, setAgents] = useState([]);
    const [loading, setLoading] = useState(false);
    const { fetchWhatsappConversationManager } = useConversationManager();

    const createConsultBeforeTransferParams = (selectedConversation, selectedChannelId) => {
        const params = { tipo: selectedConversation.tipo };

        if (selectedConversation.tipo === 1) {
            params.idConversacion = selectedConversation.id;
            params.idCanal = selectedChannelId;
            params.numeroTelefonico = selectedConversation.msisdn;
        } else if ([3, 5].includes(selectedConversation.tipo)) {
            params.id = selectedConversation.id;
        }

        return params;
    };

    const createTransferParams = (selectedConversation, selectedChannelId, agent) => {
        const params = { tipo: selectedConversation.tipo, idUsuario: agent.idUsuario };

        if (selectedConversation.tipo === 1) {
            params.idConversacion = selectedConversation.id;
            params.idCanal = selectedChannelId;
        } else if ([3, 5].includes(selectedConversation.tipo)) {
            params.id = selectedConversation.id;
        }

        return params;
    };

    const fetchAgents = async (params) => {
        try {
            let result = false;
            switch (params.tipo) {
                case 1:
                    result = await wsConsultBeforeTransferringAgent(params);
                    break;
                case 3:
                    result = await fbConsultBeforeTransferringAgent(params);
                    break;
                case 5:
                    result = await igConsultBeforeTransferringAgent(params);
                    break;
            }
            return result;
        } catch (error) {
            console.error('Error fetching agents:', error);
            return [];
        }
    };

    const fetchTranferConversationToAgent = async (params) => {
        try {
            let result = false;
            switch (params.tipo) {
                case 1:
                    result = await wsTransferAgentConversation(params);
                    break;
                case 3:
                    result = await fbTransferAgentConversation(params);
                    break;
                case 5:
                    result = await igTransferAgentConversation(params);
                    break;
            }
            return result;
        } catch (error) {
            console.error('Error fetching agents:', error);
            return [];
        }
    };

    const createAgentMenuItems = (agents, handleAgentClick) => {

        return agents.map(agent => (
            <MenuItem
                key={agent.idUsuario}
                onClick={() => handleAgentClick(agent)}
                // sx={{
                //     border: `2px solid`,
                //     borderColor: agent.activo > 0 ? `#5cb85c` : `#d43f3a`,
                //     borderRadius: 1,  // Opcional, para bordes redondeados
                //     mb: 1  // Margen inferior entre elementos
                // }}
            >
                <Box
                    component="span"
                    sx={{
                        display: 'inline-block',
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        bgcolor: agent.activo > 0 ? `#5cb85c` : `#d43f3a`,
                        mr: 1
                    }}
                />
                {agent.NombreUsuario}
            </MenuItem>
        ));
    };

    const handleTransferAgent = async () => {
        setLoading(true);
        try {
            const params = createConsultBeforeTransferParams(selectedConversation, selectedChannelId);

            const agents = await fetchAgents(params);

            if (agents.usuarios.length){
                const agentMenuItems = createAgentMenuItems(agents?.usuarios ? agents?.usuarios : [], handleAgentClick);

                setSubMenu(agentMenuItems);
                setLoading(false);
            }else{
                setLoading(false);
                handleMenuHeaderChatClose();
                dispatch(showMessage({
                    message: 'No hay agentes disponibles para transferir la conversación',
                    variant: 'error',
                }));
            }
        } catch (error) {
            console.error('Error fetching agents:', error);
            setLoading(false);
            return [];
        }

    };

    const handleAgentClick = async (agent) => {

        try {
            const params = createTransferParams(selectedConversation, selectedChannelId, agent);

            const data = await fetchTranferConversationToAgent(params);

            handleMenuHeaderChatClose();
            if (data.status == true || data.status == 'success') {
                dispatch(showMessage({
                    message: data.mensaje,
                    variant: 'success',
                }));

                let datos = {};

                if (params.tipo ==1){
                    datos = {
                        operacion: 'enviarMensaje',
                        mensaje: {
                            tipo: params.tipo,
                            items: [],
                            enviados: [],
                            trans_grupo: [],
                            trans_usuario: [],
                            actualizados: []
                        },
                        sendTo: [params.idUsuario]
                    };

                    datos.mensaje.trans_grupo.push({...selectedConversation, buscado:false, operacion:'add'});

                    dispatch({ type: 'socket/send', payload: datos });

                    if (data.usuario_del) {
                        if (data.usuario_del.usuario_actual == data.usuario_del.usuario_conversacion) {
                            if (data.usuario_del.tiene_permiso == true) {
                                fetchWhatsappConversationManager(selectedConversation.id, selectedChannelId, false, true);
                            } else {
                                const channels = selectedConversation.channels.filter((channel) => channel.id !== selectedChannelId)

                                if (channels.length == 0) {
                                    dispatch(removeConversationItem(selectedConversation.id));
                                }

                                const conversation = selectedConversation

                                conversation.channels = channels;

                                dispatch(updateConversationItem(conversation));
                            }
                        } else {
                            if (params.idConversacion && params.idCanal) {
                                datos.mensaje.trans_usuario[0] = {
                                    idConversacion: params.idConversacion,
                                    idCanal: params.idCanal,
                                    operacion: 'delete'
                                };
                            }

                            datos.sendTo = [data.usuario_del.usuario_conversacion];
                            
                            dispatch({ type: 'socket/send', payload: datos });

                            fetchWhatsappConversationManager(selectedConversation.id, selectedChannelId, false, true);
                        }
                    }

                } else if([3, 5].includes(params?.tipo)){
                    datos = {
                        operacion: 'enviarMensaje',
                        mensaje : {
                            'tipo' : 3,
                            'items' : [],
                            'enviados' : [],
                            'trans_grupo' : [],
                            'trans_usuario' : [
                                {
                                    'idConversacion': params.id,
                                    'operacion': 'delete'
                                }
                            ],
                            'actualizados' : []
                        },
                        sendTo: [data.idUsuarioAnterior]
                    };
                    dispatch({ type: 'socket/send', payload: datos });

                    datos.mensaje.trans_usuario[0].operacion = 'add';
                    datos.sendTo = [params.idUsuario];

                    dispatch({ type: 'socket/send', payload: datos });
                }
            } else {
                if (params.tipo == 1) { 
                    if (data?.operacion == 'eliminar_conversacion_canal') {
                        removeChannelFromConversation({ idConversation: params.idConversacion, idChannel: params.idCanal });
                    }
                }

                return dispatch(showMessage({
                    message: data.mensaje,
                    variant: 'error',
                }));
            }
        } catch (error) {
            console.error('Error transferring to agent:', error);
            return [];
        }

    };

  return (
      <MenuItem onClick={handleTransferAgent}>
          {loading ? <> {t('wsPanel.menuConversation.transferToAnotherAgent')} <CircularProgress size={24} /></> :  t('wsPanel.menuConversation.transferToAnotherAgent')}
      </MenuItem>
  )
}

export default TransferAgentAction
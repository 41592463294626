import MessageRowWsp from './whatsapp/MessageRow';
import MessageRowFb from './facebook/MessageRow';
import MessageRowIg from './instagram/MessageRow';

const MessageRow = ({ message, type, emoji }) => {
	let MessageRow_ = () => <>Unsupported</>;

	if (type == 1) {
		MessageRow_ = MessageRowWsp;
	} else if (type == 3) {
		MessageRow_ = MessageRowFb;
	} else if (type == 5) {
		MessageRow_ = MessageRowIg;
	}
	
	return <MessageRow_ message={message} emoji={emoji} />;
};

export default MessageRow;

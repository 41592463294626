import { Avatar, ListItemButton, Tooltip, styled } from '@mui/material';
import { selectSocialMediaPanelState } from '../../store/stateSlice';
import { useSelector } from 'react-redux';
import LastMessage from './LastMessage';
import { memo } from 'react';
import AvatarWs from './AvatarWs';

const StyledListItem = styled(({ active, ...otherProps }) => (
    <ListItemButton {...otherProps} />
))(({ theme, active }) => ({
    backgroundColor: active
        ? theme.palette.whatsapp.background.paper
        : 'inherit',
    minHeight: '60px',
}));

const ConversationListItem = ({
    conversation: {
        msisdn = '',
        name = '',
        displayName = '',
        lastMessage = null,
        channels,
    } = {},
    active,
    ...others
}) => {
    const state = useSelector(selectSocialMediaPanelState);

    return (
        <StyledListItem className active={active} {...others}>
            <AvatarWs/>
            <LastMessage
                name={name}
                displayName={displayName}
                msisdn={msisdn}
                lastMessage={lastMessage}
                channels={channels}
            />
        </StyledListItem>
    );
};

export default memo(ConversationListItem);

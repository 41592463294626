import { Avatar, ListItemButton, Tooltip, styled } from '@mui/material';
import { selectSocialMediaPanelState } from '../../store/stateSlice';
import { useSelector } from 'react-redux';
import LastMessage from './LastMessage';
import { memo } from 'react';
import { border } from '@mui/system';
import AvatarIg from './AvatarIg';

const StyledListItem = styled(({ active, ...otherProps }) => (
    <ListItemButton {...otherProps} />
))(({ theme, active }) => ({
    backgroundColor: active
        ? theme.palette.whatsapp.background.paper
        : 'inherit',
    minHeight: '60px',

}));

const ConversationListItem = ({
    conversation: {
        username = '',
        displayName = '',
        lastMessage = null,
        id_fan_page = '',
        updatedDatetime= '',
        user_pic='',
        page_pic='',
        encargado=null,
        channels,
    } = {},
    active,
    ...others
}) => {
    const state = useSelector(selectSocialMediaPanelState);

    return (
        <StyledListItem active={active} {...others}>
            <AvatarIg page_pic={page_pic} user_pic={user_pic}/>
            <LastMessage
                name={username}
                displayName={displayName}
                lastMessage={lastMessage}
                encargados={[encargado]}
            />
        </StyledListItem>
    );
};

export default memo(ConversationListItem);

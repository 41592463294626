/**
 * Sort an array of objects by a specific property
 * @param {Array} array - The array to be sorted
 * @param {string} property - The property to sort by
 * @param {string} order - The order of sorting: 'asc' for ascending, 'desc' for descending
 * @returns {Array} - The sorted array
 */
export const sortByProperty = (array, property, order = 'asc') => {
    const compare = (a, b) => {
        if (a[property] < b[property]) return -1;
        if (a[property] > b[property]) return 1;
        return 0;
    };

    const sortedArray = array.sort(compare);
    return order === 'asc' ? sortedArray : sortedArray.reverse();
};

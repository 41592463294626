import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedChannelId, setSelectedChannelId } from '../../store/tabChannelSlice';
import { getMessages, selectChatByConversationId } from '../../store/chatSlice';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Conversation from '../../Conversation';
import ItemTabChannel from './../ItemTabChannel';
import { styled } from '@mui/styles';
import { updateOffsetChannel } from '../../Utils/whatsappUtil';

const GridContainer = styled('div')({
    display: 'grid',
    gridAutoFlow: 'row',
});

const OverflowHiddenDiv = styled('div')({
    overflow: 'hidden',
});

const TabListStyled = styled(TabList)({
    minHeight: 30,
});

const tabIndicatorStyle = {
    top: 0,
};

const backgroundConversation = {
    backgroundImage: `url('assets/images/backgrounds/bg_chat.png')`,
    backgroundColor: `rgb(249 207 142 / 26%)`,
};

export const TabsChannelsWsp = ({ conversation }) => {
    const dispatch = useDispatch();
    const currentSelectedChannelId = useSelector(selectSelectedChannelId);
    const offset = useRef(0);
    const chatTabScroll = useRef({});
    const canLoadMoreFromAPI = useRef(true);
    const [isLoading, setIsLoading] = useState(false);


    const [lastMessageTime, setLastMessageTime] = useState(null);

    const { id: selectedConversationId, channels = [], lastMessage } = conversation;

    // Inicialización de valueTab basado en los canales disponibles
    const [valueTab, setValueTab] = useState(() => {
        const channelActual = conversation.channels.find((item) => { return item.id === currentSelectedChannelId });

        if ((currentSelectedChannelId === '0' && channels.length > 0) || !channelActual) {
            return channels[0].id;
        }

        return currentSelectedChannelId;
    });

    const chat = useSelector(selectChatByConversationId);

    const determineSelectedChannelId = (currentSelectedChannelId, channels, valueTab) => {
        const countTotalChannels = channels.length;
        const haveChannels = countTotalChannels > 0;
        return ((haveChannels && countTotalChannels === 1) || currentSelectedChannelId === '0') ? channels[0].id : valueTab;
    };

    const selectedChannelId = determineSelectedChannelId(currentSelectedChannelId, channels, valueTab);

    const handleChangeTab = (e, channelId) => {
        setValueTab(channelId);
        dispatch(setSelectedChannelId(channelId));
        offset.current = 0;
    };

    const loadConversations = () => {
        offset.current = 0;
        canLoadMoreFromAPI.current = true;

        if (channels.length > 0 && selectedChannelId) {
            setIsLoading(true);
            fetchMessages(dispatch, selectedConversationId, selectedChannelId, chat, setLastMessageTime, lastMessage);
        }
    }

    const fetchMessages = async (
        dispatch,
        selectedConversationId,
        selectedChannelId,
        chat,
        setLastMessageTime,
        lastMessage
    ) => {
        if (!canLoadMoreFromAPI.current) {
            setIsLoading(false);
            return;
        }

        const lastMessageTime = lastMessage ? lastMessage.updatedDatetime : null;
        const offsetQuery = offset.current || 0;
        const limit = 15;

        const params = {
            tipo: 1,
            idConversacion: selectedConversationId,
            idCanal: selectedChannelId,
            limit,
            offset: offsetQuery,
            IdMensaje: '',
            reverse: false,
        };

        const data = {
            params,
            lastMessage: false,
            canLoadMoreFromAPI: canLoadMoreFromAPI.current,
        };

        setLastMessageTime(lastMessageTime);

        await dispatch(getMessages({ data })).then((data) => {
            if (data.payload?.length > 0 && data.payload?.length !== chat.length) {
                const channelSelectedMessages = data.payload.filter((item) => {
                    return (
                        item.channelId === selectedChannelId &&
                        item.conversationId === selectedConversationId
                    );
                });

                if (offset.current + 15 > channelSelectedMessages.length) {
                    canLoadMoreFromAPI.current = false;
                }

                offset.current = channelSelectedMessages.length;

                const updateData = {
                    conversationId: selectedConversationId,
                    channelId: selectedChannelId,
                    newOffset: channelSelectedMessages.length,
                };
                dispatch(updateOffsetChannel(updateData));
            }
        });

        setIsLoading(false);
        scrollToBottom();
    };

    const handleScroll = (e) => {
        if (isLoading) return;

        if (e.currentTarget.scrollTop === 0) {
            setIsLoading(true);
            fetchMessages(dispatch, selectedConversationId, selectedChannelId, chat, setLastMessageTime, lastMessage);
        }
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            if ((channels.length > 0) && selectedConversationId && channels.length > 0) {
                if (channels.length > 1) {
                    const element = chatTabScroll.current[valueTab];
                    if (element) {
                        element.scrollTop = element.scrollHeight;
                    }
                } else if (channels.length === 1) {
                    const ch1 = channels[0].id;
                    const element = chatTabScroll.current[ch1];
                    if (element) {
                        element.scrollTop = element.scrollHeight;
                    }
                }
            }
        }, 250);
    };

    useEffect(() => {
        let channelSelected = channels.find((channel) => { return channel.id == selectedChannelId });
        if (channelSelected) {
            if (channelSelected.messages.length < 15) {
                loadConversations();
            }

            dispatch(setSelectedChannelId(selectedChannelId));
        } else {
            setValueTab(channels[0].id);
            dispatch(setSelectedChannelId(channels[0].id));
            scrollToBottom();
        }
    }, [selectedChannelId, selectedConversationId]);

    useEffect(() => {
        let channelSelected = channels.find((channel) => { return channel.id == selectedChannelId });

        if (channelSelected) {
            if (channelSelected.messages.length < 15) {
                loadConversations();
            }

            dispatch(setSelectedChannelId(selectedChannelId));
        } else {
            setValueTab(channels[0].id);
            dispatch(setSelectedChannelId(channels[0].id));
            scrollToBottom();
        }
    }, []);

    return (
        <TabContext value={selectedChannelId}>
            <Box sx={{ borderBottom: 0 }}>
                <GridContainer>
                    <OverflowHiddenDiv>
                        <TabListStyled
                            onChange={handleChangeTab}
                            variant="scrollable"
                            scrollButtons="auto"
                            textColor="secondary"
                            indicatorColor="secondary"
                            TabIndicatorProps={{ sx: tabIndicatorStyle }}
                        >
                            {channels.map(({ id, phoneNumber }, i) => (
                                <ItemTabChannel key={id} tabIndex={i} channelName={phoneNumber} value={id} />
                            ))}
                        </TabListStyled>
                    </OverflowHiddenDiv>
                </GridContainer>
            </Box>

            {channels.map((channel, i) => (
                <TabPanel
                    value={channel.id}
                    ref={(ref) => (chatTabScroll.current[channel.id] = ref)}
                    offset={channel.id === selectedChannelId ? offset.current : 0}
                    onScroll={handleScroll}
                    key={selectedConversationId + channel.id}
                    className="overflow-y-auto overscroll-contain h-screen p-10"
                    style={backgroundConversation}
                >
                    <Conversation channelId={channel.id} selectedConversation={conversation} chat={chat} isLoading={isLoading} />
                </TabPanel>
            ))}
        </TabContext>
    );
};
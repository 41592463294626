import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { subtractHour } from "../Utils/socialMediaUtil";
import { closeSocialMediaPanel } from "./stateSlice";
import { orderConversations } from "../Utils/socialMediaUtil";
import { getConversations } from "../Utils/shared/utils";

export const getConversationsList = createAsyncThunk(
    "socialMediaPanel/conversation/getConversations",
    async ({ params }, { dispatch }) => {
        const updatedParams = { ...params, tipo: 1 };
        const response = await dispatch(getConversations(updatedParams));
        
        return response;
    }
);

export const updateLastMessageByIdConversation = (id, message) => (dispatch, getState) => {
    const lastMessage = { ...message };
    const conversationList = selectConversationList(getState());

    const conversationItem = conversationList.find(conv => conv.id === id);
    if (conversationItem) {
        const updatedConversation = {
            ...conversationItem,
            updatedDatetime: lastMessage.createdDatetime,
            lastMessage: {
                ...lastMessage,
                createdDatetime: subtractHour(lastMessage.createdDatetime)
            }
        };

        dispatch(updateConversationItem(updatedConversation));
    }
}

export const updateLastMessageByConversation = (conversation, message) => (dispatch) => {
    const updatedConversation = {
        ...conversation,
        updatedDatetime: message.createdDatetime,
        lastMessage: {
            ...message,
            createdDatetime: subtractHour(message.createdDatetime)
        }
    };

    dispatch(updateConversationItem(updatedConversation));
};

export const updateEstadosConversacion = (newEstado) => (dispatch, getState) => {
    const estadosConsolidados = { ...selectEstadosConversacion(getState()) };

    newEstado.estados.forEach((estadoNuevo) => {
        const { descripcion, conteo, color_text, id } = estadoNuevo;

        if (estadosConsolidados[descripcion]) {
            const estadoConsolidado = estadosConsolidados[descripcion];
            const detallesClonados = { ...estadoConsolidado.detalles };
            detallesClonados[newEstado.nombreRedSocial] = conteo;
            const totalRecalculado = Object.values(detallesClonados).reduce((acc, valor) => acc + valor, 0);

            estadosConsolidados[descripcion] = {
                ...estadoConsolidado,
                total: totalRecalculado,
                detalles: detallesClonados,
            };
        } else {
            estadosConsolidados[descripcion] = {
                total: conteo,
                detalles: { [newEstado.nombreRedSocial]: conteo },
                color: color_text,
                id: id,
            };
        }
    });

    dispatch(setSelectEstadosConversacion(estadosConsolidados));
};

const conversationsSlice = createSlice({
    name: "socialMediaPanel/conversation",
    initialState: {
        conversationList: [],
        selectedConversationId: null,
        selectedConversation: null,
        visibleConversationsOffset: 20,
        estadosConversacion: [],
        isFiltered: false,
        filteredBy: ''
    },
    reducers: {
        setSelectedConversationId: (state, action) => {
            state.selectedConversationId = action.payload;
        },
        setSelectedConversation: (state, action) => {
            state.selectedConversation = action.payload;
        },
        removeSelectedConversationId: (state, action) => {
            state.selectedConversationId = null;
        },
        addConversationItem: (state, action) => {
            let conversationState = [...state.conversationList];
            conversationState.push(action.payload);
            conversationState = orderConversations(conversationState);
            state.conversationList = conversationState;
        },
        updateConversationItem: (state, action) => {
            const id = action.payload.id;
            let conversationState = [...state.conversationList];
            const conversationIndex = conversationState.findIndex((item) => item.id === id);
            conversationState[conversationIndex] = action.payload;
            conversationState = orderConversations(conversationState);
            state.conversationList = conversationState;
        },
        removeConversationItem: (state, action) => {
            const id = action.payload.id;
            state.conversationList = state.conversationList.filter((item) => item.id !== id);
        },
        updateConversationList: (state, action) => {
            if (action.payload.length <= 300) {
                state.conversationList = orderConversations(action.payload);
            } else {
                state.conversationList = orderConversations(action.payload.slice(0, 300));
            }
        },
        setVisibleConversationsOffset: (state, action) => {
            state.visibleConversationsOffset = action.payload;
        },
        increaseVisibleConversationsOffset: (state, action) => {
            state.visibleConversationsOffset += action.payload;
        },
        setSelectEstadosConversacion: (state, action) => {
            state.estadosConversacion = action.payload;
        },
        setIsFiltered: (state, action) => {
            state.isFiltered = action.payload;
        },
        setFilteredBy: (state, action) => {
            state.filteredBy = action.payload;
        },
    },
    extraReducers: {
        [closeSocialMediaPanel]: (state, action) => {
            state.selectedConversationId = null;
        },
    },
});

export const {
    addConversationItem,
    increaseVisibleConversationsOffset,
    removeSelectedConversationId,
    setFilteredBy,
    setIsFiltered,
    setSelectedConversationId,
    setSelectEstadosConversacion,
    setVisibleConversationsOffset,
    updateConversationItem,
    removeConversationItem,
    updateConversationList,
} = conversationsSlice.actions;

export const selectSelectedConversationId = ({ socialMediaPanel }) => socialMediaPanel.conversation.selectedConversationId;

export const selectSelectedConversation = ({ socialMediaPanel }) => {
    const selectedConversationId = socialMediaPanel.conversation.selectedConversationId;
    const conversationList = socialMediaPanel.conversation.conversationList;
    const selectedConversation = conversationList.find((conversation) => conversation.id === selectedConversationId);
    return selectedConversation;
};

export const selectVisibleConversationList = ({ socialMediaPanel }) => {
    const { conversationList, visibleConversationsOffset } = socialMediaPanel.conversation;
    return conversationList.slice(0, visibleConversationsOffset);
};

export const selectVisibleConversationsOffset = ({ socialMediaPanel }) => socialMediaPanel.conversation.visibleConversationsOffset;

export const selectConversationList = ({ socialMediaPanel }) => socialMediaPanel.conversation.conversationList;

export const selectConversationListByType = ({ socialMediaPanel }, type) => {
    return socialMediaPanel.conversation.conversationList.filter((conversation) => conversation.tipo === type)
};

export const selectEstadosConversacion = ({ socialMediaPanel }) => socialMediaPanel.conversation.estadosConversacion;

export const selectIsFiltered = ({ socialMediaPanel }) => socialMediaPanel.conversation.isFiltered;

export const selectFilteredBy = ({ socialMediaPanel }) => socialMediaPanel.conversation.filteredBy;

export default conversationsSlice.reducer;
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mui/material/Icon';
import { formatNumber } from '../../../Utils/shared/utils';
import { openSocialMediaCart } from '../../../store/cartSlice';

const styleButtonCart = {
    width: '98%',
    padding: '10px',
    color: '#007bff',
    border: 'none',
    borderTop: '1px solid #9e9e9e',
    background: ' transparent',
    margin: ' 0 auto',
    display: 'block'
};

function MessageTypeOrder(props) {
    const dispatch = useDispatch();
    const cartPanel = useSelector(({ socialMediaPanel }) => socialMediaPanel.cart);

    let productItems = props.content.order.product_items;
    let countItems = productItems.length;
    let countText = (countItems > 1) ? 'elementos' : 'elemento';
    let src = productItems[0].url;
    let orderText = props.content.order.text;
    let total = (productItems.length > 0) ? Object.values(productItems).reduce((t, { item_price }) => t + item_price, 0) : '';
    let currency = (productItems.length > 0) ? productItems[0].currency : '';

    total = formatNumber(total);

    const openCartPanel = () => {
        console.log('se ejecuta');
        if (cartPanel.length === 0) {
            var data = {
                type: "order",
                items: productItems
            }
            dispatch(openSocialMediaCart({ data }))
        }
    };

    return (
        <>
            <div style={{ width: '300px', background: '#ffffff', display: 'flow-root' }}>
                <img src={src} style={{ width: '100px', float: 'left' }} />
                <div style={{ width: 'CALC(100% - 100px)', float: 'left', padding: '30px 15px 15px 15px' }}>
                    <p style={{ width: '100%', float: 'left' }}>
                        <Icon sx={{ fontSize: "25px" }} color="primary">shopping_cart</Icon>
                        {countItems} {countText}
                    </p>
                    <p style={{ width: '100%', float: 'left', color: '#b1afaf' }}>{currency} {total} (total estimado)</p>
                </div>
            </div>
            <div className="w-full">
                <p>{orderText}</p>
            </div>
            <div className="w-full">
                <button type="button" style={styleButtonCart} onClick={openCartPanel}>
                    Ver carrito enviado
                </button>
            </div>
        </>
    )
}

export default MessageTypeOrder
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import MessageTypeUnsupported from './TypeUnsupported';
import React, { useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';

const EmbedStyled = styled('embed')(({ theme }) => ({
    width: 300,
    height: 300,
}));

function MessageTypeDocument({ item }) {
    const { t } = useTranslation();

    const document = item?.content?.document;
    const downloadLinkRef = useRef(null);

    const getCommonProperties = (title, icon) => ({ title, icon });

    const supportedTypes = {
        'application/pdf': {
            ...getCommonProperties(
                t('messageTypes.fileType.pdf'),
                'picture_as_pdf'
            ),
            viewer: (url) => <EmbedStyled src={url} type="application/pdf" />,
        },
        'application/msword': getCommonProperties(
            t('messageTypes.fileType.doc'),
            'description'
        ),
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            getCommonProperties(t('messageTypes.fileType.docx'), 'description'),
        'application/vnd.ms-excel': getCommonProperties(
            t('messageTypes.fileType.xls'),
            'grid_on'
        ),
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            getCommonProperties(t('messageTypes.fileType.xlsx'), 'grid_on'),
        'application/vnd.ms-powerpoint': getCommonProperties(
            t('messageTypes.fileType.ppt'),
            'slideshow'
        ),
        'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            getCommonProperties(t('messageTypes.fileType.pptx'), 'slideshow'),
    };

    const mimeType = document?.mime_type?.trim();
    const documentDetails = supportedTypes[mimeType];

    if (!document?.url || !documentDetails) {
        return <MessageTypeUnsupported message={t('messageTypes.unsupportedDocument')} />;
    }

    const { title, icon, viewer } = documentDetails;

    const handleDownloadDocument = () => {
        if (downloadLinkRef.current) {
            downloadLinkRef.current.click();
        }
    };

    return (
        <div className="flex justify-center items-stretch w-full">
            <div className="flex w-full justify-start">
                {mimeType !== 'application/pdf' && (
                    <>
                        <Tooltip title={title}>
                            <IconButton size="small">
                                <Icon>{icon}</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('messageTypes.downloadFile')}>
                            <IconButton
                                onClick={handleDownloadDocument}
                                size="small">
                                <Icon>cloud_download</Icon>
                            </IconButton>
                        </Tooltip>
                        <a
                            href={document.url}
                            download={document.filename || 'document'}
                            ref={downloadLinkRef}
                            className="hidden"></a>
                    </>
                )}
            </div>
            {viewer && viewer(document.url)}
        </div>
    );
}

export default MessageTypeDocument;

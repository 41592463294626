import MessageTypeAudio from './TypeAudio';
import MessageTypeButton from './TypeButton';
import MessageTypeContacts from './TypeContacts';
import MessageTypeDeleted from './TypeDeleted';
import MessageTypeDocument from './TypeDocument';
import MessageTypeHsm from './TypeHsm';
import MessageTypeImage from './TypeImage';
import MessageTypeInteractive from './TypeInteractive';
import MessageTypeLocation from './TypeLocation';
import MessageTypeOrder from './TypeOrder';
import MessageTypeReaction from './TypeReaction';
import MessageTypeSticker from './TypeSticker';
import MessageTypeText from './TypeText';
import MessageTypeVideo from './TypeVideo';

function MessageType({ item, type: propType, content: propContent }) {
    const type = item ? item.type : propType;
    const content = item ? item.content : propContent;
    const context = item && item.context ? item.context : null;

    return (
        <>
            {type === 'audio' && <MessageType   Audio item={item} />}
            {type === 'button' && <MessageTypeButton content={content} />}
            {type === 'contacts' && <MessageTypeContacts content={content} />}
            {type === 'deleted' && <MessageTypeDeleted />}
            {type === 'document' && <MessageTypeDocument item={item} />}
            {type === 'hsm' && <MessageTypeHsm item={item} />}
            {type === 'image' && <MessageTypeImage content={content} />}
            {type === 'interactive' && <MessageTypeInteractive content={content} />}
            {type === 'location' && <MessageTypeLocation content={content} />}
            {type === 'order' && <MessageTypeOrder content={content} />}
            {type === 'reaction' && <MessageTypeReaction content={content} />}
            {(type === 'sticker' || type === 'whatsappSticker') && <MessageTypeSticker content={content} type={type} />}
            {(type === 'text' || type === 'unsupported') && <MessageTypeText text={content.text} type={type} context={context} />}
            {type === 'video' && <MessageTypeVideo content={content} />}
        </>
    );
}

export default MessageType;

import { memo, useState } from 'react';
import { NoConversationSelected } from './components/NoConversationSelected';
import { selectSelectedConversation } from './store/conversationsSlice';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import CartPanel from './CartPanel';
import FormChat from './FormChat';
import HeaderConversation from './components/HeaderConversation';
import Paper from '@mui/material/Paper';
import TabsChannels from 'app/whatsapp-panel/components/TabsChannels';
import FileUploader from 'app/whatsapp-panel/components/FileUploader';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

const PaperStyled = styled(Paper)(({ theme }) => ({
    background: theme.palette.background.default,
}));

const DragOverlay = styled(motion.div)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.98)',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

const ConversationPanel = () => {
    const { t } = useTranslation();
    const selectedConversation = useSelector(selectSelectedConversation);
    const selectedConversationId = selectedConversation?.id;
    const [isDragging, setIsDragging] = useState(false);

    const handleFileSelect = (fileData) => {
        console.log(fileData);
        setIsDragging(false);
    };

    const handleCloseFileUploader = () => {
        setIsDragging(false);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Solo desactivamos si el mouse sale del contenedor principal
        if (e.currentTarget === e.target) {
            setIsDragging(false);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        setIsDragging(false);
    };

    return (
        <>
            <div
                className="flex flex-1 z-12 w-full relative"
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}>
                <PaperStyled
                    className="flex flex-col relative shadow flex-1 z-10"
                    square>
                    {!selectedConversationId && <NoConversationSelected />}
                    {selectedConversationId && (
                        <>
                            {!isDragging && (
                                <>
                                    <HeaderConversation />
                                    <TabsChannels />
                                    <FormChat />
                                </>
                            )}
                            {isDragging && (
                                <div className="flex-1 relative">
                                    <AnimatePresence>
                                        <DragOverlay
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 0.2 }}>
                                            <FileUploader
                                                disabled={false}
                                                isDragActive={isDragging}
                                                onClose={handleCloseFileUploader}
                                                onFileSelect={handleFileSelect}
                                                t={t}
                                            />
                                        </DragOverlay>
                                    </AnimatePresence>
                                </div>
                            )}
                        </>
                    )}
                </PaperStyled>
            </div>
            <CartPanel />
        </>
    );
};

export default memo(ConversationPanel);

import { combineReducers } from '@reduxjs/toolkit';
import cart from './cartSlice';
import chat from './chatSlice';
import conversation from './conversationsSlice';
import state from './stateSlice';
import tabChannel from './tabChannelSlice'

const reducer = combineReducers({
  cart,
  chat,
  conversation,
  state,
  tabChannel
});

export default reducer;

import { Link, NavLink } from 'react-router-dom';
import { selectUser } from 'app/store/userSlice';
import { useSelector,useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import JwtService from 'src/app/auth/services/jwtService';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

function UserMenu() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit">
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {user && user.data ? user.data.displayName : ''}
          </Typography>
        </div>

        {user && user?.data?.photoURL ? (
          <Avatar
            className="md:mx-4"
            alt="user photo"
            src={user ? user.data.photoURL : ''}
          />
        ) : (
          <Avatar className="md:mx-4">
            {user ? user?.data?.displayName[0] : ''}
          </Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}>
        <MenuItem
          component={Link}
          to="/apps/settings"
          onClick={userMenuClose}
          role="button">
          <ListItemIcon className="min-w-40">
            <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
          </ListItemIcon>
          <ListItemText primary={t('themeLayout.userProfile.myProfile')} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            JwtService.logout();
            dispatch({ type: 'socket/disconnect' });
            userMenuClose();
          }}>
          <ListItemIcon className="min-w-40">
            <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
          </ListItemIcon>
          <ListItemText primary={t('themeLayout.userProfile.logout')} />
        </MenuItem>
      </Popover>
    </>
  );
}

export default UserMenu;

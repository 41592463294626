import { useEffect, useState } from 'react';
import MessageTypeError from 'app/whatsapp-panel/components/MessageType/MessageTypeError';

const UNKNOWN_FILE_TYPE = 'Formato de archivo no reconocido';

export const ShowFile = ({ item }) => {
    const mimeContentType = item?.MimeContentType?.['Content-Type'];
    const contentDisposition = item?.MimeContentType?.['Content-Disposition'];
    const caption = item?.content?.file?.caption || null;
    const [strFile, setStrFile] = useState(null);

    console.log('item', item);

    useEffect(() => {
        let isMounted = true;

        const getExtension = () => {
            if (mimeContentType && contentDisposition) {
                if (mimeContentType.includes('audio')) {
                    return 'audio';
                } else if (mimeContentType.includes('pdf')) {
                    return 'pdf';
                } else {
                    const lastDotIndex = contentDisposition.lastIndexOf('.');
                    return contentDisposition
                        .substring(lastDotIndex + 1)
                        .toLowerCase();
                }
            }

            return '';
        };

        const extension = getExtension();

        if (!extension) {
            setStrFile(<MessageTypeError error={{ message: UNKNOWN_FILE_TYPE }} />);
            return;
        }

        const documentUrl =
            item?.content?.document?.url || item?.content?.file?.url;
        const urlOffice = 'https://view.officeapps.live.com/op/embed.aspx?src=';
        const officeTypes = ['xls', 'doc', 'ppt'];

        if (officeTypes.includes(extension)) {
            setStrFile(
                <iframe
                    src={`${urlOffice}${documentUrl}`}
                    style={{
                        width: 300,
                        height: 400,
                        position: 'unset',
                        margin: 0,
                    }}
                    frameBorder="0"></iframe>
            );
        } else if (extension === 'pdf') {
            setStrFile(
                <embed
                    src={documentUrl}
                    type="application/pdf"
                    style={{ width: 300, height: 300 }}
                />
            );
        } else if (extension === 'tiff') {
            const loadTiffImage = async () => {
                const request = new XMLHttpRequest();
                request.open('GET', documentUrl, true);
                request.responseType = 'arraybuffer';
                request.onload = () => {
                    const tiff = new Tiff({ buffer: request.response });
                    const canvas = tiff.toCanvas();
                    if (canvas && isMounted) {
                        const src = canvas.toDataURL();
                        setStrFile(<img src={src} style={{ width: 200 }} />);
                    }
                };
                request.send();
            };
            loadTiffImage();
        } else if (extension === 'audio') {
            const audioUrl = item?.content?.audio?.url;
            setStrFile(
                <audio controls preload="metadata">
                    <source src={audioUrl} type="audio/ogg; codecs=opus" />
                    tu navegador no soporta elementos de audio
                </audio>
            );
        } else {
            setStrFile(<MessageTypeError error={{ message: UNKNOWN_FILE_TYPE }} />);
        }

        return () => {
            isMounted = false;
        };
    }, [mimeContentType, contentDisposition, item]);

    return <>{strFile}</>;
};

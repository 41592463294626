import { closeOpenTags } from '../../Utils/shared/utils';
import { convertUTCToTimeZone } from 'app/utils/dateUtils';
import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ConversationParticipantsContainer from '../../Utils/components/ConversationParticipantsContainer';
import ConversationParticipantsSummary from './ConversationParticipantsSummary';
import DisplayName from '../../Utils/components/DisplayName';
import HtmlTooltip from '../../Utils/components/HtmlTooltip';
import LastMessageTime from '../../Utils/components/LastMessageTime';
import LastMessageTimeToResponse from '../../Utils/components/LastMessageTimeToResponse';
import jwtService from 'app/auth/services/jwtService';

const LastMessage = ({ name, displayName, lastMessage, encargados }) => {
    const { t } = useTranslation();
    const hasMessage = Boolean(lastMessage);
    const lastMessageTime = lastMessage?.createdDatetime;
    let lastMessageText = (hasMessage && lastMessage?.text) ? lastMessage.text : '';

    if (lastMessageText.length > 25) {
        if (lastMessageText.substring(24, 25) == ' ') {
            lastMessageText = closeOpenTags(`${lastMessageText.substring(0, 24)}...`);
        } else {
            lastMessageText = closeOpenTags(`${lastMessageText.substring(0, 25)}...`);
        }
    }

    const truncatedDisplayName = _.truncate(displayName, { length: 18, omission: '...' });
    const truncatedName = _.truncate(name, { length: 18, omission: '...' });

    const userSettings = useMemo(() => { return jwtService.getUserPreferences()}, []);
    const clientLastMessageTime = lastMessageTime 
        ? convertUTCToTimeZone(lastMessageTime, userSettings?.diference_timezone)
        : null;

    return (
        <>
            <HtmlTooltip
                title={<ConversationParticipantsSummary encargados={encargados} />}
                placement="top">
                <ConversationParticipantsContainer className="flex pl-7 pr-7 flex-col items-start justify-center border-r border-gray-900">
                    {encargados?.map(
                        (encargado, index) => (
                            <div
                                key={index}
                                className="max-w-full text-xs truncate"
                            // style={{ color }}
                            >
                                {encargado?.NombreUsuario && encargado?.NombreUsuario.length
                                    ? encargado?.NombreUsuario
                                    : t('global.user.userNameNotAssigned')
                                }
                            </div>
                        )
                    )}
                </ConversationParticipantsContainer>
            </HtmlTooltip>

            <div className="flex w-full pl-7 flex-col items-center justify-center">
                <div className="flex w-full">
                    <Typography className="font-semibold text-12">{truncatedName}</Typography>
                </div>

                {!name && (
                    <div className="flex w-full">
                        <Typography className="font-medium text-11">
                            <DisplayName>{!truncatedName && truncatedDisplayName}</DisplayName>
                        </Typography>
                    </div>
                )}

                <div className="flex w-full">
                    {typeof lastMessageText == "object" ? (<Typography
                        className="pl-2 truncate text-11"
                        color="textSecondary">
                        {hasMessage ? lastMessageText : ''}
                    </Typography>) : (<Typography
                        className="pl-2 truncate text-11"
                        color="textSecondary"
                        dangerouslySetInnerHTML={hasMessage ? { __html: lastMessageText } : { __html: '' }}
                    />)}
                </div>
            </div>

            <div className="flex flex-col justify-center items-end">
                <div className="mb-8">
                    <LastMessageTime lastMessageTime={clientLastMessageTime} />
                </div>
                <div>
                    <LastMessageTimeToResponse lastMessageTime={clientLastMessageTime} />
                </div>
            </div>
        </>
    );
};

export default LastMessage;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isConnected: true,
};

const connectionSlice = createSlice({
    name: 'connection',
    initialState,
    reducers: {
        setConnectionStatus: (state, action) => {
            if (state.isConnected !== action.payload) {
                state.isConnected = action.payload;
            }
        },
    },
});

export const { setConnectionStatus } = connectionSlice.actions;

export const selectConnectionStatus = (state) => state.connection?.isConnected;

export default connectionSlice.reducer;

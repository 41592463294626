import { capitalizeFirstLetter } from 'app/utils/stringUtils';
import { formatNumber } from 'app/utils/numberUtils';
import { getConversations, isActionAllowed, isPreferredAction } from './Utils/shared/utils';
import { selectConversationList, setFilteredBy, setIsFiltered, updateConversationList } from './store/conversationsSlice';
import { selectUserPreferences, selectUser } from 'app/store/userSlice';
import { Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';

const ConversationStates = ({ estadosConversacion }) => {
    const dispatch = useDispatch();
    const conversations = useSelector(selectConversationList);
    const preferences = useSelector(selectUserPreferences);
	const user = useSelector(selectUser);

    const handleStateClick = ({ infoEstado, estado }) => {
        try {
            emptyConversations();

            const types = [1, 3, 5];
            const typesDescrption = {
                1: 'whatsapp',
                3: 'facebook',
                5: 'instagram',
            };
            
            types.forEach((tipo) => {
                const isAllowed = isActionAllowed(user.permissions, typesDescrption[tipo]);
                const isPreferred = isPreferredAction(preferences, typesDescrption[tipo]);

                if (isAllowed && isPreferred) {
                    dispatch(getConversations({
                        offset: '0',
                        limit: '10',
                        tipo,
                        filtros: {
                            estado: {
                                id: infoEstado.id,
                                descripcion: estado,
                                color_text: infoEstado.color,
                            },
                        },
                    }));
                }
            });

            dispatch(setIsFiltered(true));
            dispatch(setFilteredBy(estado));
        } catch (error) {
            console.log(error);
        }
    };

    const emptyConversations = () => {
        const conversationsWithoutFiltered = conversations.filter(
            (conversation) => !conversation.filtered
        );

        dispatch(updateConversationList(conversationsWithoutFiltered));
    };

    const handleDetalleClick = (redSocial, conteo, idEstado = id, estado) => {
        emptyConversations();

        const redesSocialesById = { whatsapp: 1, facebook: 3, instagram: 5 };

        const isAllowed = isActionAllowed(redSocial);
        const isPreferred = isPreferredAction(redSocial);

        if (isAllowed && isPreferred) {
            dispatch(
                getConversations({
                    offset: '0',
                    limit: '10',
                    tipo: redesSocialesById[redSocial],
                    filtros: {
                        estado: {
                            id: idEstado,
                        },
                    },
                })
            );
        }

        dispatch(setIsFiltered(true));
        dispatch(setFilteredBy(estado));
    };

    const renderTooltipContent = ({ detalles, id, estado }) => {
        return Object.entries(detalles).map(([redSocial, conteo]) => (
            <div
                key={redSocial}
                onClick={() =>
                    handleDetalleClick(redSocial, conteo, id, estado)
                }
                style={{ cursor: 'pointer' }}>
                {capitalizeFirstLetter(redSocial)}: {formatNumber(conteo)}
            </div>
        ));
    };

    return (
        <div className="flex flex-wrap gap-5"> {/* Tailwind flex container */}
            {Object.entries(estadosConversacion).map(([estado, infoEstado]) => (
                <div
                    key={infoEstado.id}
                    className="flex items-center"
                    style={{ color: infoEstado.color }}>
                    <span
                        onClick={() =>
                            handleStateClick({ infoEstado, estado })
                        }
                        className="mr-2 whitespace-nowrap cursor-pointer">
                        {capitalizeFirstLetter(estado)}:{' '}
                        {formatNumber(infoEstado.total)}
                    </span>

                    <Tooltip
                        title={renderTooltipContent({ ...infoEstado, estado })}
                        placement="top"
                        arrow>
                        <InfoIcon className="cursor-pointer" />
                    </Tooltip>
                </div>
            ))}
        </div>
    );
};

export default ConversationStates;

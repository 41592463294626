import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import React, { useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import WhatsAppFormattedText from '../../WhatsAppFormattedText';

const ImgStyled = styled('img')(({ theme }) => ({
    width: 200,
    height: 200,
}));

const MessageTypeImage = ({ content }) => {
    const { t } = useTranslation();

    const image = content.image;
    const imgUrl = image?.url || '';
    const caption = image?.caption || '';
    const downloadLinkRef = useRef(null);

    const handleDownloadImage = () => {
        if (downloadLinkRef.current) {
            downloadLinkRef.current.click();
        }
    };

    const handleUploadImage = () => {
        // TODO
    };

    return (
        <>
            {imgUrl.length > 0 && (
                <>
                    <div className="flex flex-col items-center justify-center">
                        <ImgStyled src={imgUrl} alt="Uploaded Content" />
                        {caption && <div className="w-full my-8"><WhatsAppFormattedText text={caption} /></div>}
                    </div>
                    <div className="flex justify-center items-stretch w-full">
                        <div className="flex w-full justify-start">
                            <Tooltip title={t('messageTypes.downloadFile')}>
                                <IconButton
                                    onClick={handleDownloadImage}
                                    size="small">
                                    <Icon>cloud_download</Icon>
                                </IconButton>
                            </Tooltip>
                            <a
                                href={imgUrl}
                                download="image"
                                ref={downloadLinkRef}
                                className="hidden"></a>
                        </div>
                        <div className="flex w-full justify-center">
                            <Tooltip
                                title={t('messageTypes.attachFileToCustomerCredit')}>
                                <IconButton
                                    onClick={handleUploadImage}
                                    size="small">
                                    <Icon>cloud_upload</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="flex w-full justify-end">
                            <Tooltip title={t('messageTypes.viewInLargerSize')}>
                                <IconButton size="small">
                                    <Icon>zoom_in</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default MessageTypeImage;

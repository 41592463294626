function MessageTypeSticker(props){
    let type = props.type;
    let stickerUrl = ((type == 'sticker') ? props.content.sticker.url : props.content.whatsappSticker.link);

    return(
        <>
        <div className="flex">
        <img src={stickerUrl} width="200" height="200"/>
        </div>
        </>
    )
}

export default MessageTypeSticker
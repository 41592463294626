import { selectSelectedConversation } from "./store/conversationsSlice";
import { useSelector } from "react-redux";
import FormChatWsp from "./components/whatsapp/FormChatWsp";
import FormChatFb from "./components/facebook/FormChatFb";
import FormChatIg from "./components/instagram/FormChatIg";

const FormChatType = ({ type, conversation }) => {
    let FormChat_ = () => <>Unsupported</>;
    if (type == 1) {
        FormChat_ = FormChatWsp;
    }
    if (type == 3) {
        FormChat_ = FormChatFb;
    }
    if (type == 5) {
        FormChat_ = FormChatIg;
    }
    return (
        <FormChat_ conversation={conversation} />
    );
}

const FormChat = (props) => {
    const selectedConversation = useSelector(selectSelectedConversation);
    return (
        <>
            {selectedConversation && <FormChatType type={selectedConversation.tipo} conversation={selectedConversation} />}
        </>

    );
};

export default FormChat;

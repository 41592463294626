import { http } from "../services";

export const wsConsultBeforeTransferringGroup = async (params) => {
    const response = await http.post("/ws/consultarantesdetransferirgrupo", params);
    const data = await response.data;
    return data;
}

export const wsTransferGroupConversation = async (params) => {
    const response = await http.post("/ws/transferirconversaciongrupo", params);
    const data = await response.data;
    return data;
}

export const fbConsultBeforeTransferringGroup = async (params) => {
    const response = await http.post("/fb/consultarantesdetransferirgrupo", params);
    const data = await response.data;
    return data;
}

export const fbTransferGroupConversation = async (params) => {
    const response = await http.post("/fb/transferirconversaciongrupo", params);
    const data = await response.data;
    return data;
}
export const igConsultBeforeTransferringGroup = async (params) => {
    const response = await http.post("/ig/consultarantesdetransferirgrupo", params);
    const data = await response.data;
    return data;
}

export const igTransferGroupConversation = async (params) => {
    const response = await http.post("/ig/transferirconversaciongrupo", params);
    const data = await response.data;
    return data;
}

import { memo } from 'react';
import ConversationListItemFb from './components/facebook/ConversationListItem';
import ConversationListItemIg from './components/instagram/ConversationListItem';
import ConversationListItemWsp from './components/whatsapp/ConversationListItem';

const ConversationListItem = memo(
    ({ conversation, selectedConversationId, handlerClickConversation, ...others }) => {
        const commonProps = {
            tipo: conversation.tipo,
            conversation,
            className: 'px-16 py-2',
            active: selectedConversationId === conversation.id,
            onClick: () => handlerClickConversation(conversation.id, conversation.tipo),
            key: conversation.id
        };

        return conversation.tipo === 1 ? (
            <ConversationListItemWsp {...commonProps} />
        ) : conversation.tipo === 3 ? (
            <ConversationListItemFb {...commonProps} />
        ) : conversation.tipo === 5 ? (
            <ConversationListItemIg {...commonProps} />
        ) : (
            <>Unsupported</>
        );
    }
);

export default ConversationListItem;

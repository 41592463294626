import { http } from "../services";

export const fetchConversationStates = async () => {
    const response = await http.post("/consultar_estados_conversacion");
    const data = await response.data;
    return data;
}

export const changeWhatsappConversationState = async (params) => {
    const response = await http.post("/ws/cambiar_estado", params);
    const data = await response.data;
    return data;
}

export const changeFacebookConversationState = async (params) => {
    const response = await http.post("/fb/cambiar_estado", params);
    const data = await response.data;
    return data;
}

export const changeInstagramConversationState = async (params) => {
    const response = await http.post("/ig/cambiar_estado", params);
    const data = await response.data;
    return data;
}


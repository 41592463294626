import { selectSelectedConversation } from '../store/conversationsSlice';
import { useSelector } from 'react-redux';
import { TabsChannelsWsp } from './whatsapp/TabsChannelsWsp';
import { ConversationContainerFb } from './facebook/ConversationContainer';
import { ConversationContainerIg } from './instagram/ConversationContainer';

const TabsChannels = () => {
    const conversation = useSelector(selectSelectedConversation);

    const { tipo: type } = conversation;

    const renderTabsChannels = () => {
        switch (type) {
            case 1:
                return <TabsChannelsWsp conversation={conversation} />;
                break;
            case 3:
                return <ConversationContainerFb conversation={conversation} />;
                break;
            case 5:
                return <ConversationContainerIg conversation={conversation} />;
                break;
            default:
                return <>Unsupported</>;
        }
    };

    return renderTabsChannels();
};

export default TabsChannels;
